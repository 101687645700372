import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import axios from "axios";
import FakeField from "../../fake_field";
import SelectorItem from "../../selector_item";
import SelectorList from "../../selector_list";
import SelectorPopup from "../../selector_popup";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faChevronDown from "@fortawesome/fontawesome-free-solid/faChevronDown";

const FlightsContainer = styled.div`
  margin-right: 30px;
  min-width: 250px; // For IE 11 compat.
  width: 250px;

  .fake-field {
    height: 30px;
    font-size: 13px;
  }

  .fake-field .value,
  .fake-field .placeholder {
    margin-right: 10px;
  }

  .flight-subname {
    font-size: 11px;
    font-weight: normal;
  }

  .airline-time {
    display: flex;
    justify-content: space-between;

    span:first-child {
      margin-right: 20px;
    }
  }

  @media (min-width: 767px) {
    .selector-popup {
      width: 250px;
    }
  }
`;

function FlightList(props) {
  const [flights, setFlights] = useState([]);
  const [flightError, setFlightError] = useState(props.error);
  const [flightsLoading, setFlightsLoading] = useState(false);
  const [flightsLoadingMessage, setFlightsLoadingMessage] = useState(null);
  const [popupDisplayed, setPopupDisplay] = useState(false);
  const [displayedFlight, setDisplayedFlight] = useState();
  const flightsLoadingTimer = useRef(undefined);
  const initialLoad = useRef(true);
  const field = useRef(null);
  const selector = useRef(null);

  // Add a slight delay to the flights list loading message appearing.
  useEffect(() => {
    if (flightsLoading) {
      setFlightsLoadingMessage(null);
      flightsLoadingTimer.current = setTimeout(() => {
        setFlightsLoadingMessage(props.content.flightsLoading);
      }, 300);
    } else {
      if (flightsLoadingTimer.current)
        clearTimeout(flightsLoadingTimer.current);
      setFlightsLoadingMessage(null);
    }
  }, [flightsLoading, props.content.flightsLoading]);

  // If error changes, update the local state.
  useEffect(() => {
    setFlightError(props.error);
  }, [props.error]);

  // If value changes, update the local state.
  useEffect(() => {
    setDisplayedFlight(selectedFlight());
  }, [flights, props.value]); // eslint-disable-line react-hooks/exhaustive-deps

  // Make sure flights get loaded if flight date/pickup/dropoff changes
  // And reset flight id.
  useEffect(() => {
    if (props.flightTime && props.flightTime.date) {
      loadFlights(props.flightTime.date);
      if (!initialLoad.current) {
        props.onChange({ flightId: null });
      } else {
        initialLoad.current = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.flightTime.date, props.pickup.code, props.dropoff.code]);

  useEffect(() => {
    // Determine the selected value if it isn't explicit.
    if (!props.value && props.airline && props.flightNumber && flights.length) {
      let implicitFlight = flights.find(flight => {
        return (
          flight.airline == props.airline &&
          flight.flight_number == props.flightNumber
        );
      });
      if (implicitFlight) props.onChange({ flightId: implicitFlight.id });
    }
  }, [props.value, props.airline, props.flightNumber, flights]);

  const _knmTranslateCode = function(code) {
    return {
      9: "tab",
      13: "enter",
      27: "esc",
      37: "left",
      38: "up",
      39: "right",
      40: "down"
    }[code];
  };

  const flightLabel = flight =>
    `${flight.airline} ${flight.flight_number} @${flight.flight_time_string}`;

  const flightSelected = flight => {
    props.onChange({ flightId: flight.id });
    setDisplayedFlight(flightLabel(flight));
    hidePopup({ displayedValue: flightLabel(flight) });
  };

  const handleKeyDown = function(e) {
    const keyCode = e.keyCode || e.which,
      name = _knmTranslateCode(keyCode);
    if (keyActions[name]) {
      keyActions[name].call(this);
      if (name != "tab") e.preventDefault();
    }
  };

  const hidePopup = function({ displayedValue = selectedFlight() } = {}) {
    setPopupDisplay(false);
    setDisplayedFlight(displayedValue);
  };

  // Keyboard actions
  const keyActions = {
    down: () => (popupDisplayed ? selector.current.down() : showPopup()),
    enter: () => selector.current.select(),
    esc: () => hidePopup(),
    right: () => selector.current.select(),
    tab: () => selector.current.select(),
    up: () => selector.current.up()
  };

  const loadFlights = function(date) {
    let pickup = props.pickup,
      dropoff = props.dropoff,
      airport = pickup.isAirport ? pickup.code : dropoff.code;

    setFlightsLoading(true);
    setFlights([]);

    const postData = {
      dropoff_type: dropoff.type,
      pickup_type: pickup.type,
      airport: airport,
      date: date
    };

    axios
      .post(window.urls.flights, postData)
      .then(response => {
        setFlightsLoading(false);
        if (response.data.error) {
          setFlightError(response.data.error);
        } else {
          setFlights(response.data.flights || []);
        }
      })
      .catch(error => {
        setFlightError(error);
      });
  };

  const renderError = () => {
    if (!flightError) return "";
    return (
      <label
        className="error-label"
        dangerouslySetInnerHTML={{ __html: flightError }}
      />
    );
  };

  const renderPopup = function() {
    const list = flights.map(flight => {
      let onClick = flightSelected.bind(this, flight);

      const subname = (
        <div>
          <span className="flight-subname">
            {props.content.flightsFlightPrefix} {flight.flight_number}{" "}
            {props.content.flightsTimePrefix} {flight.flight_time_string}
          </span>
        </div>
      );

      return (
        <SelectorItem key={flight.id} onClick={onClick}>
          <div className="airline-time">
            <span>{flight.airline}</span>
          </div>
          {subname}
        </SelectorItem>
      );
    });

    const popup = <SelectorList ref={selector}>{list}</SelectorList>;

    return (
      <SelectorPopup content={props.content} onClose={hidePopup}>
        {popup}
      </SelectorPopup>
    );
  };

  const selectedFlight = function() {
    if (!props.value) return null;
    let ourFlight = flights.find(
      flight => Number(flight.id) == Number(props.value)
    );
    return ourFlight ? flightLabel(ourFlight) : null;
  };

  const showPopup = () => {
    setDisplayedFlight("");
    setPopupDisplay(true);
  };

  let blankLabel;
  if (flightsLoading) {
    blankLabel = flightsLoadingMessage || props.content.flightsBlankLabel;
  } else if (flights.length > 0) {
    blankLabel = props.content.flightsBlankLabel;
  } else {
    blankLabel = props.content.flightsDisabledLabel;
  }

  const fieldProps = {
    content: {
      icon: <FontAwesomeIcon icon={faChevronDown} />,
      placeholder: blankLabel
    },
    disabled: !!(
      (!flightsLoading && flights.length == 0) ||
      (flightsLoading && flightsLoadingMessage)
    ),
    onClick: showPopup,
    onFocus: showPopup,
    onKeyDown: handleKeyDown,
    ref: field,
    value: displayedFlight || ""
  };

  return (
    <FlightsContainer>
      <label
        dangerouslySetInnerHTML={{
          __html: props.content.flightsLabel
        }}
      />

      <FakeField {...fieldProps} />
      {popupDisplayed ? renderPopup() : null}
      {renderError()}
    </FlightsContainer>
  );
}

FlightList.propTypes = {
  airline: PropTypes.string,
  content: PropTypes.object,
  dropoff: PropTypes.shape({
    code: PropTypes.string
  }),
  error: PropTypes.string,
  flightNumber: PropTypes.string,
  flightTime: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  pickup: PropTypes.shape({
    code: PropTypes.string
  }),
  value: PropTypes.string
};

export default FlightList;
