import { createSelector } from 'reselect'

dropoffLoc = (state, resFields) -> resFields.dropoff

export locationTypesStore = (state, resFields, airport) ->
  if !airport
    airport = 'unx'
  locTypeKey = resFields.providerSiteId + '.' + airport + '.' + resFields.serviceArea
  state.locationTypes[locTypeKey]

pickupLoc = (state, resFields) -> resFields.pickup

# (from https://github.com/reduxjs/reselect)
# createSelector(...inputSelectors | [inputSelectors], resultFunc)
# Takes one or more selectors, or an array of selectors, computes their values 
# and passes them as arguments to resultFunc.

export dropoffConfig = createSelector(
  [locationTypesStore, dropoffLoc],
  (locTypes, dropoff) => 
    return null unless dropoff && dropoff.type
    locTypes.typesByType[dropoff.type]
)

export pickupConfig = createSelector(
  [locationTypesStore, pickupLoc],
  (locTypes, pickup) => 
    return null unless pickup && pickup.type
    locTypes.typesByType[pickup.type] 
)

export isArrival = createSelector(pickupConfig, (pickup) => pickup?.isAirport)

export isDeparture = createSelector(dropoffConfig, (dropoff) => dropoff?.isAirport)
