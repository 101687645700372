import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import './pickup_time.scss'
import DateTime from '../date_time/date_time'
import SupplementaryInfo from './supplementary_info'

class PickupTime extends React.Component
  constructor: (props) -> super(props)

  componentDidMount: ->
    @props.onChange(flightId: '')
    if @props.onReturnChange
      @props.onReturnChange(flightId: '')
    
  renderPickupDate: ->
    <DateTime
      content={@props.content.pickupDate}
      controlled={true}
      errors={{
        date: @props.errors.pickupTime?.date,
        time: @props.errors.pickupTime?.time
      }}
      format={{
        date: @props.options.dateFormat, time: @props.options.timeFormat
      }}
      onDateChange={(date) => @props.onChange(pickupTime: date)}
      onHourChange={(hour) => @props.onChange(pickupTime: { hour })}
      onMinuteChange={(minute) => @props.onChange(pickupTime: { minute })}
      onViewportChange={@props.onViewportChange}
      options={{
        defaultDaysOut: @props.options.defaultPickupDaysOut,
        defaultToToday: @props.options.defaultPickupToToday,
        hidden: @props.options.hidden,
        hideTime: @props.options.hideTime,
        language: @props.options.language,
        minuteInterval: @props.options.pickupTimeMinInterval
      }}
      value={@props.fields.pickupTime}/>

  renderReturnPickup: ->
    return null if @props.options.onewayOnly

    if @props.fields.mode == 'roundtrip'
      <DateTime
        content={@props.content.returnPickupDate}
        controlled={true}
        errors={{
          date: @props.returnErrors?.pickupTime?.date,
          time: @props.returnErrors?.pickupTime?.time
        }}
        format={{
          date: @props.options.dateFormat, time: @props.options.timeFormat
        }}
        onDateChange={(date) => @props.onReturnChange(pickupTime: date)}
        onHourChange={(hour) => @props.onReturnChange(pickupTime: { hour })}
        onMinuteChange={(minute) => @props.onReturnChange(pickupTime: { minute })}
        onViewportChange={@props.onViewportChange}
        options={{ 
          hidden: @props.options.hidden,
          hideTime: @props.options.hideTime,
          language: @props.options.language,
          minuteInterval: @props.options.pickupTimeMinInterval
        }}
        value={@props.returnFields?.pickupTime}/>

  render: ->
    <SupplementaryInfo className="pickup-time-container">
      {@renderPickupDate()}
      {@renderReturnPickup()}
    </SupplementaryInfo>

PickupTime.propTypes = {
  content: PropTypes.shape({
    pickupDate: PropTypes.object,
    returnPickupDate: PropTypes.object,
  }),
  errors: PropTypes.shape({ pickupTime: PropTypes.object }),
  fields: PropTypes.shape({
    mode: PropTypes.oneOf(['oneway', 'multi', 'roundtrip', 'hourly']),
    pickupTime: PropTypes.object,
  }),
  onChange: PropTypes.func.isRequired,
  onReturnChange: PropTypes.func,
  onViewportChange: PropTypes.func,
  options: PropTypes.shape({
    dateFormat: PropTypes.string,
    defaultPickupDaysOut: PropTypes.number,
    defaultPickupToToday: PropTypes.bool,
    hidden: PropTypes.bool,
    hideTime: PropTypes.bool,
    onewayOnly: PropTypes.bool,
    pickupTimeMinInterval: PropTypes.number,
    timeFormat: PropTypes.string
  }),
  returnErrors: PropTypes.shape({
    pickupTime: PropTypes.object,
  }),
  returnFields: PropTypes.shape({
    pickupTime: PropTypes.object,
  }),
}

# --- Connect to Redux
mapStateToProps = (state, ownProps) ->
  content: $.extend(true, {}, state.content, (ownProps.content || {})),
  options: $.extend(true, {}, state.configuration, (ownProps.options || {}))

export default connect(mapStateToProps)(PickupTime)
