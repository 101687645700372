import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CustomerRequest from "./customer_request";
import FlightLookupContainer from "./flight_lookup_container";
import FlightEntry from "./flight_entry";

function Flight(props) {
  let MainComponent = FlightEntry;
  if (props.options.flightLookup) {
    MainComponent = FlightLookupContainer;
  } else if (props.options.forceCustomerRequest || props.fields.customerRequest) {
    MainComponent = CustomerRequest;
  }

  return <MainComponent {...props} />;
}

Flight.propTypes = {
  fields: PropTypes.shape({
    customerRequest: PropTypes.bool,
  }),
  options: PropTypes.shape({
    flightLookup: PropTypes.bool,
  }),
};

// --- Connect to Redux
const mapStateToProps = (state, ownProps) => {
  return {
    content: state.content,
    options: { ...state.configuration, ...(ownProps.options || {}) },
  };
};
export default connect(mapStateToProps)(Flight);
