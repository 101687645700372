import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faCaretRight from '@fortawesome/fontawesome-free-solid/faCaretRight'

import './selector_item.scss'

export default class SelectorItem extends React.Component
  constructor: (props) -> super(props)

  render: ->
    if @props.parent
      forwardIcon = <div className="forward">
                      <FontAwesomeIcon icon={faCaretRight} size="lg"/>
                    </div>
    className = "selector-item #{@props.className}"
    className += " selected hudson-primary" if @props.selected

    <li className={className} onClick={@props.onClick} data-name={@props.name}>
      <div className="value">{@props.children}</div>
      {forwardIcon}
    </li>
