import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import SelectorItem from '../selector_item'
import SelectorList from '../selector_list'

class SiteSelector extends React.Component
  constructor: (props) -> super(props)

  down: => @refs.list.down()

  select: => @refs.list.select()

  up: => @refs.list.up()

  render: ->
    sites = @props.sites.map (site) =>
      <SelectorItem key={site.providerSiteId} parent="true"
                    onClick={=> @props.onChange(site.providerSiteId, site.serviceAreaId)}>
        {site.description}
      </SelectorItem>

    <SelectorList ref="list">
      <li className="hudson-subsection list-head">{@props.content.header}</li>
      {sites}
    </SelectorList>

SiteSelector.propTypes = {
  content: PropTypes.shape({
    header: PropTypes.String
  }),
  onChange: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      providerSiteId: PropTypes.string
  }))
}

SiteSelector.defaultProps = {
  sites: []
}

# --- Connect to Redux
mapStateToProps = (state) -> content: state.content.site

export default connect(mapStateToProps, null, null, { forwardRef: true })(SiteSelector)
