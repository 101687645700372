export changeGroup = (index, group) ->
  {
    index,
    promise: $.post(window.urls.groups.change, group: group),
    types: ['CHANGE_GROUP_REQUEST',
            'CHANGE_GROUP_SUCCESS',
            'CHANGE_GROUP_FAILURE']
  }

export groupsReducer = (state = {}, action) ->
  switch action.type
    when 'CHANGE_GROUP_REQUEST'
      $.extend({}, state, { updating: true })
    when 'CHANGE_GROUP_SUCCESS'
      if action.error
        alert(action.error);
      $.extend({}, state, { updating: false })
    else state

