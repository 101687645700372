import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const COLORS_QUERY = gql`
  query locationLoadingBarQuery {
    colors {
      buttonBackground
      id
    }
  }
`;

function LocationLoadingBar() {
  const { loading, data } = useQuery(COLORS_QUERY);

  const StyledProgress = withStyles({
    bar: {
      background: !loading && data.colors.buttonBackground
    },
    root: {
      background: "hsla(0, 0%, 0%, 0.15)"
    }
  })(LinearProgress);

  return (
    !loading && (
      <Fade in={true} unmountOnExit style={{ transitionDelay: "800ms" }}>
        <StyledProgress />
      </Fade>
    )
  );
}

export default LocationLoadingBar;
