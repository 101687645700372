import { predictions } from '../utils/place_predictions'

# Search for locations using google
googleSearch = (resIndex, locType, searchTerm) ->
  res = window.reduxStore.getState().reservations.legs[resIndex]
  {
    promise: googleSearchPromise(resIndex, locType, searchTerm),
    query: searchTerm, resIndex, siteId: res.providerSiteId, locType,
    source: 'google',
    types: ['LOAD_LOCS_REQUEST', 'LOAD_LOCS_SUCCESS', 'LOAD_LOCS_FAILURE']
  }

googleSearchPromise = (resIndex, locType, searchTerm) ->
  if !google?
    throw "MA - google is undefined"
    return
  res = window.reduxStore.getState().reservations.legs[resIndex]
  airports = window.reduxStore.getState().airports
  siteCfg = window.reduxStore.getState().siteConfiguration[res.providerSiteId]

  # If we already have a pickup selected then default to that as search center.
  if res.pickup?.map_point
    searchCenter = { lat: res.pickup.map_point.split(',')[0], lng: res.pickup.map_point.split(',')[1]}

  else  
    searchCenter = siteCfg.defaultSearchCenter
    if res.serviceArea
      serviceAreas = window.reduxStore.getState().serviceAreas[res.providerSiteId] || []
      serviceArea = (sA for sA in serviceAreas when Number(sA.id) == Number(res.serviceArea))[0]
      if serviceArea && serviceArea.map_point
        searchCenter = serviceArea.map_point
    
    # If no default search center see if network airport has one.
    if !searchCenter && res.regionCity
      region = (a for a in airports when a.name == res.regionCity)[0]
      if region && region.map_point
        searchCenter = region.map_point.split(',')

    if searchCenter
      searchCenter = { lat: searchCenter[0], lng: searchCenter[1] }

  predictionPromise = predictions(searchTerm, searchCenter)
    .then (locations) => { locations }

# Initiate a search for locations using hudson location records
hudsonSearch = (resIndex, locType, searchTerm, byAirport, airport) ->
  res = window.reduxStore.getState().reservations.legs[resIndex]
  {
    promise: hudsonSearchPromise(resIndex, locType, searchTerm, byAirport, undefined, airport),
    query: searchTerm, resIndex, siteId: res.providerSiteId, locType,
    source: 'hudson',
    types: ['LOAD_LOCS_REQUEST', 'LOAD_LOCS_SUCCESS', 'LOAD_LOCS_FAILURE']
  }

hudsonSearchPromise = (resIndex, locType, searchTerm, byAirport, resultLimit, airport) ->
  res = window.reduxStore.getState().reservations.legs[resIndex]

  getOptions = {
    loc_type: locType,
    provider_site: res.providerSiteId,
    result_limit: resultLimit,
    service_area: res.serviceArea,
    term: searchTerm
  }

  if byAirport
    airport = res.airport if !airport
    getOptions.airport = airport

  $.getJSON(window.urls.locations.load, getOptions)

hudsonSearchWithGoogleFallback = (resIndex, locType, searchTerm, byAirport, fallback, airport) ->
  res = window.reduxStore.getState().reservations.legs[resIndex]
  siteCfg = window.reduxStore.getState().siteConfiguration[res.providerSiteId]
  hudsonType = siteCfg.mainSearchLocType

  source = 'google'
  promise = hudsonSearchPromise(resIndex, hudsonType, searchTerm, byAirport, 15, airport).then (action) =>
    if action.locations.length
      action.googleFallback = true
      action.source = 'hudson'
      return $.Deferred().resolve(action).promise()
    googleSearchPromise(resIndex, locType, searchTerm)      

  {
    promise: promise,
    query: searchTerm, resIndex, siteId: res.providerSiteId, locType, source,
    types: ['LOAD_LOCS_REQUEST', 'LOAD_LOCS_SUCCESS', 'LOAD_LOCS_FAILURE']
  }

# Fetch a location type object for the given location type params.
locationType = (locType, res, byAirport, airport) ->
  locTypeStore = locationTypeStore(res, byAirport, airport)
  locTypeStore.typesByType[locType] || {}

locationTypeStore = (res, byAirport, airport) ->
  if byAirport
    if (!airport) 
      airport = res.airport
    locTypeKey = res.providerSiteId + '.' + airport + '.' + res.serviceArea
  else
    locTypeKey = res.providerSiteId + '.unx.' + res.serviceArea
  window.reduxStore.getState().locationTypes[locTypeKey]

export fetchLocations = (resIndex, locType, searchTerm = '', byAirport = false, googleFallback, airport) ->
  res = window.reduxStore.getState().reservations.legs[resIndex]
  currentTripType = window.reduxStore.getState().tripTypes.current

  if locType
    locTypeConfig = locationType(locType, res, byAirport, airport)
    searchMode = locTypeConfig.displayMode
  else
    locTypeConfig = locationTypeStore(res, byAirport, airport)
    siteCfg = window.reduxStore.getState().siteConfiguration[res.providerSiteId]
    searchMode = locTypeConfig.mainSearchMode

  # We don't support google search on Airport tab.
  tripTypeSupportsGoogle = currentTripType == 'universal'
  if tripTypeSupportsGoogle && (searchMode == 'google' || googleFallback)
    googleSearch(resIndex, locType, searchTerm)
  else if searchMode == 'google_as_fallback' && tripTypeSupportsGoogle
    hudsonSearchWithGoogleFallback(resIndex, locType, searchTerm, byAirport, undefined, airport)
  else
    hudsonSearch(resIndex, locType, searchTerm, byAirport, airport)

export locationsReducer = (state = {}, action) ->
  switch action.type
    when 'LOAD_LOCS_REQUEST'
      newState = $.extend(true, {}, state)
      newState[action.resIndex] ||= {
        query: action.query, locType: action.locType, locations: []
      }
      newState[action.resIndex].fetching = true
      newState
    when 'LOAD_LOCS_SUCCESS'
      newState = $.extend(true, {}, state)
      newState[action.resIndex] = {
        query: action.query, locType: action.locType,
        googleFallback: action.googleFallback
        locations: action.locations, source: action.source,
        fetching: false
      }
      newState
    when 'LOAD_LOCS_FAILURE'
      console.error "Unable to load location types for #{action.siteId}"
      newState = $.extend(true, {}, state)
      newState[action.resIndex] = {
        query: action.query, locType: action.locType,
        locations: [], fetching: false }
      newState
    else state
