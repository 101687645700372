import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faChevronDown from '@fortawesome/fontawesome-free-solid/faChevronDown'

import './group_selector.scss'
import FakeField from '../fake_field'
import SelectorItem from '../selector_item'
import SelectorList from '../selector_list'
import SelectorPopup from '../selector_popup'

class GroupSelector extends React.Component
  constructor: (props) ->
    super(props)

    @state = {
      displayPopup: false,
      displayedValue: (@selectedGroup() || '')
    }

  keyActions: { # Keyboard actions
      down: (->
        if @state.displayPopup then @currentSelector()?.down?() else @showPopup()),
      enter: (-> @currentSelector()?.select?()),
      esc: (-> @hidePopup()), tab: (-> @hidePopup()),
      left: (-> @currentSelector()?.left?()),
      right: (-> @currentSelector()?.select?()),
      tab: (-> @currentSelector()?.select?()),
      up: (-> @currentSelector()?.up?())
  }

  _knmTranslateCode: (code) ->
    {
      9: 'tab', 13: 'enter', 27: 'esc', 37: 'left', 38: 'up', 39: 'right',
      40: 'down'
    }[code]

  handleKeyDown: (e) =>
    keyCode = e.keyCode || e.which
    name = @_knmTranslateCode(keyCode)
    if @keyActions?[name]?
      @keyActions[name].call(this)
      e.preventDefault() unless name == 'tab'

  componentDidMount: -> 
    @props.onViewportChange()

    # Auto-select if only one group.
    groupKeys = Object.keys(@props.groups)
    if groupKeys.length == 1
      @props.onChange(groupKeys[0])

  componentDidUpdate: (prevProps, prevState) ->
    @props.onViewportChange()
    if prevProps.value != @props.value
      @setState displayedValue: @selectedGroup() 

    # If we tried to load a group but it failed.
    if prevProps.loading && !@props.loading && @selectedGroup() != @state.displayedValue
      @setState displayedValue: @selectedGroup()

  clearGroup: =>
    @hidePopup()
    @props.onChange()

  groupSelected: (group, label) =>
    @props.onChange(group)
    @hidePopup(label)

  hidePopup: (displayedValue) =>
    displayedValue ||= @selectedGroup()
    @refocus() if window.matchMedia('screen and (max-width: 767px)').matches
    @setState displayPopup: false, displayedValue: displayedValue

  refocus: => @refs.field.focus?()

  selectedGroup: =>
    return null unless @props.value
    @props.groups[@props.value]

  showPopup: => @setState displayPopup: true, displayedValue: ''

  renderError: ->
    return '' unless @props.error
    <label className="error-label"
           dangerouslySetInnerHTML={{__html: @props.error}}/>

  renderField: ->
    hasOneGroup = Object.keys(@props.groups).length == 1
    fieldProps = {
      content: { 
        icon: <FontAwesomeIcon icon={faChevronDown}/>,
        placeholder: @props.content.placeholder 
      },
      disabled: hasOneGroup,
      onClick: @showPopup, onFocus: @showPopup, onKeyDown: @handleKeyDown,
      ref: "field",
      loading: @props.loading,
      value: @state.displayedValue || ''}
    fieldProps.className = "has-error" if @props.error
    fieldProps.content.icon = null if hasOneGroup
    <div><FakeField {...fieldProps}/></div>

  renderPopup: ->
    # if @props.value
    #   blankOption = <SelectorItem key='remove' onClick={@clearGroup}>
    #                   {@props.content.removeLabel}
    #                 </SelectorItem>

    <SelectorPopup content={@props.content} onClose={@hidePopup}>
      <SelectorList ref="selector">
        {
          for group, label of @props.groups
            onClick = @groupSelected.bind(this, group, label)
            <SelectorItem key={group} onClick={onClick}>
              {label}
            </SelectorItem>
        }
      </SelectorList>
    </SelectorPopup>

  renderSelector: ->
    # if @props.value
    #   blankOption = <option value=''>{@props.content.removeLabel}</option>
    if !@props.value
      blankOption = <option value='' disabled>
                      {@props.content.blankLabel}
                    </option>

    <div className="group-selector">
      <select value={@props.value || ''} onChange={@onChange}>
        { <option key={group} value={group}>{label}</option> for group, label of @props.groups }
      </select>
    </div>

  render: ->  
    <div className="group-selector">
      <label dangerouslySetInnerHTML={{__html: @props.content.label}}/>
      {@renderField()}
      {if @state.displayPopup then @renderPopup()}
      {@renderError()}
    </div>

GroupSelector.propTypes = {
  content: PropTypes.shape({
    label: PropTypes.string,
    placeholder: PropTypes.string,
    removeLabel: PropTypes.string
  }),
  error: PropTypes.string,
  groups: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onViewportChange: PropTypes.func,
  value: PropTypes.string
}

# --- Connect to Redux
mapStateToProps = (state) ->
  content: state.content.groupSelector,
  loading: state.groups.updating,
  groups: state.groups.groups,
  value: state.configuration.groupAttached

export default connect(mapStateToProps)(GroupSelector)

