# From discussion: https://github.com/reactjs/redux/issues/99
promiseMiddleware = ->
  (next) ->
    (action) ->
      deobj = (obj, keys...) ->
        ret = (obj[k] for k in keys)
        ret.push(rest = { })
        rest[k] = v for k, v of obj when k !in keys
        ret
      [promise, types, rest] = deobj(action, 'promise', 'types')

      return next(action) unless promise
      [REQUEST, SUCCESS, FAILURE] = types

      next( $.extend({}, rest, type: REQUEST, promise: promise) )

      promise.then(
        ((result) -> next( $.extend({}, rest, result, type: SUCCESS) ) ),
        ((error) -> next( $.extend({}, rest, error, type: FAILURE) ))
      )

export default promiseMiddleware
