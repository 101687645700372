import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import hudsonLogo from "./logo.png";

const DATA_QUERY = gql`
  query pageQuery {
    colors {
      buttonColor
      buttonBackground
      id
    }

    content {
      id
      book: portalBookLinkCaption
      resLookup: portalReslookupLinkCaption
    }

    profile(type: "group") {
      id
      name
    }
  }
`;

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  background: #fafafa;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 25px 0 10px 0;
  text-align: center;
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 25px;
  padding: 10px 25px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.background} !important;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.2) 100%);  
  color: ${(props) => props.color} !important;

  @media (max-width: 767px) {
    justify-content: center;
    padding: 10px 8px 8px;
  }
`;

const StyledLink = styled.a`
  align-self: center;
  font-size: 18px;
  font-weight: 500;
  padding: 0 20px;

  color: ${(props) => props.color} !important;

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 0 8px;
  }
`;

const StyledLinkContainer = styled.div`
  display: flex;

  @media (max-width: 767px) {
    justify-content: space-between;
    width: 100%;
  }
`;

const StyledName = styled.span`
  font-size: 30px;
  font-weight: 300;
  font-family: sans-serif;
  align-self: flex-end;

  @media (max-width: 767px) {
    display: block;
    width: 100%;
    text-align: center;
  }
`;

function Portal({ children }) {
  const { loading, data, error } = useQuery(DATA_QUERY);

  if (loading || error) {
    if (error) console.error(error);
    return null;
  }

  return (
    <>
      <StyledHeader color={data.colors.buttonColor} background={data.colors.buttonBackground}>
        <StyledName>{data.profile.name}</StyledName>

        <StyledLinkContainer>
          <StyledLink
            color={data.colors.buttonColor}
            background={data.colors.buttonBackground}
            href={window.urls.home}
          >
            {data.content.book}
          </StyledLink>
          <StyledLink
            color={data.colors.buttonColor}
            background={data.colors.buttonBackground}
            href={window.urls.resLookup}
          >
            {data.content.resLookup}
          </StyledLink>
        </StyledLinkContainer>
      </StyledHeader>

      {children}

      <StyledFooter>
        <a
          href="https://hudsonltd.com"
          rel="noreferrer"
          target="_blank"
          title="Powered by Hudson Software"
        >
          <img src={window.urls.relative_root + hudsonLogo} />
        </a>
      </StyledFooter>
    </>
  );
}

Portal.propTypes = {
  children: PropTypes.any,
};

export default Portal;
