import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faShoppingCart from '@fortawesome/fontawesome-free-solid/faShoppingCart'

import LoginModal from '../login_modal'
import './action_bar.scss'

class EntryPageActionBar extends React.Component
  constructor: (props) ->
    super(props)
    @state = { showLoginModal: false }

  logout: (url) =>
    return unless confirm(@props.content.logoutConfirm)

    # Below lines can be removed when we move to the new CMS plugin
    try
      parentUrl = $(window.frameElement).data('ma-after-logout-url')
    catch
      null

    if !parentUrl
      parentUrl = @props.options.afterLogoutUrl 

    if parentUrl
      if url.indexOf('?') != -1
        logoutUrl = url + "&"
      else
        logoutUrl = url + '?'
      window.location.href = logoutUrl + "parent_goto=#{parentUrl}"
    else
      window.location.href = url

  renderCartLink: ->
    return '' unless @props.options.showCart && !@props.options.editMode
    <a href={window.urls.cart.home}>
      <FontAwesomeIcon icon={faShoppingCart}/> {@props.content.cartLabel}
    </a>

  renderCustomLink: ->
    unless @props.content.customLinkLabel && @props.content.customLinkURL
      return null
    <a href={@props.content.customLinkURL}
       target="_parent"
       dangerouslySetInnerHTML={{__html: @props.content.customLinkLabel}}/>

  renderGroupLogout: ->
    groupCanBeRemoved = @props.options.groupAttached && !@props.options.lockGroupProfile
    return null unless groupCanBeRemoved && !@props.options.groupSelectorEnabled

    <a className="logout"
       onClick={=> window.location.href = window.urls.profiles.groupLogout }
       href="#"
       dangerouslySetInnerHTML={{__html: @props.content.logoutGroupLabel}}/>

  renderHomeLink: ->
    onClick = =>
      window.parent.window.postMessage("page_redirect=#{@props.options.preferredRiderHomeURL}", '*')
    <a onClick={onClick} href='#'
       dangerouslySetInnerHTML={{__html: @props.content.homeLabel}}/>

  renderLoginLink: ->
    return null if @props.options.hideLogin
    <a href='#'
       dangerouslySetInnerHTML={{__html: @props.content.loginLabel}}
       onClick={@showLoginModal}/>

  renderLogoutLink: ->
    return null unless @props.options.loggedIn
    <a className="logout"
       onClick={=> @logout(window.urls.profiles.logout) }
       href="#"
       dangerouslySetInnerHTML={{__html: @props.content.logoutLabel}}/>

  showLoginModal: => @setState showLoginModal: true

  render: ->
    <div className="entry-page-action-bar">
      {if @props.options.loggedIn then @renderHomeLink() else @renderLoginLink()}
      {@renderCustomLink()}
      {@renderLogoutLink()}
      {@renderGroupLogout()}
      {@renderCartLink()}

      <LoginModal displayed={@state.showLoginModal}
              onHide={=> @setState showLoginModal: false}/>
    </div>

EntryPageActionBar.propTypes = {
  content: PropTypes.shape({
    customLinkLabel: PropTypes.string,
    customLinkURL: PropTypes.string,
    homeLabel: PropTypes.string,
    loginLabel: PropTypes.string
  }),
  options: PropTypes.shape({
    editMode: PropTypes.bool,
    groupAttached: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hideLogin: PropTypes.bool,
    lockGroupProfile: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    loggedIn: PropTypes.bool,
    preferredRiderHomeURL: PropTypes.string,
    showCart: PropTypes.bool
  })
}


# --- Connect to Redux
mapStateToProps = (state, ownProps) ->
  content: state.content.actionBar,
  options: $.extend(true, {}, state.configuration, (ownProps.options || {}))

EntryPageActionBar = connect(mapStateToProps)(EntryPageActionBar)

export default EntryPageActionBar
