import { ApolloClient } from "apollo-client";
import { BatchHttpLink } from "apollo-link-batch-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { mutations as paymentInfoMutations } from "./resolvers/payment_info";
import { mutations as cartMutations } from "./resolvers/cart";

const link = new BatchHttpLink({
  uri: window.gqlEndpoint,
  credentials: "include",
});

const client = new ApolloClient({
  connectToDevTools: window.serverEnv == "development",
  link: link,
  cache: new InMemoryCache(),
  resolvers: {
    Mutation: { ...paymentInfoMutations, ...cartMutations },
  },
});
export default client;
