import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { changeGroup } from '../../../redux/groups'
import { fetchLocations } from '../../../redux/locations'
import { clearLocationTypes, fetchLocationTypes } from '../../../redux/location_types'
import { dropoffConfig, isArrival, isDeparture, locationTypesStore, pickupConfig } from '../../../redux/selectors/reservations'
import { fetchSiteConfig } from '../../../redux/site_configuration'

import './universal.scss'
import AirportSelector from '../airport_selector'
import DirectionSelector from '../direction_selector'
import Flight from '../flight'
import GoogleMap from '../../google_map'
import GroupSelector from '../group_selector'
import Hourly from '../hourly'
import Location from '../../location'
import MacroSelector from '../macro_selector'
import PassengerSelector from '../passenger_selector'
import PickupTime from '../pickup_time'
import ResEditHeader from '../res_edit_header'
import RoundtripHourlyToggle from '../roundtrip_hourly_toggle'
import SiteAndServiceArea from '../site_and_service_area'
import SupplementaryInfo from '../supplementary_info'
import { isMobile } from '../../../utils/utils'
import Collapse from "@material-ui/core/Collapse";
import LocationLoadingBar from '../location_loading_bar'
import { withStyles } from "@material-ui/core/styles";

class EntryPageUniversalReservation extends React.Component
  constructor: (props) -> 
    super(props)
    @state = {
      hideServiceArea: false
    }

  componentDidMount: ->  
    unless @isLocationDisabled()
      @onLocationTypesRequested() # Preload location type dialog.

    if @props.options.hardFromLocation
      @props.onLocationChanged('pickup', @props.options.hardFromLocation)

    # Hide service area if asked to -- but only if we already have a service area selected.
    if @props.options.hideServiceArea && @props.fields.serviceArea && @props.fields.providerSiteId
      @setState hideServiceArea: true

    window.addEventListener('resize', @forceUpdateWithState)

  componentDidUpdate: (prevProps, prevState) -> @props.onViewportChanged()

  componentWillUnmount: -> 
    window.removeEventListener('resize', @forceUpdateWithState)

  forceUpdateWithState: => @forceUpdate()

  isAirportMode: =>
    @props.fields.providerSiteId &&
      @props.options.airportOnlySites.includes(@props.fields.providerSiteId)

  # Locations are disabled if we don't have a provider site and if we have
  # service areas available but one hasn't been selected.
  isLocationDisabled: =>
    return true unless @props.fields.providerSiteId

    !@props.fields.serviceArea &&
      @props.regions.serviceAreas?[@props.fields.providerSiteId]?.length > 1

  pickupAndDropoffSelected: -> 
    return !!(@props.fields.pickup?.code && !@props.fields.pickup?.error && 
              @props.fields.dropoff?.code && !@props.fields.dropoff?.error)

  renderDirection: ->
    # Only show direction selector for "airport only" providers.
    return unless @isAirportMode()

    <DirectionSelector value={@props.fields.direction}
            onChange={(direction) => 
              # This section of code is here only to try to smooth out 
              # interface updates/changes while the pickup and dropoff are
              # swapped behind the scenes. (ex. trying to avoid the airport
              # field from blanking out and then re-filling)
              pickup = $.extend({}, @props.fields.pickup)
              dropoff = $.extend({}, @props.fields.dropoff)
              if @props.fields.direction == 'D'
                firstToChange = => @props.onLocationChanged('pickup', dropoff)
                secondToChange = => @props.onLocationChanged('dropoff', pickup)
              else
                firstToChange = => @props.onLocationChanged('dropoff', pickup)
                secondToChange = => @props.onLocationChanged('pickup', dropoff)

              # Running one location update fully before the second to ensure 
              # there are no race conditions with the Google Map updating.
              firstToChange().then =>
                secondToChange()
                @props.onFieldsChanged({direction})
            }/>

  renderFlight: ->
    return null if @props.fields.mode == 'hourly'
    return null unless  @isAirportMode() || @props.fields.requireFlightType || @props.fields.requireFlightDate

    flightLookup = (@props.fields.pickup && @props.fields.pickup.flightLookupEnabled) || 
                    (@props.fields.dropoff && @props.fields.dropoff.flightLookupEnabled)

    <div>
      <Flight fields={@props.fields}
                  errors={@props.errors}
                  onChange={@props.onFieldsChanged}
                  onCustomerRequestChange={@props.onCustomerRequestChanged}
                  onReturnChange={@props.onReturnFieldsChanged}
                  onViewportChange={@props.onViewportChanged}
                  options={
                    $.extend(@props.options, { 
                              showFlightDate: @isAirportMode() || @props.fields.requireFlightDate,
                              showFlightType: @isAirportMode() || @props.fields.requireFlightType,
                              flightLookup
                             })
                  }
                  returnErrors={@props.returnErrors}
                  returnFields={@props.returnFields}/>
    </div>

  renderGroupSelector: ->
    return null unless @props.options.groupSelectorEnabled

    <GroupSelector onChange={(group) =>                    
                     @props.onGroupChanged(group).then(() =>
                      @props.onClearLocationTypes()
                      @onLocationTypesRequested()
                     )
                   }
                   onViewportChange={@props.onViewportChanged}
                   error={@props.errors.GroupAlias}/>

  renderHourly: ->
    return null unless @props.fields.mode == 'hourly'
    <Hourly fields={@props.fields}
            errors={@props.errors}
            onChange={@props.onFieldsChanged}
            onViewportChange={@props.onViewportChanged}/>

  renderLocationLoader: ->
    locationLoading = @props.fields.pickup?.updating || @props.fields.dropoff?.updating

    if locationLoading
      return <LocationLoadingBar/> 

  onLocationsRequested: (locType, query, googleFallback) =>
    if @isAirportMode() && @props.fields.direction == 'D'
      airport = @props.fields.dropoff.code
    else if @isAirportMode()
      airport = @props.fields.pickup.code
    @props.onLocationsRequested(locType, query, googleFallback, @isAirportMode(), airport)

  onLocationTypesRequested: => 
    if @isAirportMode() && @props.fields.direction == 'D'
      airport = @props.fields.dropoff?.code
    else if @isAirportMode()
      airport = @props.fields.pickup?.code

    @props.onLocationTypesRequested(@isAirportMode(), airport)

  renderLocationSelectors: ->
    forceDisabled = @props.siteConfig.fetching && @isAirportMode()

    if !@props.siteConfig.fetching && @isAirportMode()
      if @props.fields.serviceArea
        airports = @props.airportsBySite[@props.fields.providerSiteId][@props.fields.serviceArea]
      else
        airports = @props.airportsBySite[@props.fields.providerSiteId]["DEFAULT"]

      content = @props.content.siteAndLocation
      if @props.fields.direction == 'D'
        label = content.fromLabel
        airport = 'dropoff'
        location = 'pickup'
      else
        label = content.toLabel
        airport = 'pickup'
        location = 'dropoff'

      <React.Fragment>
        <div className="airport-loc-container">
          <AirportSelector 
                        airports={airports}
                        onChange={(air) => 
                          airportObj = airports.find (a) -> a.code == air
                          @props.onLocationChanged(airport, { airport: true, code: air, map_point: airportObj?.map_point }).then =>
                            @onLocationTypesRequested()
                        }
                        value={@props.fields[airport]?.code}
                        onViewportChange={@props.onViewportChanged}/>
        </div>

        <div className="location-container">
          <Location
            className="to-location"
            content={$.extend({}, @props.content.location, { label })}
            disabled={@props.locationsByTypeLoading || !@props.fields[airport]?.code || @isLocationDisabled()}
            error={@props.errors?.location}
            loading={@props.siteConfig.fetching || @props.locationsByTypeLoading}
            location={ value: @props.fields[location] }
            locationsByType={@props.locationsByType}
            onLocationChange={(loc) => @props.onLocationChanged(location, loc) }
            onLocationsRequested={@onLocationsRequested}
            onLocationTypesRequested={@onLocationTypesRequested}
            onViewportChanged={@props.onViewportChanged}
            options={@props.options}
            requestedLocations={@props.requestedLocations}/>
        </div>    

      </React.Fragment>
    else
      <React.Fragment>
        <div className="location-container">
          <Location
            className="from-location"
            content={@props.content.pickup}
            disabled={@props.locationsByTypeLoading || forceDisabled || @isLocationDisabled()}
            error={@props.errors?.pickup}
            loading={@props.siteConfig.fetching || @props.locationsByTypeLoading}
            location={value: @props.fields.pickup }
            locationsByType={@props.locationsByType}
            onLocationChange={(loc) => @props.onLocationChanged('pickup', loc) }
            onLocationsRequested={@onLocationsRequested}
            onLocationTypesRequested={@onLocationTypesRequested}
            onViewportChanged={@props.onViewportChanged}
            options={@props.options}
            readonly={!!@props.options.hardFromLocation}
            requestedLocations={@props.requestedLocations}/>
        </div>

        <div className="location-container">
          <Location
            className="to-location"
            content={@props.content.dropoff}
            disabled={@props.locationsByTypeLoading || forceDisabled || @isLocationDisabled()}
            error={@props.errors?.dropoff}
            loading={@props.siteConfig.fetching || @props.locationsByTypeLoading}
            location={ value: @props.fields.dropoff }
            locationsByType={@props.locationsByType}
            onLocationChange={(loc) => @props.onLocationChanged('dropoff', loc) }
            onLocationsRequested={@onLocationsRequested}
            onLocationTypesRequested={@onLocationTypesRequested}
            onViewportChanged={@props.onViewportChanged}
            options={@props.options}
            requestedLocations={@props.requestedLocations}/>
        </div>    
      </React.Fragment>    

  renderMacroAndGroupRow: ->
    if @props.options.macrosEnabled
      macros = <div className="col-sm-4">{@renderMacros()}</div>
    if @props.options.groupSelectorEnabled
      groupSelector = <div className="col-sm-4">{@renderGroupSelector()}</div>
    <div className="row region-row">{macros}{groupSelector}</div>

  renderMacros: ->
    return null unless @props.options.macrosEnabled
    <MacroSelector value={@props.fields.macro}
                   onViewportChange={@props.onViewportChanged}
                   onChange={ (fields) =>
                     @props.onFieldsChanged(fields)
                     # Macro could have location filtering
                     @props.onClearLocationTypes()
                     @onLocationTypesRequested()
                   }/>

  renderMap: ->
    if (@props.options?.googleMapsEnabled && !@props.options?.hideMaps && !@props.options?.optimizeSpace)

      <div className="map-row">
        <GoogleMap defaultCenter={@props.options.defaultSearchCenter}
                   pickup={@props.fields.pickup?.map_point}
                   dropoff={@props.fields.dropoff?.map_point}/>
      </div>

  renderPassengers: ->
    return null unless @props.options.showPassengersOnEntryPage
    showPopup = !isMobile() || @props.options.showPassengerPopupOnMobile

    <PassengerSelector
      error={@props.errors?.passengers}
      onChange={(field, value) =>
        obj = { passengers: {} }
        obj.passengers[field] = value
        @props.onFieldsChanged(obj) }
      onViewportChange={@props.onViewportChanged}
      options={{ usePopup: showPopup }}
      value={@props.fields.passengers}/>

  renderPickupTime: ->
    if @props.fields.mode == 'hourly' || @props.fields.requireFlightDate || @isAirportMode()
      return null

    options = $.extend({}, @props.options, {
      hideTime: @props.options.hidePickupTimeOnEntryPage
    })

    <PickupTime fields={@props.fields}
                         errors={@props.errors}
                         onChange={@props.onFieldsChanged}
                         onReturnChange={@props.onReturnFieldsChanged}
                         onViewportChange={@props.onViewportChanged}
                         options={options}
                         returnErrors={@props.returnErrors}
                         returnFields={@props.returnFields}/>

  renderRegions: ->
    if @props.options.macrosEnabled || !@props.regions.available || @state.hideServiceArea
      return null 

    <div className="row region-row">
      <div className="col-sm-4">
        <SiteAndServiceArea
          onServiceAreaChange={(serviceArea) =>
            @props.onFieldsChanged({serviceArea: serviceArea})
            if serviceArea
              @onLocationTypesRequested()
          }
          onSiteChange={(providerSiteId, regionCity) =>
            @props.onFieldsChanged({providerSiteId, regionCity})
            @onLocationTypesRequested()
            @props.onSiteConfigRequested(providerSiteId)
          }
          onViewportChange={@props.onViewportChanged}
          value={{
            regionCity: @props.fields.regionCity,
            site: @props.fields.providerSiteId,
            serviceArea: @props.fields.serviceArea
          }}
        />
      </div>
    </div>

  renderRoundtripHourlyToggle: ->
    return null if @props.options.onewayOnly || @props.options.editMode
    <RoundtripHourlyToggle value={@props.fields.mode}
      hourlyDisabled={!!@isAirportMode()}
      onChange={(mode) => @props.onFieldsChanged({mode})}/>

  renderSupplemental: ->
    hidden = !@props.options?.optimizeSpace && !@pickupAndDropoffSelected() && 
             !@props.options?.dontHideFlightFields

    <Collapse in={!hidden} 
              onEntered={@props.onViewportChanged} 
              onExited={@props.onViewportChanged}>
      <SupplementaryInfo className="res-supplemental #{"hidden" if hidden}">
        {@renderRoundtripHourlyToggle()}
        <div className="res-supplemental-sub">
          {@renderFlight()}
          {@renderHourly()}
          {@renderPickupTime()}
          {@renderPassengers() if isMobile()}
        </div>
      </SupplementaryInfo>
    </Collapse>

  render: ->
    <div className="universal-reservation">
      {<ResEditHeader res={@props.fields}/> if @props.options.editMode}

      <div className="ma-panel control-group tab-content hudson-subsection panel reservation">
        {@renderMap()}
        {@renderMacroAndGroupRow()}
        {@renderRegions()}
        {@renderDirection()}

        <div className="location-row">
          {@renderLocationSelectors()}
          {@renderPassengers() unless isMobile()}
        </div>

        {@renderLocationLoader()}
        {@renderSupplemental()}

        <div className="submit-container">
          {@props.submitButton(undefined, @isAirportMode())}
        </div>
      </div>
    </div>

EntryPageUniversalReservation.propTypes = {
  content: PropTypes.object,
  index: PropTypes.number.isRequired,
  errors: PropTypes.object,
  fields: PropTypes.shape({
    dropoff: PropTypes.object,
    mode: PropTypes.oneOf(['oneway', 'multi', 'roundtrip', 'hourly']),
    pickup: PropTypes.object,
    providerSiteId: PropTypes.string,
    requireFlightDate: PropTypes.bool,
    requireFlightType: PropTypes.bool,
    serviceArea: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }).isRequired,
  onCustomerRequestChanged: PropTypes.func,
  onFieldsChanged: PropTypes.func.isRequired,
  onGroupChanged: PropTypes.func,
  onLocationChanged: PropTypes.func.isRequired,
  onReturnFieldsChanged: PropTypes.func.isRequired,
  onViewportChanged: PropTypes.func,
  options: PropTypes.object,
  regions: PropTypes.shape({
    cities: PropTypes.array,
    regions: PropTypes.object,
    serviceAreas: PropTypes.object
  }),
  returnErrors: PropTypes.object,
  returnFields: PropTypes.object
}


# --- Connect to Redux

# Takes labels and options from the currently selected location types and
# meshes them into the components properties.
translateLocationTypeConfigToProps = (resFields, contentStore, options, state, airport) ->
  puConfig = pickupConfig(state, resFields, airport)
  doConfig = dropoffConfig(state, resFields, airport)
  arrival = isArrival(state, resFields, airport)
  departure = isDeparture(state, resFields, airport)

  return {} unless puConfig || doConfig

  requireFlightDate = resFields.mode != 'hourly' &&
                      (puConfig?.showFlightDate || doConfig?.showFlightDate)

  if arrival
    flightDateLabel = puConfig?.flightDateArrivalLabel
  else if departure
    flightDateLabel = doConfig?.flightDateDepartureLabel
  else
    flightDateLabel = puConfig?.flightDateLabel || doConfig?.flightDateLabel

  if departure
    returnFlightDateLabel = doConfig?.flightDateArrivalLabel
  else if arrival
    returnFlightDateLabel = puConfig?.flightDateDepartureLabel
  else
    returnFlightDateLabel = doConfig?.flightDateLabel || puConfig?.flightDateLabel
  returnDateLabel = contentStore.returnFlight.datePrefix + ' ' + returnFlightDateLabel


  {
    content: {
      flight: {
        dateLabel: flightDateLabel,
        timeLabel: '' # Not supported in type config.
      },
      flightType: { label: puConfig?.flightTypeLabel || doConfig?.flightTypeLabel },
      returnFlight: {
        dateLabel: returnDateLabel,
        timeLabel: '' # Not supported in type config.
      }
    },
    fields: {
      requireFlightDate: options.showFlightDate && requireFlightDate,
      requireFlightType: options.showFlightType &&
                         (puConfig?.showFlightType || doConfig?.showFlightType)
    }
  }

mapStateToProps = (state, ownProps) ->
  if ownProps.fields.providerSiteId
    siteConfig = state.siteConfiguration[ownProps.fields.providerSiteId]
  else
    siteConfig = {}

  options = $.extend({}, state.configuration, siteConfig);

  airportMode = ownProps.fields.providerSiteId &&
      options.airportOnlySites.includes(ownProps.fields.providerSiteId)
  if airportMode && ownProps.fields.direction == 'D'
    airport = ownProps.fields.dropoff?.code
  else if airportMode
    airport = ownProps.fields.pickup?.code
  locTypeStore = locationTypesStore(state, ownProps.fields, airport)
  
  if locTypeStore?.typesByType
    locTypeProps = translateLocationTypeConfigToProps(ownProps.fields, state.content, state.configuration, state, airport)
  else
    locTypeProps = {}

  # Service area can override mapping options
  if ownProps.fields.serviceArea
    serviceAreas = state.serviceAreas[ownProps.fields.providerSiteId] || []
    serviceArea = (sA for sA in serviceAreas when Number(sA.id) == Number(ownProps.fields.serviceArea))[0]
  if serviceArea
    options.defaultSearchCenter = serviceArea.map_point || options.defaultSearchCenter 
    options.googleMapsEnabled = options.googleMapsEnabled || serviceArea.map_enabled

  # If no default search center see if network airport has one.
  if !options.defaultSearchCenter && ownProps.fields.regionCity
    region = (a for a in state.airports when a.name == ownProps.fields.regionCity)[0]
    if region && region.map_point
      options.defaultSearchCenter = region.map_point.split(',')

  props = {
    airportsBySite: state.airportsBySite,
    content: state.content,
    fields: ownProps.fields,
    locationsByType: (locTypeStore?.types || [])
    locationsByTypeLoading: (locTypeStore?.fetching || siteConfig?.fetching),
    options: options,
    regions: state.regions,
    requestedLocations: (state.locations[ownProps.index] || {}),
    siteConfig: siteConfig
  }
  $.extend(true, {}, props, locTypeProps)

mapDispatchToProps = (dispatch, ownProps) -> bindActionCreators({
  onClearLocationTypes: (-> clearLocationTypes()),
  onGroupChanged: ((group) -> changeGroup(ownProps.index, group)),
  onLocationsRequested: ((locType, query, googleFallback, byAirport, airport) ->
    fetchLocations(ownProps.index, locType, query, byAirport, googleFallback, airport)),
  onLocationTypesRequested: ((byAirport, airport) -> fetchLocationTypes(ownProps.index, byAirport, airport)),
  onSiteConfigRequested: ((siteId) -> fetchSiteConfig(ownProps.index, siteId))
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EntryPageUniversalReservation)
