import React, { useState } from "react";
import PropTypes from "prop-types";
import DateTime from "../../date_time/date_time";
import FlightList from "./flight_list";

function FlightLookup(props) {
  const [flightDate, setFlightDate] = useState(props.fields.flightTime);

  const renderDate = function({
    content,
    defaults = {}, // eslint-disable-line react/prop-types
    errors, // eslint-disable-line react/prop-types
    onChange, // eslint-disable-line react/prop-types
    value // eslint-disable-line react/prop-types
  }) {
    return (
      <DateTime
        content={content}
        controlled={true}
        errors={{ date: errors.date, time: errors.time }}
        format={{
          date: props.options.dateFormat,
          time: props.options.timeFormat
        }}
        onDateChange={date => onChange({ flightTime: date })}
        onHourChange={hour => onChange({ flightTime: { hour } })}
        onMinuteChange={minute => onChange({ flightTime: { minute } })}
        onViewportChange={props.onViewportChange}
        options={{
          defaultDaysOut: defaults.daysOut,
          defaultToToday: defaults.defaultToToday,
          hideTime: true,
          language: props.options.language,
          minuteInterval: props.options.flightTimeMinInterval
        }}
        value={value}
      />
    );
  };

  const renderFlightDate = () =>
    renderDate({
      content: props.content,
      defaults: props.dateDefaults,
      errors: props.errors.flightTime ? props.errors.flightTime : {},
      onChange: ({ flightTime }) => {
        setFlightDate(flightTime);
        props.onChange({ flightTime });
      },
      value: flightDate
    });

  return (
    <React.Fragment>
      {renderFlightDate()}
      <FlightList
        airline={props.fields.airline}
        content={props.content}
        dropoff={props.dropoff || {}}
        error={props.errors.flightId}
        flightNumber={props.fields.flightNumber}
        flightTime={props.fields.flightTime || {}}
        onChange={props.onChange}
        pickup={props.pickup || {}}
        value={props.fields.flightId}
      />
    </React.Fragment>
  );
}

FlightLookup.propTypes = {
  content: PropTypes.shape({
    flightsBlankLabel: PropTypes.string,
    flightsDisabledLabel: PropTypes.string,
    flightsLabel: PropTypes.string,
    flightsLoading: PropTypes.string
  }),
  dateDefaults: PropTypes.object,
  dropoff: PropTypes.object,
  errors: PropTypes.shape({
    flightId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    flightTime: PropTypes.object
  }),
  fields: PropTypes.shape({
    airline: PropTypes.string,
    dropoff: PropTypes.object,
    flightId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    flightNumber: PropTypes.string,
    flightTime: PropTypes.object,
    pickup: PropTypes.object
  }),
  onChange: PropTypes.func,
  onViewportChange: PropTypes.func,
  options: PropTypes.shape({
    flightTimeMinInterval: PropTypes.number,
    dateFormat: PropTypes.string,
    defaultPickupDaysOut: PropTypes.number,
    defaultPickupToToday: PropTypes.bool,
    language: PropTypes.string,
    timeFormat: PropTypes.string
  }),
  pickup: PropTypes.object
};

export default FlightLookup;
