import React, { useEffect } from "react";
import PropTypes from "prop-types";
import DateTime from "../../date_time/date_time";
import FlightType from "../../flight_type";
import styled from "styled-components";
import SupplementaryInfo from "../supplementary_info";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  div.date-bootstrap {
    max-width: 170px;
  }

  @media (max-width: 767px) {
    padding-left: 0;

    .supplementary-info.flight-time-container {
      flex-direction: column;
    }
  }
`;

const FlightToggle = styled.a`
  cursor: pointer;
  font-size: 12px;
  // margin-bottom: 15px !important;

  @media (max-width: 767px) {
    display: block;
    padding-top: 12px;
  }
`;

const Header = styled.p`
  max-width: 600px;

  @media (max-width: 767px) {
    margin-bottom: 0;
    margin-top: 20px;
  }
`;

function FlightEntry(props) {
  // Ensure we don't have a flight id.
  useEffect(() => {
    props.onChange({ flightId: "" });
    if (props.onReturnChange) props.onReturnChange({ flightId: "" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let header;
  if (props.content.flight.header) header = <Header>{props.content.flight.header}</Header>;

  const showReturnFlight =
    props.fields.mode == "roundtrip" && !props.options.onewayOnly && props.options.showFlightDate;

  const renderCustomerRequestLink = () => (
    <FlightToggle onClick={() => props.onCustomerRequestChange(true)}>
      {props.content.flight.setCustomerRequestLabel}
    </FlightToggle>
  );

  const renderDate = function ({
    content,
    defaults = {}, // eslint-disable-line react/prop-types
    errors, // eslint-disable-line react/prop-types
    onChange, // eslint-disable-line react/prop-types
    value, // eslint-disable-line react/prop-types
  }) {
    return (
      <DateTime
        content={content}
        controlled={true}
        errors={{ date: errors.date, time: errors.time }}
        format={{
          date: props.options.dateFormat,
          time: props.options.timeFormat,
        }}
        onDateChange={(date) => onChange({ flightTime: date })}
        onHourChange={(hour) => onChange({ flightTime: { hour } })}
        onMinuteChange={(minute) => onChange({ flightTime: { minute } })}
        onViewportChange={props.onViewportChange}
        options={{
          defaultDaysOut: defaults.daysOut,
          defaultToToday: defaults.defaultToToday,
          language: props.options.language,
          minuteInterval: props.options.flightTimeMinInterval,
        }}
        value={value}
      />
    );
  };

  const renderFlightDate = () => (
    <div style={{ zIndex: "9999" }}>
      {renderDate({
        content: props.content.flight,
        defaults: {
          daysOut: props.options.defaultPickupDaysOut,
          defaultToToday: props.options.defaultPickupToToday,
        },
        errors: props.errors.flightTime ? props.errors.flightTime : {},
        onChange: props.onChange,
        value: props.fields.flightTime,
      })}
      {props.options.enableCustomerRequest ? renderCustomerRequestLink() : null}
    </div>
  );

  const renderFlightType = () => (
    <FlightType
      content={props.content.flightType}
      error={props.errors.flightType}
      onChange={(flightType) => props.onChange({ flightType })}
      value={props.fields.flightType}
    />
  );

  const renderReturnFlightDate = () =>
    renderDate({
      content: props.content.returnFlight,
      errors: props.returnErrors.flightTime ? props.returnErrors.flightTime : {},
      onChange: props.onReturnChange,
      value: props.returnFields ? props.returnFields.flightTime : {},
    });

  return (
    <Container>
      {header}

      <SupplementaryInfo className="flight-time-container">
        {props.options.showFlightDate ? renderFlightDate() : null}
        {showReturnFlight ? renderReturnFlightDate() : null}
        {props.options.showFlightType ? renderFlightType() : null}
      </SupplementaryInfo>
    </Container>
  );
}

FlightEntry.propTypes = {
  content: PropTypes.shape({
    flight: PropTypes.shape({
      header: PropTypes.string,
      setCustomerRequestLabel: PropTypes.string,
    }),
    flightType: PropTypes.object,
    returnFlight: PropTypes.object,
  }),
  errors: PropTypes.shape({
    flightTime: PropTypes.string,
    flightType: PropTypes.string,
  }),
  fields: PropTypes.shape({
    flightTime: PropTypes.object,
    flightType: PropTypes.string,
    mode: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onCustomerRequestChange: PropTypes.func,
  onReturnChange: PropTypes.func,
  onViewportChange: PropTypes.func,
  options: PropTypes.shape({
    dateFormat: PropTypes.string,
    defaultPickupDaysOut: PropTypes.number,
    defaultPickupToToday: PropTypes.bool,
    enableCustomerRequest: PropTypes.bool,
    flightTimeMinInterval: PropTypes.number,
    language: PropTypes.string,
    onewayOnly: PropTypes.bool,
    showFlightDate: PropTypes.bool,
    showFlightType: PropTypes.bool,
    timeFormat: PropTypes.string,
  }),
  returnErrors: PropTypes.shape({
    flightTime: PropTypes.string,
  }),
  returnFields: PropTypes.shape({
    flightTime: PropTypes.object,
  }),
};

export default FlightEntry;
