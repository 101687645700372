fetchSiteConfig

export fetchSiteConfig = (resIndex, siteId) ->
  res = window.reduxStore.getState().reservations.legs[resIndex]

  if window.reduxStore.getState().siteConfiguration[siteId]?.fetching ||
     window.reduxStore.getState().siteConfiguration[siteId]
    return { type: 'NOOP' }

  getOptions = { provider_site_id: siteId }

  {
    promise: $.getJSON(window.urls.siteConfig.load, getOptions),
    siteId: siteId,
    types: ['LOAD_SITE_CFG_REQUEST',
            'LOAD_SITE_CFG_SUCCESS',
            'LOAD_SITE_CFG_FAILURE']
  }
export siteConfigurationReducer = (state = {}, action) ->
  switch action.type
    when 'LOAD_SITE_CFG_REQUEST'
      newState = $.extend(true, {}, state)
      newState[action.siteId] ||= {}
      newState[action.siteId].fetching = true
      newState
    when 'LOAD_SITE_CFG_SUCCESS'
      newState = $.extend(true, {}, state)
      newState[action.siteId] = $.extend(true, {}, action.config, { fetching: false })
      newState
    when 'LOAD_SITE_CFG_FAILURE'
      console.error "Unable to load site config for #{action.siteId}"
      newState = $.extend(true, {}, state)
      newState[action.siteId] = { fetching: false }
      newState
    else state
