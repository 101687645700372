import React from 'react';
import PropTypes from 'prop-types';

export default class SelectList extends React.Component
  # Call onChange when the component mounts to ensure that the component
  # and the background state have the same value.
  componentDidMount: ->  
    if @value() != @props.value && @props.onChange
      @props.onChange(@value())

  constructor: (props) -> super(props)

  onChange: (e) => @props.onChange(e.target.value)

  value: =>
    if Object.keys(@props.values).length == 1
      key = Object.keys(@props.values)[0]
      @props.values[key]
    else
      @refs.selectList.value

  render: ->
    inputProps = {
      name: @props.field
    }
    if @props.onChange
      inputProps.onChange = @onChange
      inputProps.value = (@props.value || '')
    else
      inputProps.defaultValue = (@props.value || '')
    inputProps.className = 'select-list '
    inputProps.className = @props.className if @props.className

    if @props.blankValue
      blankValue = <option value=''>{@props.blankValue}</option>

    if Object.keys(@props.values).length == 1
      label = Object.keys(@props.values)[0]
      <p className={inputProps.className}>{label}</p>
    else
      inputProps.className += ' form-control'
      <select {...inputProps} ref="selectList">
        {blankValue}
        { for name, value of @props.values
          <option value={value} key={value}>{name}</option> }
      </select>

SelectList.propTypes = {
  blankValue: PropTypes.string, # If set, this will be the initial name with a blank value.
  className: PropTypes.string,
  field: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),

  # The values object values will be the select list value and 
  # the object properties will be the select list names.
  # ex: { 'Value 1': 1, 'Value 2': 2, 'Value b': 'b' }
  values: PropTypes.object 
}
