import { serviceExtras } from "./selectors/service_extras"

export loadServiceExtras = (siteId, serviceArea) ->
  if !siteId || serviceExtras(window.reduxStore.getState(), siteId, serviceArea)?.fetching
    return { type: 'NOOP' }

  {
    promise: $.getJSON(window.urls.serviceExtras.load, provider_site: siteId, service_area: serviceArea),
    serviceArea, siteId,
    types: ['LOAD_SERVICE_EXTRAS_REQUEST',
            'LOAD_SERVICE_EXTRAS_SUCCESS',
            'LOAD_SERVICE_EXTRAS_FAILURE']
  }

export serviceExtrasReducer = (state = {}, action) ->
  switch action.type
    when 'LOAD_SERVICE_EXTRAS_REQUEST'
      newState = $.extend(true, {}, state)
      newState[action.siteId] ||= {}
      if action.serviceArea
        newState[action.siteId][action.serviceArea].fetching = true  
      else
        newState[action.siteId].main.fetching = true
      end
      newState
    when 'LOAD_SERVICE_EXTRAS_SUCCESS'
      newState = $.extend(true, {}, state)

      if action.serviceArea
        key = action.serviceArea
      else
        key = "main"
      end

      newState[action.siteId] ||= {}
      newState[action.siteId][key] = {
        fixed: action.fixed, unfixed: action.unfixed, fetching: false
      }

      newState
    when 'LOAD_SERVICE_EXTRAS_FAILURE'
      console.error "Unable to load service extras for #{action.siteId}, #{action.serviceArea}"
      newState = $.extend(true, {}, state)

      if action.serviceArea
        key = action.serviceArea
      else
        key = "main"
      end

      newState[action.siteId] ||= {}
      newState[action.siteId][key] = { fixed: [], unfixed: [], fetching: false }
      newState
    else state
