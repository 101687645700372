import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faCircleNotch from '@fortawesome/fontawesome-free-solid/faCircleNotch'

import './fake_field.scss'

export default class FakeField extends React.Component
  constructor: (props) -> super(props)

  focused: => $(@refs.field).is(':focus')

  render: ->
    if @props.content.placeholder? && !@props.value
      placeholder = <div className="placeholder">{@props.content.placeholder}</div>

    if typeof @props.value == 'object'
      value = @props.value
    else
      value = <div className="value"
                  dangerouslySetInnerHTML={{__html: @props.value}}></div> if @props.value

    props = {
      ref: 'field',
      tabIndex: @props.tabIndex || 0,
      className: "fake-field #{@props.className || ''}",
    }
    if @props.disabled
      props.className += " disabled"
    else
      props.onBlur = @props.onBlur
      props.onClick = @props.onClick
      props.onFocus = @props.onFocus
      props.onKeyDown = @props.onKeyDown

    if @props.loading
      icon = <FontAwesomeIcon icon={faCircleNotch} spin/>
    else
      icon = @props.content.icon

    <div {...props}>
      {placeholder}{value}{icon}
    </div>

FakeField.defaultProps = {
  disabled: false
}

FakeField.propTypes = {
  className: PropTypes.string,
  content: PropTypes.shape({
    icon: PropTypes.object, 
    placeholder: PropTypes.string
  }),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  tabIndex: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
}
