export serviceAreasReducer = (state = {}, action) ->
  switch action.type
    when 'LOAD_LOC_TYPE_SUCCESS'
      if action.serviceAreas
        newState = $.extend(true, {}, state)
        newState[action.siteId] = action.serviceAreas
        newState
      else
        state
    else
      state
