import { geocode, geocodeToHudson } from '../utils/geocoder'
import { directions } from '../utils/directions'

# Improvement note:
# This should probably be split into separate files by trip type
# (ex. a universal_reservations and a multi_leg_reservations, etc.)

# Calculate distance/travel times for all applicable legs.
# Returns a promise which resolves with an object that can be merged into
# the submitting fields object that actions.submitReservation generates.
calculateDistanceAndTravelTimes = (airportModeWithCalculate) ->
  deferredObject = $.Deferred()
  legs = window.reduxStore.getState().reservations.legs
  distanceObject = {}
  promises = []

  # Only calculate distance/travel time for universal reservations.
  tripType = window.reduxStore.getState().tripTypes.current
  unless airportModeWithCalculate || tripType == 'universal'
    deferredObject.resolve(distanceObject) 
    return deferredObject

  for res, index in legs when res?.dropoff && res?.pickup
    siteCfg = window.reduxStore.getState().siteConfiguration[res.providerSiteId]

    if !airportModeWithCalculate
      airport = 'unx'
    else if res.direction == 'D'
      airport = res.dropoff.code
    else
      airport = res.pickup.code      
    locTypeKey = res.providerSiteId + '.' + airport + '.' + res.serviceArea

    locTypeStore = window.reduxStore.getState().locationTypes[locTypeKey]

    usingGoogle = (locTypeStore.mainSearchMode == 'google')
    continue if !usingGoogle || (index == 1 && res.mode == 'roundtrip')

    promises[index] = $.Deferred()
    calculate = (resIndex, res) =>
      googleLatLngForLocation(res.dropoff).always (dropoffLatLng) =>
        googleLatLngForLocation(res.pickup).always (pickupLatLng) =>
          if dropoffLatLng && pickupLatLng
            directions(pickupLatLng, dropoffLatLng)
              .fail => promises[resIndex].resolve()
              .then (dirs) =>
                distanceInMeters = dirs.distance.value
                distance = distanceInMeters * 0.000621371192
                timeInSeconds = dirs.duration.value
                time = timeInSeconds / 60 / 60
                distanceObject["trip[#{resIndex}][de]"] = distance
                distanceObject["trip[#{resIndex}][tt]"] = time
                console.log "Distance #{distance} miles, time #{time} hours"
                promises[resIndex].resolve()
    calculate(index, res)

  $.when.apply(this, promises).then => deferredObject.resolve(distanceObject)
  deferredObject

clearSiteSpecificFields = (res) ->
  res.location = null
  res.dropoff = {}
  res.pickup = {}
  res

# Create a parameter name from the given state field (and index).
fieldName = (index, field) ->
  fieldTranslate = {
    code: 'location',
    direction: 'Direction',
    dropoffTime: 'DropOffTOD',
    flightId: 'flight_id',
    flightTime: 'FlightTOD',
    flightType: 'FlightType',
    mapPoint: 'map_point',
    pickupTime: 'PickupTOD',
    providerSiteId: 'PrimarySite',
    regionCity: 'region_city',
    serviceArea: 'ServiceArea'
  }
  field = fieldTranslate[field] if fieldTranslate[field]
  "trip[#{index}][#{field}]"

fleshOutLocation = (siteId, serviceArea, value, noGeocodeOK, airportCode) ->
  cfgStore = window.reduxStore.getState().siteConfiguration[siteId]
  currentTripType = window.reduxStore.getState().tripTypes.current

  mapsEnabled = cfgStore.googleMapsEnabled && currentTripType != 'bus'
  if serviceArea && currentTripType != 'bus'
    serviceAreas = window.reduxStore.getState().serviceAreas[siteId] || []
    serviceAreaObj = (sA for sA in serviceAreas when Number(sA.id) == Number(serviceArea))[0]
    if serviceAreaObj
      mapsEnabled = mapsEnabled || serviceAreaObj.map_enabled

  routeLevelLocs = cfgStore.allowRouteLevelLocations

  if !value || value.code == ''
    return $.Deferred().resolve(value).promise()

  if value.code # Hudson location
    failOk = true
    haveCode = true

  # If this is an airport class (limited data) then add a hint to help Google.
  if value.airport
    value.lookupString = "airport " + value.code

  if (value.code && value.map_point) || !mapsEnabled
    # Don't want to waste a geocode, but still want to normalize.
    geocodeFn = $.Deferred().resolve(value).promise()
  else
    geocodeFn = geocodeToHudson(value, routeLevelLocs: routeLevelLocs, failOk: failOk)

  promise = $.Deferred()
  geocodeFn.then (location) =>
    # Don't lose PUD ID
    location.pud_id = value.pud_id

    if haveCode && !noGeocodeOK && !location.map_point && mapsEnabled
      error = window.reduxStore.getState().content.location.errorLocationNotFound
      value.error = error
      return promise.resolve(value)

    if haveCode # Hudson location, we only need the map point. 
      location.error = ''
      value.map_point = location.map_point
      if value.airport # If Hudson airport then we're done.
        return promise.resolve(value)
    else
      value = location
      value.code = cfgStore.freeformCode

    if location.error
      error = window.reduxStore.getState().content.location.errorLocationTooGeneral
      value.error = error
      return promise.resolve(value)

    postOptions = {
      airport: airportCode, location: value, provider_site: siteId, service_area: serviceArea
    }
    $.post(window.urls.locations.lookup, postOptions).then (result) =>
      if result.error
        value.error = result.error
        return promise.resolve(value)
      else
        # FIXME
        if result.location
          console.log("Fare key lookup result for #{postOptions.location.name} >>>>>> CODE: #{result.location.code} PLACEID: #{postOptions.location.google_place_id} METHOD: #{result.location.lookup_method}")
        for key, val of result.location
          value[key] = val if val
        promise.resolve(value)

flightDateDisplayed = (res) ->
  locTypeKey = res.providerSiteId + '.unx.' + res.serviceArea
  locTypeStore = window.reduxStore.getState().locationTypes[locTypeKey]
  showDateOption = window.reduxStore.getState().configuration.showFlightDate
  puConfig = locTypeStore.typesByType[res.pickup.type] if res.pickup?.type
  doConfig = locTypeStore.typesByType[res.dropoff.type] if res.dropoff?.type

  showDateOption && res.mode != 'hourly' &&
    (puConfig?.showFlightDate || doConfig?.showFlightDate)

flightTypeDisplayed = (res) ->
  locTypeKey = res.providerSiteId + '.unx.' + res.serviceArea
  locTypeStore = window.reduxStore.getState().locationTypes[locTypeKey]
  showTypeOption = window.reduxStore.getState().configuration.showFlightType
  puConfig = locTypeStore.typesByType[res.pickup.type] if res.pickup?.type
  doConfig = locTypeStore.typesByType[res.dropoff.type] if res.dropoff?.type

  showTypeOption && res.mode != 'hourly' &&
    (puConfig?.showFlightType || doConfig?.showFlightType)

# Return "lat,lon" for the given hudson location.
# Returns a promise which resolves with a google LatLng object (or nothing
# if a LatLng can not be generated).
googleLatLngForLocation = (loc) ->
  deferredObject = $.Deferred()
  if loc.map_point
    latLng = loc.map_point.split(',')
    deferredObject.resolve(new google.maps.LatLng(latLng[0], latLng[1]))
  else
    # If we have address, city, and state then don't include the location name.
    # Location name may not be relevant (ex. "My Home Location") and could
    # prevent a successful geocode. Only use loc name if we may not have
    # enough info to geocode otherwise.
    if loc.address && loc.city && loc.state
      address = "#{loc.address || ''}, #{loc.city || ''}, " +
                " #{loc.state || ''}, #{loc.zip || ''}"
    else
      address = "#{loc.name || ''} #{loc.address || ''}, #{loc.city || ''}, " +
                " #{loc.state || ''}, #{loc.zip || ''}"

    geocode(address, locationOnly: true)
      .then( (latLng) -> deferredObject.resolve(latLng) )
      .fail( -> deferredObject.reject() )
    deferredObject

isSubmittable = (state, tripType) ->
  leg = state.legs?[0]

  if tripType == 'airport'
    !!(leg && leg.airport && leg.location?.code && !leg.updating)
  else if tripType == 'universal'
    !!(leg && !leg.pickup?.updating && !leg.dropoff?.updating && !leg.updating)
  else if tripType == 'bus'
    leg = state.legs?[0]
    submittable = !!(leg && leg.pickup?.code && !leg.pickup?.error && 
                     !leg.pickup?.updating && leg.dropoff?.code && 
                     !leg.dropoff?.error && !leg.dropoff?.updating &&
                     !leg.updating)
    return false if !submittable

    if leg.roundtrip == 'roundtrip'
      leg = state.legs?[1]
      !!(leg && leg.pickup?.code && !leg.pickup?.error && 
         !leg.pickup?.updating && leg.dropoff?.code && !leg.dropoff?.error && 
         !leg.dropoff?.updating && !leg.updating)
    else
      submittable

  else if tripType == 'multi'
    submittable = true
    for leg in state.legs.slice(0,2)
       submittable = false if !(leg.airport && leg.location?.code) || leg.updating
    submittable
  else
    true

# Tries to default the provider site when a reservation's airport changes.
providerSiteFromAirport = (airport, airports) ->
  record = (a for a in airports when a.code == airport)?[0]
  return record.serviceAreas[0].siteId if record?.serviceAreas?.length == 1
  null

sendMessageToParent = (key, value) ->
  window.parent.window.postMessage("#{key}=#{value}", '*')

# Tries to default service area when a reservation's airport changes.
serviceAreaFromAirport = (airport, airports) ->
  record = (a for a in airports when a.code == airport)?[0]
  return record.serviceAreas[0].serviceAreaId if record?.serviceAreas?.length == 1
  null

# Convert the current reservations state to field/value params ready to be
# submitted to the backend.
# NOTE: Can be refactored better to support multi-leg and have less ifs
stateToParamsUniversal = ->
  fields = {}
  legs = window.reduxStore.getState().reservations.legs
  currentTripType = window.reduxStore.getState().tripTypes.current

  if currentTripType == 'bus'
    sendPickupDate = true
  else
    sendFlightDate = flightDateDisplayed(legs[0])
    sendPickupDate = !sendFlightDate || legs[0].mode == 'hourly'

  for res, i in legs

    # Force direction to universal in case this res originated as an airport res
    # Force it to P if bus.
    if currentTripType == 'bus'
      fields[fieldName(i, "Direction")] = "P"
    else
      fields[fieldName(i, "Direction")] = "U"

    for field, value of res
      if typeof(value) == 'object'
        for subfield, subvalue of value
          fieldKey = ''
          if field == 'flightTime'
            if !sendFlightDate ||
               subfield == 'year' || subfield == 'month' || subfield == 'day'
              continue
            switch subfield
              when 'date'
                fieldKey = fieldName(i, 'FlightTOD')
              when 'hour'
                fieldKey = fieldName(i, 'FlightTOD(4i)')
                newValue = if Number(subvalue) < 10 then  '0' else ''
                newValue += String(Number(subvalue))
                subvalue = newValue
              when 'minute'
                fieldKey = fieldName(i, 'FlightTOD(5i)')
                newValue = if Number(subvalue) < 10 then  '0' else ''
                newValue += String(Number(subvalue))
                subvalue = newValue
          else if field == 'dropoffTime'
            continue if subfield == 'year' || subfield == 'month' || subfield == 'day'
            switch subfield
              when 'date'
                fieldKey = fieldName(i, 'DropOffTOD')
              when 'hour'
                fieldKey = fieldName(i, 'DropOffTOD(4i)')
                newValue = if Number(subvalue) < 10 then  '0' else ''
                newValue += String(Number(subvalue))
                subvalue = newValue
              when 'minute'
                fieldKey = fieldName(i, 'DropOffTOD(5i)')
                newValue = if Number(subvalue) < 10 then  '0' else ''
                newValue += String(Number(subvalue))
                subvalue = newValue
          else if field == 'pickupTime'
            if !sendPickupDate || 
               subfield == 'year' || subfield == 'month' || subfield == 'day'
              continue
            switch subfield
              when 'date'
                fieldKey = fieldName(i, 'PickupTOD')
              when 'hour'
                fieldKey = fieldName(i, 'PickupTOD(4i)')
                newValue = if Number(subvalue) < 10 then  '0' else ''
                newValue += String(Number(subvalue))
                subvalue = newValue
              when 'minute'
                fieldKey = fieldName(i, 'PickupTOD(5i)')
                newValue = if Number(subvalue) < 10 then  '0' else ''
                newValue += String(Number(subvalue))
                subvalue = newValue
          else if field == 'dropoff'
            if subfield == 'code'
              fieldKey = fieldName(i, 'dropoff')
            else
              fieldKey = "#{fieldName(i, 'dropoff_loc')}[#{subfield}]"
          else if field == 'pickup'
            if subfield == 'code'
              fieldKey = fieldName(i, 'pickup')
            else
              fieldKey = "#{fieldName(i, 'pickup_loc')}[#{subfield}]"
          else
            fieldKey = fieldName(i, subfield)
          if fieldKey && field != "location"
            fields[fieldKey] = subvalue
      else unless field == 'roundtrip' || field == 'requireFlightDate' ||
                  field == 'requireFlightType' || field == 'direction' ||
                  field == 'updating' || field == 'customerRequest' || 
                  field == 'serviceGroupId' || field == 'airline' ||
                  field == 'flightNumber' || field == "airport"
        continue if field == 'flightType' && (currentTripType == 'bus' || !flightTypeDisplayed(res))
        fields[fieldName(i, field)] = value
  fields["roundtrip"] = window.reduxStore.getState().reservations.legs[0].roundtrip
  fields["mode"] = window.reduxStore.getState().reservations.legs[0].mode
  fields['service_group_id'] = window.reduxStore.getState().reservations.legs[0].serviceGroupId

  adtl_params = window.reduxStore.getState().configuration.additionalFormParams
  fields = $.extend(fields, adtl_params) if adtl_params
  fields

# NOTE: Can be refactored better to support multi-leg and have less ifs
stateToParamsAirport = (universalToAirport) ->
  fields = {}

  for res, i in window.reduxStore.getState().reservations.legs

    # Universal/Airport mapping
    if universalToAirport && (res.pickup || res.dropoff)
      if res.direction == 'D'
        res.airport = res.dropoff?.code
        res.location = res.pickup
      else
        res.airport = res.pickup?.code
        res.location = res.dropoff
      delete res.airport?.airport

    for field, value of res
      if typeof(value) == 'object'
        for subfield, subvalue of value
          fieldKey = ''
          if field == 'flightTime'
            continue if subfield == 'year' || subfield == 'month' || subfield == 'day'
            switch subfield
              when 'date'
                fieldKey = fieldName(i, 'FlightTOD')
              when 'hour'
                fieldKey = fieldName(i, 'FlightTOD(4i)')
                newValue = if Number(subvalue) < 10 then  '0' else ''
                newValue += String(Number(subvalue))
                subvalue = newValue
              when 'minute'
                fieldKey = fieldName(i, 'FlightTOD(5i)')
                newValue = if Number(subvalue) < 10 then  '0' else ''
                newValue += String(Number(subvalue))
                subvalue = newValue
          else if field == 'location'
            if subfield == 'code'
              fieldKey = fieldName(i, 'location')
            else
              fieldKey = "#{fieldName(i, 'loc')}[#{subfield}]"
          else
            fieldKey = fieldName(i, subfield)
          unless field == 'pickup' || field == 'dropoff' || field == 'pickupTime' ||
                 field == 'dropoffTime'
            if fieldKey
              fields[fieldKey] = subvalue
      else unless field == 'roundtrip' || field == 'requireFlightDate' ||
                  field == 'requireFlightType' || field == 'updating' ||
                  field == 'customerRequest' || field == 'airline' ||
                  field == 'flightNumber'
        fields[fieldName(i, field)] = value

  fields["roundtrip"] = window.reduxStore.getState().reservations.legs[0].roundtrip
  fields["mode"] = window.reduxStore.getState().reservations.legs[0].mode
  adtl_params = window.reduxStore.getState().configuration.additionalFormParams
  fields = $.extend(fields, adtl_params) if adtl_params
  fields


export addExtraStop = (index) -> { type: 'ADD_RES_EXTRA_STOP', index, globalState: window.reduxStore.getState() }

export removeExtraStop = (index, stopIndex) ->
  { type: 'REMOVE_RES_EXTRA_STOP', index, stopIndex }

# Send reservation data to back-end but don't trigger service calc or error handling.
export saveReservation = -> submitReservation(save_data_only: true)

# Send reservation data to back-end
export submitReservation = (adtlParams = {}, forceAirportMode) ->
  promise = calculateDistanceAndTravelTimes(forceAirportMode).then (valuesHash) =>
      currentTripType = window.reduxStore.getState().tripTypes.current
      if !forceAirportMode && (currentTripType == 'universal' || currentTripType == 'bus')
        postParams = stateToParamsUniversal()
      else
        postParams = stateToParamsAirport(forceAirportMode)
      postParams.trip_type = currentTripType
      postParams[attr] = value for attr, value of valuesHash
      postParams[attr] = value for attr, value of adtlParams
      $.post(window.urls.reservations.submit, postParams)

  {
    promise: promise,
    types: ['SUBMIT_RES_REQUEST', 'SUBMIT_RES_SUCCESS', 'SUBMIT_RES_FAILURE']
  }

# Update a reservation location (pickup, dropoff).
export updateReservationLocation = (index, field, value) ->
  return  { type: 'NOOP' } if field != 'dropoff' && field != 'pickup'
  res = window.reduxStore.getState().reservations.legs[index]

  if $.isEmptyObject(value)
    promise = $.Deferred().resolve({ value: value }).promise()
  else
    # Airport res?
    if res.direction == 'D'
      airport = res.dropoff?.code
    else if res.directon == 'A'
      airport = res.pickup?.code

    # Each "pre-filter" that needs to run is a promise that returns an updated
    # values object. Each "pre-filter" should accept a "values" and return the
    # updated "values".
    promise = fleshOutLocation(res.providerSiteId, res.serviceArea, value, undefined, airport).then (locObject) =>
      $.Deferred().resolve({ value: locObject }).promise()

  {
    promise: promise, field, index,
    types: ['UPDATE_RES_LOC_REQUEST', 'UPDATE_RES_LOC', 'UPDATE_RES_LOC_FAILURE'],
  }

export updateCustomerRequest = (index, customerRequest) ->
  { type: 'SET_CUSTOMER_REQUEST', index, customerRequest }

export updateExtraStop = (index, stopIndex, values) ->
  { type: 'UPDATE_RES_EXTRA_STOP', index, stopIndex, values, globalState: window.reduxStore.getState() }

# Update an extra stop location.
export updateExtraStopLocation = (index, stopIndex, value) ->
  res = window.reduxStore.getState().reservations.legs[index]

  if $.isEmptyObject(value)
    promise = $.Deferred().resolve({ value: value }).promise()
  else
    # Each "pre-filter" that needs to run is a promise that returns an updated
    # values object. Each "pre-filter" should accept a "values" and return the
    # updated "values".
    promise = fleshOutLocation(res.providerSiteId, res.serviceArea, value, true).then (locObject) =>
      $.Deferred().resolve({ value: locObject }).promise()

  {
    promise: promise, stopIndex, index,
    types: ['UPDATE_RES_ES_LOC_REQUEST', 'UPDATE_RES_ES_LOC', 'UPDATE_RES_ES_LOC_FAILURE'],
  }

export updateReservationAirport = (index, airport) ->
  res = window.reduxStore.getState().reservations.legs[index]

  if res.airport == airport     
    { type: 'NOOP' } 
  else
    { type: 'UPDATE_RES_AIRPORT', index, airport, globalState: window.reduxStore.getState() }

# Accepts an object of key/value pairs for res values to be updated
export updateReservationFields = (index, values) ->
  { values: values, type: 'UPDATE_RES_FIELDS', index }

export reservationsReducer = (state = { }, action) ->
  currentTripType = state.currentTripType

  switch action.type
    when 'ADD_RES_EXTRA_STOP'
      clearStop = $.extend(true, {}, action.globalState.extraStops[action.index].fields)
      newRes = $.extend(true, {}, state.legs[action.index])
      newRes.extraStops.push({ fields: clearStop })
      newState = $.extend(true, {}, state)
      newState.legs[action.index] = newRes
      newState

    when 'CHANGE_GROUP_SUCCESS'
      # Group was attached, clear it's associated error if it's there.
      newState = $.extend(true, {}, state)
      delete newState.errors?.legs?[action.index]?['GroupAlias']
      newState

    when 'LOAD_LOC_TYPE_SUCCESS'
      return state unless action.passengerTypes

      # If the passenger types changed then reset the passenger count fields
      newRes = $.extend({}, state.legs[action.index])
      for field, value of newRes.passengers
        newPType = (p for p in action.passengerTypes when p.fieldName == field)[0]
        newRes.passengers[field] = 0 if !newPType

      newState = $.extend(true, {}, state)
      newState.legs[action.index] = newRes
      newState

    when 'REMOVE_RES_EXTRA_STOP'
      newRes = $.extend(true, {}, state.legs[action.index])
      newRes.extraStops.splice(action.stopIndex, 1)
      newState = $.extend(true, {}, state)
      newState.legs[action.index] = newRes
      newState

    when 'SET_CUSTOMER_REQUEST'
      newRes = $.extend({}, state.legs[action.index])
      newRes['customerRequest'] = action.customerRequest
      if action.customerRequest
        newRes['flightType'] = 'R'
      else
        newRes['flightType'] = 'D'
      newState = $.extend(true, {}, state)
      newState.legs[action.index] = newRes
      newState

    when 'SUBMIT_RES_REQUEST'
      newState = $.extend(true, {}, state, submitting: true)
      delete newState.errors
      newState
    when 'SUBMIT_RES_SUCCESS'
      if action.nextPage
        window.location = action.nextPage
      else if action.nextParentPage
        nextPage = action.nextParentPage
        sendMessageToParent('page_redirect', nextPage)
      else if action.errors
        return $.extend(true, {}, state, submitting: false,
                                         errors: action.errors)
      $.extend(true, {}, state)
    when 'SUBMIT_RES_FAILURE'
      console.error "Unable to submit reservation"
      alert "We were unable to submit your request."
      $.extend(true, {}, state, submitting: false)
    when 'UPDATE_RES_AIRPORT'
      values = { airport: action.airport }
      newSite = providerSiteFromAirport(action.airport, action.globalState.airports)
      newServiceArea = serviceAreaFromAirport(action.airport, action.globalState.airports)
      values.providerSiteId = newSite
      values.serviceArea = newServiceArea

      # If nothing changed, do nothing
      if state.legs[action.index].providerSiteId == newSite && state.legs[action.index].airport?.toUpperCase() == action.airport.toUpperCase()
        return state

      res = $.extend(true, {}, state.legs[action.index], values)
      res = clearSiteSpecificFields(res)
      newState = $.extend(true, {}, state)
      newState.legs[action.index] = res
      newState.submittable = isSubmittable(newState, currentTripType)
      newState

    when 'UPDATE_RES_EXTRA_STOP'
      newRes = $.extend({}, state.legs[action.index])
      if !newRes.extraStops[action.stopIndex]
        clearStop = $.extend(true, {}, action.globalState.extraStops[action.index].fields)
        newRes.extraStops[action.stopIndex] = { fields: clearStop }
      for fieldName, value of action.values
        newRes.extraStops[action.stopIndex].fields[fieldName] ||= {}
        newRes.extraStops[action.stopIndex].fields[fieldName].value = value
      newState = $.extend(true, {}, state)
      newState.legs[action.index] = newRes
      newState

    when 'UPDATE_RES_ES_LOC_REQUEST'
      newRes = $.extend(true, {}, state.legs[action.index])
      newRes.extraStops[action.stopIndex].updating = true
      newRes.extraStops[action.stopIndex].error = undefined

      newState = $.extend(true, {}, state)
      newState.legs[action.index] = newRes
      newState.submittable = false
      newState

    when 'UPDATE_RES_ES_LOC'
      newRes = $.extend(true, {}, state.legs[action.index])
      newRes.extraStops[action.stopIndex].updating = false
      newRes.extraStops[action.stopIndex].error = action.value.error

      map = {
        PickupAddr: 'address', PickupCity: 'city', PickupKey: 'code',
        PickupZip: 'zip', PickupCountry: 'country',
        PickupLocationDisp: 'display_name', PickupPlaceID: 'google_place_id',
        PickupType: 'location_type', PickupMapInfo: 'map_point',
        PickupLocation: 'name', PickupSector: 'sector', PickupState: 'state',
        PUDID: 'pud_id', ServiceArea: 'service_area'
      }
      editableFields = action.value['editableFieldsAsPickup'] || []
      for stopField, valField of map
        newRes.extraStops[action.stopIndex].fields[stopField] ||= {}
        newRes.extraStops[action.stopIndex].fields[stopField].value = action.value[valField]
        editable = editableFields.indexOf(valField) > -1
        newRes.extraStops[action.stopIndex].fields[stopField].locked = !editable

      newState = $.extend(true, {}, state)
      newState.legs[action.index] = newRes
      newState.submittable = true
      newState

    when 'UPDATE_RES_ES_LOC_FAILURE'
      newRes = $.extend({}, state.legs[action.index])
      newRes.extraStops[action.stopIndex].updating = false

      newState = $.extend(true, {}, state)
      newState.legs[action.index] = newRes
      newState.submittable = true
      newState

    when 'UPDATE_RES_LOC'
      newRes = $.extend({}, state.legs[action.index])
      newRes[action.field] = action.value
      newState = $.extend(true, {}, state)
      newState.legs[action.index] = newRes
      newState.submittable = isSubmittable(newState, currentTripType)
      delete newState.errors?.legs?[action.index]?[action.field]
      newState

    when 'UPDATE_RES_LOC_REQUEST'
      newRes = $.extend({}, state.legs[action.index])
      newRes[action.field] ||= {}
      newRes[action.field].updating = true
      newRes[action.field].error = undefined

      newState = $.extend(true, {}, state)
      newState.legs[action.index] = newRes
      newState.submittable = isSubmittable(newState, currentTripType)
      newState

    when 'UPDATE_RES_LOC_FAILURE'
      newRes = $.extend({}, state.legs[action.index])
      newRes[action.field] ||= {}
      newRes[action.field].updating = false

      newState = $.extend(true, {}, state)
      newState.legs[action.index] = newRes
      newState.submittable = isSubmittable(newState, currentTripType)
      newState

    when 'UPDATE_RES_REQUEST'
      res = $.extend({}, state.legs[action.index], { updating: true })
      newState = $.extend(true, {}, state)
      newState.legs[action.index] = res
      newState.submittable = isSubmittable(newState, currentTripType)
      newState
    when 'UPDATE_RES_FAILURE'
      console.error "Unable to update reservation fields"
      res = $.extend({}, state.legs[action.index], { updating: false })
      newState = $.extend(true, {}, state)
      newState.legs[action.index] = res
      newState.submittable = isSubmittable(newState, currentTripType)
      newState
    when 'UPDATE_RES_FIELDS'
      res = $.extend({}, state.legs[action.index], { updating: false })

      # If updating a location clear out the current values.
      res.location = {} if action.values.location

      # Clone the res object and update the clone's fields.
      res = $.extend(true, {}, res, action.values)
      siteChanged = (action.values.providerSiteId &&
                     action.values.providerSiteId !=
                      state.legs[action.index].providerSiteId)
      areaChanged = (action.values.serviceArea &&
                     action.values.serviceArea !=
                      state.legs[action.index].serviceArea)
      airportChanged = (action.values.regionCity && 
                        action.values.regionCity != 
                         state.legs[action.index].regionCity)

      if siteChanged || areaChanged || airportChanged
        res = clearSiteSpecificFields(res)

      newState = $.extend(true, {}, state)
      newState.legs[action.index] = res
      newState.submittable = isSubmittable(newState, currentTripType)

      # Clear field error
      for field, _value of action.values
        delete newState.errors?.legs?[action.index]?[field]

      newState
    else
      if currentTripType        
        submittable = isSubmittable(state, currentTripType)
      else
        submittable = false

      $.extend(true, {}, state, submittable: submittable)