import React from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import ReactList from 'react-list'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faCaretLeft from '@fortawesome/fontawesome-free-solid/faCaretLeft'

export default class SelectorList extends React.Component

  _clearHoverState: =>
    $(':hover, .hover', @_jqList()).removeClass('hover')
    @props.onHighlight?()

  _handleMouseMove: (e) =>
    $currentTarget = $('li:hover', @_jqList())
    return if @selectedChild().is($currentTarget)
    @selectedChild().removeClass('hover')
    $currentTarget.addClass('hover')
    @props.onHighlight?()

  _jqList: =>  $('div', ReactDOM.findDOMNode(@list))

  down: =>
    $selected = @selectedChild()
    if $selected.length
      $nextItem = $selected.parent().next().children('.selector-item')
      if $nextItem.length
        $selected.removeClass('hover')
        $nextItem.addClass('hover')
        @scrollList()
    else
      @_jqList().children('.selector-item').first().addClass('hover')
    @props.onHighlight?( @highlightedValue() )

  highlightedValue: => @selectedChild().data('name') || @selectedChild().text()

  left: => @props.onBack?()

  scrollList: (up) =>
    $parentPopup = @_jqList().parent('.selector-popup')
    return unless @_jqList().height() > $parentPopup.height()

    if $parentPopup.length && @selectedChild().length
      elemHeight = @selectedChild().outerHeight()
      if up
        newScrollTop = ($parentPopup.scrollTop() - elemHeight)
      else
        newScrollTop = ($parentPopup.scrollTop() + elemHeight)
      $parentPopup.scrollTop newScrollTop

  select: => @selectedChild().click()

  selectedChild: => $('.hover', @_jqList())

  uniqueId: =>
    @_uniqueId ||= Math.random().toString(36).substring(7)

  up: =>
    $selected = @selectedChild()
    $prevItem = $selected.parent().prev().children('.selector-item')
    if $selected.length && $prevItem.length
      $selected.removeClass('hover')
      $prevItem.addClass('hover')
      @scrollList(true)
      @props.onHighlight?( @highlightedValue() )
    else
      @_clearHoverState()

  renderItem: (index, key) => <div key={key}>{@props.children[index]}</div>

  render: ->
    backLabel = if @props.backLabel? then @props.backLabel else 'Back'

    if @props.onBack? && !@props.head?
      back = <li key="back" className="back" onClick={@props.onBack}>
               <FontAwesomeIcon icon={faCaretLeft}/> {backLabel}
             </li>
    if @props.head? && !@props.onBack?
      head = <li key="head" className="head hudson-subsection">{@props.head}</li>

    if @props.head? && @props.onBack?
      head = <li key="back" className="backHead hudson-subsection"
                 onClick={@props.onBack}>
               <div className="title">{@props.head}</div>
               <div className="backTitle">
                 <FontAwesomeIcon icon={faCaretLeft}/> {backLabel}
               </div>
             </li>

    <ul className="selector-list #{@props.className || ''}"
        id={@uniqueId()}
        onMouseMove={@_handleMouseMove} onMouseLeave={@_clearHoverState}>
      {head}
      {back}

      <ReactList
        itemRenderer={@renderItem}
        length={@props.children.length}
        ref={(list) => @list = list}
        />
    </ul>
