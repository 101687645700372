# Returns a list of location objects adapted from google prediction
# objects retrieved from a Google AutoComplete getPlacePredictions call.

_autocomplete = undefined;

_predictionToLocation =  (pred) ->
  if pred.terms.length == 1
    name = pred.terms[0].value
  else if String(Number(pred.terms[0].value)) == pred.terms[0].value
    name = pred.terms[0].value + ' ' + pred.terms[1].value
    cityState = pred.description.slice(pred.description.indexOf(pred.terms[2].value))
  else
    name = pred.terms[0].value
    cityState = pred.description.slice(pred.description.indexOf(pred.terms[1].value))

  { name, address: cityState, googlePlaceId: pred.place_id }

# Returns a promise that resolves into a location array.
export predictions = (query, searchCenter) ->
  deferredObject = $.Deferred()

  _autocomplete ||= new google.maps.places.AutocompleteService()
  acOptions = { types: ['establishment', 'geocode'], input: query }

  if searchCenter?.lat && searchCenter?.lng
    center = new google.maps.LatLng(searchCenter.lat, searchCenter.lng)
    acOptions.location = center
    acOptions.radius = 100000

  _autocomplete.getPlacePredictions acOptions, (predictions, status) =>
    if status == google.maps.places.PlacesServiceStatus.OK
      locations = (_predictionToLocation(place) for place in predictions)
      deferredObject.resolve(locations)
    else
      if status == google.maps.places.PlacesServiceStatus.ZERO_RESULTS
        deferredObject.resolve([])
      else
        console.error "Google autocomplete error: #{status}"
        deferredObject.reject(status)
  deferredObject.promise()
