import React from 'react';
import { connect } from 'react-redux'

import Airport from './airport'

class AirportSelector extends React.Component
  constructor: (props) -> super(props)

  render: -> <Airport {...@props}/>

# --- Connect to Redux
mapStateToProps = (state, ownProps) ->
  content: state.content.airport,
  mode: state.configuration.airportMode,
  airports: ownProps.airports || state.airports

export default connect(mapStateToProps)(AirportSelector)
