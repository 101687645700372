import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { autoResizeParent, parentScrollToTheTop } from "../utils/utils";
import Portal from "./global/portal";
import Standalone from "./global/standalone";

const DATA_QUERY = gql`
  query pageQuery {
    colors {
      buttonColor
      id
    }

    config(isWidget: ${window.isWidget}) {
      id
      editMode
      parentFrameResizeDisabled
      showPortalFrame
      showStandaloneFrame
    }

    content {
      id
      checkoutHeaderTitle
    }
  }
`;

const StyledPage = styled.div`
  line-height: 1.15;
  font: 400 13px/1.6 "Open Sans", sans-serif;
  margin: 0 auto;
  padding: 15px;

  @media (max-width: 767px) {
    overflow-x: hidden;
  }
`;

function OptionalPortal({ children, include = false }) {
  return include ? <Portal>{children}</Portal> : children;
}

OptionalPortal.propTypes = {
  children: PropTypes.any,
  include: PropTypes.bool,
};

function OptionalStandalone({ children, include = false }) {
  return include ? <Standalone>{children}</Standalone> : children;
}

OptionalStandalone.propTypes = {
  children: PropTypes.any,
  include: PropTypes.bool,
};

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function Page({ children, noGlobalWrap, noInitialScroll }) {
  const { loading, data, error } = useQuery(DATA_QUERY);

  // With components loading after the page has mounted we need to ensure
  // we're constantly checking that the page is sized correctly.
  useInterval(() => {
    autoSizeParentFrame(data);
  }, 200);

  useEffect(() => {
    // Don't allow "enter" to submit forms.
    window.addEventListener(
      "keydown",
      function (e) {
        if (e.keyIdentifier == "U+000A" || e.keyIdentifier == "Enter" || e.keyCode == 13) {
          if (e.target.nodeName == "INPUT" && e.target.type == "text") {
            e.preventDefault();
            return false;
          }
        }
      },
      true
    );

    // Resize frame when window is resized.
    window.addEventListener("resize", autoSizeParentFrame);

    if (!noInitialScroll) {
      parentScrollToTheTop();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || error) {
    if (error) console.error(error);
    return null;
  }

  function autoSizeParentFrame(data) {
    if (!data || !data.config || data.config.parentFrameResizeDisabled) return;

    // We need to make sure that all browser renders have happened before trying
    // to resize the parent.
    // see http://stackoverflow.com/questions/26556436/react-after-render-code
    const callback = autoResizeParent;
    setTimeout(() => window.requestAnimationFrame(callback), 0);
  }

  return (
    <OptionalStandalone include={!noGlobalWrap && data.config.showStandaloneFrame}>
      <OptionalPortal include={!noGlobalWrap && data.config.showPortalFrame}>
        <StyledPage>{children}</StyledPage>
      </OptionalPortal>
    </OptionalStandalone>
  );
}

Page.propTypes = {
  children: PropTypes.any,
  noGlobalWrap: PropTypes.bool,
  noInitialScroll: PropTypes.bool,
};

export default Page;
