import gql from "graphql-tag";
import FileLoader from "file-loader";

function updateCartField(field, value = "", cache) {
  const query = gql`
    query updateCartQuery {
      cart {
        id
      }
    }
  `;

  const newCart = { ...cache.readQuery({ query }).cart, [field]: value };
  cache.writeQuery({ query, data: { cart: newCart } });
  return newCart;
}

export const mutations = {
  updateCartField: (_, { field, value }, { cache }) => {
    return updateCartField(field, value, cache);
  },
};
