import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import './hourly.scss'
import DateTime from '../date_time/date_time'
import SupplementaryInfo from './supplementary_info'

class Hourly extends React.Component
  constructor: (props) ->
    super(props)
    @state = {
      hours: @calculateHours(),
      minutes: @calculateMinutes()
    }

  componentDidMount: -> @updateDropoff()

  componentDidUpdate: (prevProps, prevState) ->
    if prevProps.fields.pickupTime?.date != @props.fields.pickupTime?.date ||
       prevProps.fields.pickupTime?.hour != @props.fields.pickupTime?.hour ||
       prevProps.fields.pickupTime?.minute != @props.fields.pickupTime?.minute
      @updateDropoff()

  # Logic grabbed from github.com/eternicode/bootstrap-datepicker
  _formatDate: (date) =>
    return '' unless date
    format = @_parseFormat(@props.options.dateFormat)
    val = {
      d: date.getDate(),
      m: date.getMonth() + 1,
      yy: date.getFullYear().toString().substring(2),
      yyyy: date.getFullYear()
    }
    val.dd = if val.d < 10 then "0#{val.d}" else val.d
    val.mm = if val.m < 10 then "0#{val.m}" else val.m

    date = []
    seps = $.extend([], format.separators)

    for part in format.parts
      date.push(seps.shift()) if seps.length
      date.push(val[part])
    date.join('')

  _formatTime: (hour, minute) =>
    return null unless hour? && minute?
    date = new Date(2000, 1, 1, hour, minute)
    hour12 = Number(@props.options.timeFormat) == 12
    output = date.toLocaleTimeString(@props.options.language,
              { hour: '2-digit', minute:'2-digit', hour12: hour12 })

    # Some older browsers will show seconds. If that's the case try to remove them.
    secondsShown = /(\d\d?:\d\d):\d\d/
    if secondsShown.test(output)
      matches = secondsShown.exec(output)
      output = output.replace(matches[0], matches[1])

    return output

  # Calculate hours from dropoff time.
  calculateHours: =>
    unless @props.fields.dropoffTime?.hour && @props.fields.pickupTime?.hour
      return 1
    hrs = Math.abs(@props.fields.dropoffTime.hour - @props.fields.pickupTime.hour)
    mins = @props.fields.dropoffTime.minute - @props.fields.pickupTime.minute
    hrs -= 1 if mins < 0
    hrs || 1

  # Calculate minutes from dropoff time (round to nearest "15" minute mark)
  calculateMinutes: =>
    unless @props.fields.dropoffTime?.minute && @props.fields.pickupTime?.minute
      return 0
    mins = @props.fields.dropoffTime.minute - @props.fields.pickupTime.minute
    mins = 15.0 * Math.round(mins/15.0)
    mins += 60 if mins < 0
    mins

  hoursChanged: (e) =>
    @setState hours: e.target.value
    @updateDropoff()

  minutesChanged: (e) =>
    @setState minutes: e.target.value
    @updateDropoff()

  # Logic grabbed from github.com/eternicode/bootstrap-datepicker
  _parseFormat: (format) =>
    validParts = /dd?|DD?|mm?|MM?|yy(?:yy)?/g
    separators = format.replace(validParts, '\0').split('\0')
    parts = format.match(validParts)
    if (!separators || !separators.length || !parts || parts.length == 0)
      throw new Error("Invalid date format.")
    { separators: separators, parts: parts }

  # Update the dropoff time based off pickup and the set hours/minutes
  updateDropoff: =>
    return unless @props.fields.pickupTime?.date
    pu = @props.fields.pickupTime
    currentPickup = new Date(pu.year, pu.month-1, pu.day, pu.hour, pu.minute)
    adtlTimeInMin = (Number(@refs.hours.value) * 60) + Number(@refs.minutes.value)
    dropoff = new Date(currentPickup.getTime() + (adtlTimeInMin * 60 * 1000))
    @props.onChange(dropoffTime: {
      date: @_formatDate(dropoff),
      year: dropoff.getFullYear(),
      month: dropoff.getMonth() + 1,
      day: dropoff.getDate(),
      hour: dropoff.getHours(),
      minute: dropoff.getMinutes()
    })

  renderDropoffDate: ->
    unless @props.fields.dropoffTime?.date && @props.fields.pickupTime?.date &&
           @props.fields.pickupTime.hour && @props.fields.pickupTime.minute
      return null

    if @props.fields.dropoffTime.date == @props.fields.pickupTime.date
      date = null
    else
      date = <span>{@props.fields.dropoffTime.date}</span>

    <div className="dropoff-date">
      <label dangerouslySetInnerHTML={{__html: @props.content.hourly.dropoffTimeLabel}}/>
      <p>
        {date}
        <span>{@_formatTime(@props.fields.dropoffTime.hour,
                            @props.fields.dropoffTime.minute)}</span>
      </p>
    </div>

  renderHoursMins: ->
    hrs = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
           '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
    hrs.push(@state.hours) if @state.hours && $.inArray(String(@state.hours), hrs) == -1
    mins = ['0', '15', '30', '45']
    mins.push(@state.minutes) if @state.minutes && $.inArray(String(@state.minutes), mins) == -1

    <div className="hours">
      <div>
        <label dangerouslySetInnerHTML={{__html: @props.content.hourly.hourLabel}}/>
        <div className="form-group form-inline">
          <select ref="hours" value={@state.hours} onChange={@hoursChanged}>
            {<option value={hr} key="hr#{hr}">{hr}</option> for hr in hrs}
          </select>
        </div>
      </div>
      <div className="minutes">
        <label dangerouslySetInnerHTML={{__html: @props.content.hourly.minuteLabel}}/>
        <div className="form-group form-inline">
          <select ref="minutes" value={@state.minutes} onChange={@minutesChanged}>
            {<option value={min} key="m#{min}">{min}</option> for min in mins}
          </select>
        </div>
      </div>
    </div>

  renderPickupDate: ->
    <DateTime
      controlled={true}
      content={@props.content.pickupDate}
      errors={{
        date: @props.errors.pickupTime?.date,
        time: @props.errors.pickupTime?.time
      }}
      format={{
        date: @props.options.dateFormat, time: @props.options.timeFormat
      }}
      onDateChange={(date) => @props.onChange(pickupTime: date)}
      onHourChange={(hour) => @props.onChange(pickupTime: { hour })}
      onMinuteChange={(minute) => @props.onChange(pickupTime: { minute })}
      onViewportChange={@props.onViewportChange}
      options={{ language: @props.options.language }}
      value={@props.fields.pickupTime}/>

  render: ->
    <SupplementaryInfo className="hourly">
      {@renderPickupDate()}
      {@renderHoursMins()}
      {@renderDropoffDate()}
    </SupplementaryInfo>

Hourly.propTypes = {
  content: PropTypes.shape({
    hourly: PropTypes.shape({
      dropoffTimeLabel: PropTypes.string,
      hourLabel: PropTypes.string,
      hoursMinsRequiredError: PropTypes.string,
      minuteLabel: PropTypes.string
    }),
    pickupDate: PropTypes.object
  }),
  errors: PropTypes.shape({ pickupTime: PropTypes.object }),
  fields: PropTypes.shape({
    dropoffTime: PropTypes.object,
    pickupTime: PropTypes.object,
  }),
  onChange: PropTypes.func.isRequired,
  onViewportChange: PropTypes.func,
  options: PropTypes.shape({
    dateFormat: PropTypes.string,
    language: PropTypes.string,
    timeFormat: PropTypes.string
  })
}


# --- Connect to Redux
mapStateToProps = (state, ownProps) ->
  content: $.extend(true, {}, state.content, (ownProps.content || {})),
  options: $.extend(true, {}, state.configuration, (ownProps.options || {}))

export default connect(mapStateToProps)(Hourly)
