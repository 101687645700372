import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { dispatchCustomEvent } from "../../utils/analytic_events";
import axios from "axios";
import styled from "styled-components";
import NoticeBox from "../notice_box";
import ValuePack from "./value_pack";

const VALUE_PACK_QUERY = gql`
  query valuePackFormQuery {
    valuePacks {
      id
      cost
      description
      name
    }
  }
`;

const ErrorMessage = styled(NoticeBox)`
  max-width: 550px;
  margin: 25px auto auto auto;
`;

function ValuePackForm() {
  const { loading, data, dataError } = useQuery(VALUE_PACK_QUERY);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  if (dataError || loading) {
    if (dataError) console.error(dataError);
    return null;
  }

  const submit = function(program, amount) {
    if (submitting) return;
    dispatchCustomEvent("hudson.ma.entrySubmit");

    setSubmitting(program);
    setError(undefined);

    axios
      .post(window.urls.reservations.addValuePack, {
        program: program,
        amount: amount
      })
      .then(response => {
        if (response.data.error) {
          setSubmitting(false);
          setError(response.data.error);
        } else {
          window.location = response.data.nextUrl;
        }
      })
      .catch(error => setError(error));
  };

  const renderPack = function(pack) {
    return (
      <ValuePack
        key={pack.id}
        disabled={!!submitting}
        onAddToCart={quantity => submit(pack.id, quantity)}
        submitting={submitting == pack.id}
        valuePack={pack}
      />
    );
  };

  return (
    <div>
      <ErrorMessage type="error" content={{ message: error }} />
      {data.valuePacks.map(pack => renderPack(pack))}
    </div>
  );
}

export default ValuePackForm;
