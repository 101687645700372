import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faCircleNotch from '@fortawesome/fontawesome-free-solid/faCircleNotch'

export default class InputWithIcon extends React.Component
  constructor: (props) ->
    super(props)
    @state = { lastInputDate: new Date, stateValue: @props.value }

  UNSAFE_componentWillReceiveProps: (newProps) ->
    stateValue = @state.stateValue
    elementValue = @fieldRef().value

    # Fix for IE 11 text input duplication issues, adapted from:
    # https://github.com/r0man/sablono/commit/579f3d77271afd9edacc4fa0813f93ff42fe33a6
    # on IE, onChange event might come after actual value of an element
    # have changed. We detect this and render element as-is, hoping that
    # next onChange will eventually come and bring our modifications anyways.
    # Ignoring this causes skipped letters in controlled components

    now = new Date
    isIE11 = !!window.MSInputMethodContext && !!document.documentMode
    if !isIE11 || !newProps.valueTime
      @setState lastInputDate: now, stateValue: newProps.value
      return

    if stateValue == elementValue && @state.lastInputDate >= newProps.valueTime
      return

    if stateValue != elementValue
      @setState lastInputDate: now, stateValue: elementValue
    else
      @setState lastInputDate: now, stateValue: newProps.value

  fieldRef: => @refs.field

  focus: => @refs.field.focus()

  isBlank: => !$.trim(@refs.field.value).length

  value: => @fieldRef().value

  render: ->
    props = {
      type: @props.type,
      className: "form-control",
      autoComplete: @props.autoComplete || 'disabled', 
      autoCorrect: 'off', autoCapitalize: 'off',
      spellCheck: 'false',
      placeholder: @props.content?.placeholder,
      ref: "field",
      name: @props.name,
      onChange: @props.onChange, onFocus: @props.onFocus,
      onInput: @props.onInput,
      onKeyDown: @props.onKeyDown,
      defaultValue: @props.defaultValue,
      value: @state.stateValue,
      readOnly: @props.readOnly,
      disabled: @props.disabled
    }

    if @props.onIconClick
      iconClassName = 'clickable'

    if @props.loading
      icon = <FontAwesomeIcon icon={faCircleNotch} spin/>
    else if @props.content?.icon
      icon = React.cloneElement(@props.content.icon, {
        className: iconClassName, 
        onClick: (e) =>
          return if @props.disabled
          if @props.onIconClick 
            @props.onIconClick(e)
          else if @props.onFocus
            @props.onFocus(e)        
      })

    <div className="form-group input-with-icon #{@props.className}"
         style={@props.style}>
      <input {...props}/>
      {@props.children}
      {icon}
    </div>

InputWithIcon.defaultProps = {
  disabled: false,
  readOnly: false,
  type: 'text'
}

InputWithIcon.propTypes = {
  className: PropTypes.string,
  content: PropTypes.shape({
    icon: PropTypes.object, 
    placeholder: PropTypes.string
  }),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onIconClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  readOnly: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.string
}
