import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Button from '@material-ui/core/Button';
import { graphql } from '@apollo/react-hoc';
import gql from "graphql-tag";

import './entry_page.scss'
import './entry_page_widget.scss'
import { autoResizeParent, isMobile } from '../utils/utils'
import { dispatchCustomEvent } from '../utils/analytic_events';
import { submitReservation } from '../redux/reservations'
import ActionBar from '../components/entry_page/action_bar'
import { ReduxedTripTypeSelector } from '../components/trip_type_selector'
import AirportForm from '../components/entry_page/airport_form'
import BusForm from '../components/entry_page/bus_form'
import MultiLegForm from '../components/entry_page/multi_leg_form'
import UniversalForm from '../components/entry_page/universal_form'
import ValuePackForm from '../components/entry_page/value_pack_form'
import NoticeBox from '../components/notice_box'

class EntryPage extends React.Component
  componentDidUpdate: (prevProps, prevState) ->
    $(@refs.submit).button('loading') if !prevProps.submitting && @props.submitting
    $(@refs.submit).button('reset') if prevProps.submitting && !@props.submitting

  autoSizeParentFrame: =>
    return if @props.options.parentFrameResizeDisabled || !@props.options.id

    # We need to make sure that all browser renders have happened before trying
    # to resize the parent.
    # see http://stackoverflow.com/questions/26556436/react-after-render-code
    callback = autoResizeParent
    setTimeout ( => window.requestAnimationFrame(callback)), 0


  renderActions: ->
    options = {
      preferredRiderHomeURL: window.urls.parent.prefRiderHome
    }
    # Accessing the parent frame when we're not allowed will throw an "Access Denied"
    # exception. The long-term solution is to use HTML5 messaging to access
    # these preferences instead of trying to access them directly.
    try
      if window.frameElement
        if !@props.options.lockGroupProfile
          options.lockGroupProfile = $(window.frameElement).data('ma-lock-group-profile')

        # Can remove the next two lines after everybody is on the new CRM plugin
        home_override = $(window.frameElement).data('ma-pref-rider-home-url')
        options.preferredRiderHomeURL = home_override if home_override          
    catch
      null

    return '' if @props.options.hideActions
    <ActionBar options={options}/>

  renderButtonContainer: ->
    # Airport Universal, Bus, Value Packs handle the submit button themselves.
    if @props.currentTripType == 'airport' || @props.currentTripType == 'universal' ||
       @props.currentTripType == 'bus' || @props.currentTripType == 'value_pack'
      return null       
    <div className="ma-button-container">{@renderSubmit()}</div>
  
  renderForm: ->
    switch @props.currentTripType
      when 'airport'
        <AirportForm onViewportChanged={@autoSizeParentFrame}
                     submitButton={@renderSubmit}/>
      when 'bus'
        <BusForm onViewportChanged={@autoSizeParentFrame}
                 submitButton={@renderSubmit}/>
      when 'multi'
        <MultiLegForm onViewportChanged={@autoSizeParentFrame}/>
      when 'universal'
        <UniversalForm onViewportChanged={@autoSizeParentFrame}
                       submitButton={@renderSubmit}/>
      when 'value_pack'
        <ValuePackForm onViewportChanged={@autoSizeParentFrame}/>
      else
        console.warn "No form associated with trip type #{@props.currentTripType}"
        ''

  renderHeader: ->
    return null if @props.options.hideHeader
    <div dangerouslySetInnerHTML={{__html: @props.content?.header}}
         style={{ overflow: 'auto' }}/>

  renderNoticeBox: ->
    return null if @props.options.optimizeSpace
    if @props.notice then  <NoticeBox  {...@props.notice}/> else ''

  renderSubmit: (beforeSubmit, forceAirportMode) =>
    buttonProps = {
      className: "ma-button btn-hudson",
      disabled: !@props.submittable,
      variant: 'contained'
    }

    if @props.submitting
      label = @props.content.submitLoading
    else
      label = @props.content.submitLabel

    buttonProps.onClick = (e) =>
      e.preventDefault()
      return if @props.submitting
      beforeSubmit() if beforeSubmit
      
      dispatchCustomEvent("hudson.ma.entrySubmit")
      @props.submitForm(undefined, forceAirportMode)
    
    if @props.options.optimizeSpace
      buttonProps.className += " ma-widget-button"
      
    <Button {...buttonProps}>{label}</Button>

  renderTripTypeSelector: ->
    unless @props.options.hideTripTypeSelector || @props.options.editMode
      <ReduxedTripTypeSelector/>
    else
      null

  render: ->
    return null if !@props.options.id    

    <div className="entry-page" ref="self">
      {@renderHeader()}
      {@renderNoticeBox()}
      {@renderTripTypeSelector()}
      {@renderActions()}

      <form role="form">
        {@renderForm()}
        {@renderButtonContainer()}
      </form>
    </div>

EntryPage.propTypes = {
  content: PropTypes.shape({
    header: PropTypes.string,
    submitLabel: PropTypes.string,
    submitLoading: PropTypes.string
  }),
  currentTripType: PropTypes.string,
  notice: PropTypes.object,
  submitForm: PropTypes.func,
  submitting: PropTypes.bool,
  submittable: PropTypes.bool
}

# --- Connect to Redux
mapStateToProps = (state) ->
  props = {
    content: state.content.entryPage,
    currentTripType: state.tripTypes.current,
    submittable: state.reservations.submittable,
    submitting: state.reservations.submitting
  }

  if state.reservations.errors?.base
    props.notice = {
      content: { message: state.reservations.errors.base },
      type: 'error'
    }

  props

mapDispatchToProps = (dispatch) -> bindActionCreators({
  submitForm: submitReservation
}, dispatch)


# -- Apollo / GraphQL
apolloComponent = graphql(gql("
  query entryPageQuery {
    config(isWidget: #{window.isWidget}) {
      id
      editMode
      hideActions
      hideHeader
      hideTripTypeSelector
      lockGroupProfile
      optimizeSpace
      parentFrameResizeDisabled
      }
  }"),  
  {
    props: (apollo) => 
      return {
        options: apollo.data.config || {}
      }
  }
)(EntryPage)

export default connect(mapStateToProps, mapDispatchToProps)(apolloComponent)