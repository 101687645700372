import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import './res_edit_header.scss'

class ResEditHeader extends React.Component
  constructor: (props) -> super(props)

  goBack: =>
    $(@refs.backButton).button('loading')
    document.location = window.urls.viewRes

  renderBackLink: ->
    <div className="btn btn-danger btn-xs back-button"
            data-loading-text={@props.content.backInProgressMessage}
            ref="backButton"
            onClick={@goBack}>
      {@props.content.backLabel}
    </div>

  renderGeneralHeader: ->
    return null unless @props.content.generalHeader
    <div className="general-header"
         dangerouslySetInnerHTML={{__html: @props.content.generalHeader}}/>

  render: ->
    <div className="res-edit-header">
      <div className="resid">{@props.res.resId}</div>
      <div className="res-details">
        <div>
          <label dangerouslySetInnerHTML={{__html: @props.content.nameLabel}}/>
          {@props.res.name}
        </div>
        <div>
          <label dangerouslySetInnerHTML={{__html: @props.content.serviceLabel}}/>
          {@props.res.serviceName}</div>
        {@renderBackLink()}
      </div>
      {@renderGeneralHeader()}
    </div>

ResEditHeader.propTypes = {
  content: PropTypes.shape({
    backInProgressMessage: PropTypes.string,
    backLabel: PropTypes.string,
    generalHeader: PropTypes.string,
    nameLabel: PropTypes.string,
    serviceLabel: PropTypes.string
  }),
  res: PropTypes.shape({
    name: PropTypes.string,
    resId: PropTypes.string.isRequired
  })
}

# --- Connect to Redux
mapStateToProps = (state) ->
  content: state.content.resEditHeader

export default connect(mapStateToProps)(ResEditHeader)
