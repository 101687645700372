import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faChevronDown from '@fortawesome/fontawesome-free-solid/faChevronDown'
import faUser from '@fortawesome/fontawesome-free-solid/faUser'

import './passenger_selector.scss'
import FakeField from '../fake_field'
import SelectorPopup from '../selector_popup'

class PassengerSelector extends React.Component
  constructor: (props) ->
    super(props)
    @state = { displayPopup: false }

  componentDidMount: ->
    # If a user is tabbing between fields we want to hide the popup if they
    # have tabbed away from it.
    $(document).on 'focusin', =>
      isMobile = window.matchMedia('screen and (max-width: 767px)').matches
      return unless document.activeElement && !isMobile
      if $(@refs.container)[0] && document.activeElement && !$.contains(@refs.container, document.activeElement)
        @hidePopup() 

  componentDidUpdate: (prevProps, prevState) ->
    # Resize page if popup is shown or hidden
    if !prevState.displayPopup && @state.displayPopup
      @props.onViewportChange()
    else if prevState.displayPopup && !@state.displayPopup
      @props.onViewportChange()

  hidePopup: => @setState displayPopup: false

  showPopup: => @setState displayPopup: true

  totalPassengers: =>
    total = 0
    total += Number(value) for field, value of @props.value
    if total == 0 && !@props.options.showNumberOnZero
      @props.content.selectLabel 
    else 
      total

  renderError: ->
    return '' unless @props.error
    <label className="error-label"
           dangerouslySetInnerHTML={{__html: @props.error}}/>

  renderField: ->
    fakeFieldProps = {
      content: { icon: <FontAwesomeIcon icon={faChevronDown}/> },
      ref: 'field',
      onClick: @showPopup, onFocus: @showPopup,
      value: <div>
        {@totalPassengers()}
        <FontAwesomeIcon size='xs' icon={faUser}/>
      </div>
    }
    fakeFieldProps.className = 'has-error' if @props.error
    <FakeField {...fakeFieldProps}/>

  renderPassengerType: (type) ->
    onChange = (e) =>
      @props.onChange(type.fieldName, Number(e.target.value))
      setTimeout(@hidePopup, 100) if @props.passengerTypes.length == 1

    className = "passenger-count form-control"
    className += ' has-error' if @props.error

    <div className="passenger-type" key={"#{type.fieldName} #{type.passengerTypeAlias || ''}"}>
        <label dangerouslySetInnerHTML={{__html: type.label}}/>
        <select className={className} onChange={onChange}
                value={@props.value?[type.fieldName]}>
          {for i in [0..type.maxPassengers]
            <option value={i} key={"#{type.fieldName} #{type.passengerTypeAlias || ''} #{i}"}>{i}</option> }
        </select>
    </div>

  renderPassengerTypes: ->
    @renderPassengerType(type) for type in @props.passengerTypes

  renderPopup: ->
    return null unless @state.displayPopup
    <SelectorPopup content={@props.content} onClose={@hidePopup}>
      <div className="contents">
        {@renderPassengerTypes()}
      </div>
    </SelectorPopup>

  render: ->
    className = "passenger-selector"
    className += " new-style" if !@props.options?.oldStyle

    if @props.passengerTypes.length == 1 &&
       @props.passengerTypes[0].label.length < 30
      usePopup = false
      label = null
      className += " single"
    else
      usePopup = !@props.options || @props.options?.usePopup
      label = <label>{@props.content.label}</label>
      className += " popuped"

    className += " flattened" unless usePopup

    <div className={className} ref="container">      
      {
        if usePopup
          <div>
            {label}
            {@renderField()}
            {@renderPopup()}
          </div>
        else
          <div>
            {@renderPassengerTypes()}
          </div>
      }
      {@renderError()}
    </div>

PassengerSelector.propTypes = {
  content: PropTypes.shape({
    label: PropTypes.string,
    selectLabel: PropTypes.string,
    totalPassengersLabel: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  onViewportChange: PropTypes.func,
  options: PropTypes.shape({
    usePopup: PropTypes.bool
  }),
  passengerTypes: PropTypes.array.isRequired,
  value: PropTypes.object
}


# --- Connect to Redux
mapStateToProps = (state) ->
  content: state.content.passengerTypes,
  passengerTypes: state.passengerTypes

export default PassengerSelector = connect(mapStateToProps)(PassengerSelector)
