import gql from "graphql-tag";

const analyticsLocationFragment = gql`
  fragment analyticsLocationFragment on Location {
    address
    city
    code
    displayName
    state
    zip
  }
`;

export const analyticsResFragment = gql`
  fragment analyticsResFragment on Reservation {
    dropoff {
      ...analyticsLocationFragment
    }
    dropoffTime {
      xml
    }
    fare
    fareDisplay
    itineraryId
    pickup {
      ...analyticsLocationFragment
    }
    pickupTime {
      xml
    }
    resId
    salesTax
    serviceArea
    serviceAreaName
    serviceName
    serviceType
    totalPassengers
    uniqueId
  }

  ${analyticsLocationFragment}
`;

function analyticsLocation(resLoc) {
  let loc = resLoc || {};
  return {
    address: loc.address,
    city: loc.city,
    code: loc.code,
    name: loc.displayName || loc.display_name,
    postalCode: loc.zip,
    state: loc.state
  };
}

function analyticsTime(xmlTime) {
  if (!xmlTime) return undefined;
  // Strip off any sort of time zone designation.
  return xmlTime.replace(/-\d\d:\d\d$/, "");
}

export function dispatchCustomEvent(
  eventName,
  inReservations,
  isSingleRes,
  cartFields
) {
  // Ensure reservations is an array.
  const reservations = inReservations ? [].concat(inReservations) : [];

  // Create analytics res objects.
  let resData = [];
  reservations.forEach(leg => {
    const resObject = {
      dropoff: analyticsLocation(leg.dropoff),
      dropoffTime: analyticsTime(leg.dropoffTime && leg.dropoffTime.xml),
      id: leg.uniqueId,
      fare: Number(leg.fareDisplay || leg.fare).toFixed(2),
      passengerCount: leg.totalPassengers,
      pickup: analyticsLocation(leg.pickup),
      pickupTime: analyticsTime(leg.pickupTime && leg.pickupTime.xml),
      salesTax: Number(leg.salesTax).toFixed(2),
      service: {
        id: leg.serviceType,
        name: leg.serviceName
      },
      serviceArea: {
        id: leg.serviceArea,
        name: leg.serviceAreaName
      }
    };

    if (leg.resId) resObject.idRes = leg.resId;
    if (leg.itineraryId) resObject.idItinerary = leg.itineraryId;
    if (cartFields) {
      resObject.email = cartFields.EmailAddr || leg.email;
      resObject.name = cartFields.Name || leg.name;
    }

    resData.push(resObject);
  });

  const eventDetail = { detail: {} };
  if (inReservations) {
    eventDetail.detail.reservations = resData;
    if (isSingleRes) {
      eventDetail.detail.reservation = resData[0];
    }
  }

  const customEvent = new CustomEvent(eventName, eventDetail);
  window.dispatchEvent(customEvent);
}
