import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  updateReservationAirport, updateCustomerRequest, updateReservationFields
} from '../../redux/reservations'
import Reservation from './reservation/airport_with_apollo'

class MultiLegForm extends React.Component
  constructor: (props) -> super(props)

  renderHeader: ->
    return '' unless @props.content.header
    <div className="entry-page-header"
         dangerouslySetInnerHTML={{__html: @props.content.header}}/>

  renderReservation: (i) ->
    <div className="entrypage-form-multi-reservation" key={"reservation#{i}"}>
      <h3>{@props.content.legHeaders[i]}</h3>
      <Reservation index={i}
        errors={@props.errors?.legs?[i] || {}}
        fields={@props.reservations[i]}
        options={{onewayOnly: true}}
        onAirportChanged={(airport) => @props.onAirportChanged(i, airport)}
        onCustomerRequestChanged={(value) => @props.onCustomerRequestChanged(i, value)}
        onFieldsChanged={(fields) => @props.onFieldsChanged(i, fields)}
        onViewportChanged={@props.onViewportChanged}/>
    </div>

  render: ->
    <div>
      {@renderHeader()}
      {@renderReservation(i) for i in [0..@props.maxLegs-1]}
    </div>

MultiLegForm.propTypes ={
  content: PropTypes.shape({
    header: PropTypes.string,
    legHeaders: PropTypes.array
  }),
  errors: PropTypes.object,
  maxLegs: PropTypes.number,
  onAirportChanged: PropTypes.func.isRequired,
  onFieldsChanged: PropTypes.func.isRequired,
  reservations: PropTypes.array.isRequired
}

# --- Connect to Redux
mapStateToProps = (state) ->
  content: state.content.trip.multi,
  errors: state.reservations.errors,
  maxLegs: state.configuration.maxLegs,
  reservations: state.reservations.legs

mapDispatchToProps = (dispatch) -> bindActionCreators({
  onAirportChanged: updateReservationAirport,
  onCustomerRequestChanged: updateCustomerRequest,
  onFieldsChanged: updateReservationFields
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MultiLegForm)
