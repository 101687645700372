import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import CardContent from "@material-ui/core/CardContent";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import SelectList from "../fields/select_list";

const CONTENT_QUERY = gql`
  query valuePackQuery {
    content {
      id
      pageVpEachLabel
      pageVpQuantityLabel
      pageVpSubmitLabel
      pageVpSubmitLoading
    }
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  button {
    font-size: 1em;
  }
`;

const CostContainer = styled.div`
  margin-right: 20px;
`;

const Cost = styled.span`
  font-weight: bold;
  font-size: 20px;
  margin-right: 4px;
`;

const Each = styled.span``;

const ValuePackCard = styled.div`
  max-width: 550px;
  margin: 50px auto;
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
`;

const Header = styled.h2`
  font-size: 22px;
`;

const Quantity = styled.div`
  margin-right: 20px;
`;

function ValuePack({ disabled, onAddToCart, submitting, valuePack }) {
  const { loading, data, error } = useQuery(CONTENT_QUERY);
  const [quantity, setQuantity] = useState(0);

  if (error || loading) {
    if (error) console.error(error);
    return null;
  }

  const renderButton = function() {
    if (loading) return null;

    const buttonProps = {
      className: "ma-button btn-hudson",
      disabled: disabled || submitting || quantity == 0,
      variant: "contained"
    };

    const label = submitting
      ? data.content.pageVpSubmitLoading
      : data.content.pageVpSubmitLabel;

    buttonProps.onClick = () => onAddToCart(quantity);

    return <Button {...buttonProps}>{label}</Button>;
  };

  return (
    <ValuePackCard key={`pack${valuePack.id}`}>
      <CardContent>
        <Header>{valuePack.name}</Header>
        <div dangerouslySetInnerHTML={{ __html: valuePack.description }} />
        <Actions>
          <CostContainer>
            <Cost>${valuePack.cost}</Cost>
            <Each>{data.content.pageVpEachLabel}</Each>
          </CostContainer>
          <Quantity>
            {data.content.pageVpQuantityLabel}
            <SelectList
              field={`amount[${valuePack.id}]`}
              onChange={value => setQuantity(value)}
              value={quantity}
              values={{
                0: 0,
                1: 1,
                2: 2,
                3: 3,
                4: 4,
                5: 5,
                6: 6,
                7: 7,
                8: 8,
                9: 9
              }}
            />
          </Quantity>
          {renderButton()}
        </Actions>
      </CardContent>
    </ValuePackCard>
  );
}

ValuePack.propTypes = {
  disabled: PropTypes.bool,
  onAddToCart: PropTypes.func,
  submitting: PropTypes.bool,
  valuePack: PropTypes.object
};

export default ValuePack;
