import React from 'react';
import { connect } from 'react-redux'

import LocationSelector from './location_selector/selector'

class ReduxedLocationSelector extends React.Component
  constructor: (props) -> super(props)

  left: => @refs.selector.left?()
  down: => @refs.selector.down?()
  select: => @refs.selector.select?()
  up: => @refs.selector.up?()

  render: -> <LocationSelector ref="selector" {...@props}/>

# --- Connect to Redux
mapStateToProps = (state, ownProps) ->
  content: state.content.location

export default connect(mapStateToProps, null, null, { forwardRef: true })(ReduxedLocationSelector)
