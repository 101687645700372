import { myHeight, parentRedirect, parentScrollToElement } from '../utils/utils'

export default class App
  constructor: ->
    @_noticeBoxId = '#notice_box'
    @_heightAdjust  = 0

    $('document').ready =>
      @sendPageLoadedMessage()

      # Don't allow "enter" to submit forms.
      $(window).not('textarea').keydown (event) =>
        if event.keyCode is 13
          event.preventDefault()
          return false

      $('input[readonly]').focus -> this.blur()

      @_initParent()
      @_sendStatusToParent()
      $.ajaxSetup(
        data: { site_id: @config('site') },
        global: true,
        beforeSend: (xhr) ->
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) )
      $(document).ajaxComplete (event,xhr) ->
        data = $.parseJSON(xhr.responseText) if xhr.responseText?.charAt(0) is '{'
        window.location.href = data.redirect_to if (data?.redirect_to?)
        parentRedirect(data.redirect_parent_to) if data?.redirect_parent_to

    $(window).on 'page:restore', => @autoResizeParent()
    $(window).resize @autoResizeParent
    $(document).on 'page:load', => @autoResizeParent()
    $(document).ready => @autoResizeParent()

  _initParent: ->
    if @parentDocument()?.getElementById('loading')
      @parentDocument()?.getElementById('loading').style.visibility = 'hidden'

  _sendStatusToParent: ->
    status = {
      group: @config('current-groupname'),
      loggedIn: @config('logged-in'),
      username: @config('current-username')
    }
    window.parent.window.postMessage(status, '*')

  autoResizeParent: =>
    return if @config('no-auto-resize')
    @tellParent('set_iframe_height', "#{@myHeight()}px")

  config: (key) -> $('body').data(key)

  devMode: -> @config("env") is 'development'

  ensureSSL: ->
    if !@devMode() && window.location.protocol != "https:"
      window.location.href = "https:" + window.location.href.substring(window.location.protocol.length)

  isOnScreen: (element) => element.getBoundingClientRect().top >= 0

  myHeight: -> myHeight()

  parent: ->
    try
      return parent if window.parent.document
    catch
      null

  parentDocument: -> 
    try 
      return window.parent.document 
    catch
      null

  parentGoto: (url) -> @parent()?.goto url

  parentGotoInNewWindow: (url) -> @parent()?.gotoInNewWindow "/#{url}"

  parentScrollToTheTop: ->
    widget = $('body[data-controller="profile"]').length
    if !widget
      @tellParent('scroll_to_top_of_frame')

  # Tells the containing frame (if there is one) to scroll to the given element.
  # $elem - jQuery element
  # topOffset - optional extra amount to scroll down (in pixels)
  parentScrollToElement: ($elem, topOffset = 0) ->
    parentScrollToElement($elem, topOffset)

  scrollTo: (vertical_pos) ->
    $("html, body").animate scrollTop: vertical_pos, 1000

  scrollToTheBottom: ->
    $("html, body").animate scrollTop: $(document).height(), 1000

  scrollToTheTop: ->
    return if @config('no-auto-resize')
    $("html, body").animate scrollTop: 0, "slow"
    @parentScrollToTheTop()

  sendPageLoadedMessage: -> @tellParent('pageLoaded', true)

  # An adjustment that will be applied to any auto-reizing functions.
  setHeightAdjustment: (adjustment) -> @_heightAdjust = adjustment

  showError: (error) =>
    $(@_noticeBoxId)
      .removeAttr('class').addClass('alert alert-danger')
      .html(error)
      .fadeIn()

  showLoader: (element, options) =>
    className = "loader"
    className += " centered" if options['centered']
    loaderElem = $("<span class='#{className}'/>")
    placementMethod = options['placement'] || 'after'
    element[placementMethod](loaderElem)

  supportsLocalStorage: ->
      try
        return window['localStorage']?
      catch
        return false

  tellParent: (key, value) ->
    return unless parent?.window?.postMessage
    window.parent.window.postMessage("#{key}=#{value}", '*')

  url: (urlName, paramString) ->
    url = @config('urls')[urlName] #|| throw("No URL found for #{urlName}")
    if paramString
      delimiter = if (url.indexOf('?') >= 0) then '&' else '?'
      return "#{url}#{delimiter}#{paramString}"
    url
