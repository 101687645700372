import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './direction_selector.scss'

class DirectionSelector extends React.Component
  constructor: (props) -> super(props)

  componentDidMount: -> 
    if @props.value != 'A' && @props.value != 'D'
      @props.onChange('A')

  onChange: (e) => @props.onChange(e.target.value)

  render: ->
    <div className="direction-selector">
      <div className="input-group">
        <select value={@props.value || ''} className="form-control" onChange={@onChange}>
          <option value="A">{@props.content.arrivalName}</option>
          <option value="D">{@props.content.departureName}</option>
        </select>
      </div>
    </div>

DirectionSelector.propTypes = {
  content: PropTypes.shape({
    arrivalName: PropTypes.string.isRequired,
    departureName: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(['A', 'D'])
}

# --- Connect to Redux
mapStateToProps = (state) -> content: state.content.direction
export default connect(mapStateToProps)(DirectionSelector)
