import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'antd/lib/icon'
import Switch from 'antd/lib/switch'
import 'antd/lib/switch/style/css'
import 'antd/lib/icon/style/css'
import { graphql } from '@apollo/react-hoc';
import gql from "graphql-tag";

import './roundtrip_hourly_toggle.scss'

class RoundtripHourlyToggle extends React.Component
  constructor: (props) -> super(props)

  componentDidMount: ->
    # Activate any tooltips
    if $('[title]').length
      $('[title]').tooltip()

  componentDidUpdate: (prevProps, prevState) ->
    # GraphQL data finished loading.
    if prevProps.apolloLoading && !@props.apolloLoading
      if @onlyOnewayEnabled()
        @toggle('oneway')
      else if @onlyHourlyEnabled()
        @toggle('hourly')
      else if @onlyRoundtripEnabled()
        @toggle('roundtrip')

  toggle: (mode) => @props.onChange(mode)

  hourlyEnabled: => @props.options.hourlyEnabled

  onewayEnabled: => true

  onlyHourlyEnabled: => !@roundtripEnabled() && !@onewayEnabled()

  onlyOnewayEnabled: => !@roundtripEnabled() && !@hourlyEnabled()

  onlyRoundtripEnabled: => !@hourlyEnabled() && !@onewayEnabled()

  onlyRoundtripAndOnewayEnabled: => 
    @roundtripEnabled() && @onewayEnabled() && !@hourlyEnabled()

  roundtripEnabled: => @props.options.roundtripEnabled

  renderHourly: ->
    return null if !@hourlyEnabled() || @props.hourlyDisabled

    <label className="radio-inline">
      <input type="radio" name="rt_toggle" value='hourly'
             defaultChecked={@props.value == 'hourly'}
             onChange={=> @toggle('hourly')}/>
      <span dangerouslySetInnerHTML={{__html: @props.content.rtToggleHourlyLabel}}/>
    </label>

  renderLabel: ->
    if @hourlyEnabled()
      <label className="blank-label">&nbsp;</label>
    else
      <label dangerouslySetInnerHTML={{__html: @props.content.rtToggleNonHourlyLabel}}/>

  renderOneway: ->
    return null unless @onewayEnabled()

    if @hourlyEnabled()
      label = @props.content.rtToggleOnewayLabel
    else
      label = @props.content.rtToggleDisableRtLabel

    <label className="radio-inline">
      <input type="radio" name="rt_toggle" value='oneway'
             defaultChecked={@props.value == 'oneway'}
             onChange={=> @toggle('oneway')}/>
      <span dangerouslySetInnerHTML={{__html: label}}/>
    </label>

  renderRoundtrip: ->
    return null unless @roundtripEnabled()

    if @hourlyEnabled()
      label = @props.content.rtToggleRoundtripLabel
    else
      label = @props.content.rtToggleEnableRtLabel

    <label className="radio-inline">
      <input type="radio" name="rt_toggle" value='roundtrip'
             defaultChecked={@props.value == 'roundtrip'}
             onChange={=> @toggle('roundtrip')}/>
      <span dangerouslySetInnerHTML={{__html: label}}/>
    </label>

  renderRoundtripToggle: ->
    style = ".ant-switch-checked { \
            background-color: #{@props.colors?.buttonBackground}; \
            color: #{@props.colors?.buttonColor}; \
          } \
    "

    <div>
      <style>{style}</style>
      <Switch checked={@props.value == 'roundtrip'}
              checkedChildren={<Icon type="check" />} 
              defaultChecked={@props.value == 'roundtrip'}
              size="large"
              onChange={
                (checked) =>
                  if checked
                    @toggle('roundtrip')
                  else
                    @toggle('oneway')
              }                            
              unCheckedChildren={<Icon type="close" />}/>
    </div>

  render: ->
    if @onlyOnewayEnabled()
      <input type="hidden" name="rt_toggle" value='oneway' defaultChecked={true}/>
    else if @onlyHourlyEnabled()
      <input type="hidden" name="rt_toggle" value='hourly' defaultChecked={true}/>
    else if @onlyRoundtripEnabled()
      <input type="hidden" name="rt_toggle" value='roundtrip' defaultChecked={true}/>
    else if @onlyRoundtripAndOnewayEnabled()
      <div className="roundtrip-hourly-toggle">
        {@renderLabel()}
        <div className="toggles">{@renderRoundtripToggle()}</div>
      </div>
    else
      <div className="roundtrip-hourly-toggle three-options">
        {@renderLabel()}
        <div className="toggles">
          {@renderRoundtrip()}
          {@renderOneway()}
          {@renderHourly()}
        </div>
      </div>

RoundtripHourlyToggle.propTypes = {
  hourlyDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(['oneway', 'multi', 'roundtrip', 'hourly'])
}

# --- Apollo/GraphQL
export default graphql(gql("
  query roundtripToggleQuery {
    colors {
      buttonBackground
      buttonColor
      id
    }
    config {
      id
      hourlyEnabled
      roundtripEnabled
    }    
    content {
      id
      rtToggleDisableRtLabel
      rtToggleEnableRtLabel
      rtToggleHourlyLabel
      rtToggleNonHourlyLabel
      rtToggleOnewayLabel
      rtToggleRoundtripLabel
    }    
  }"),  
  {
    props: (apollo) => 
      # Allow incoming props to override 
      apolloConfig = (apollo.data.config || {})
      propsConfig = (apollo.ownProps.options || {})

      return {
        apolloLoading: apollo.data.loading,
        colors: apollo.data.colors || {},
        options: {...apolloConfig, ...propsConfig },
        content: apollo.data.content || {}
      }
  }
)(RoundtripHourlyToggle)