import React from 'react';
import PropTypes from 'prop-types';

import AutoCompleteSelector from './selectors/auto_complete'
import ListSelector from './selectors/list'
import TypeSelector from './selectors/type'

export default class LocationSelector extends React.Component
  constructor: (props) ->
    super(props)
    @state = { locationType: null }

  componentDidMount: ->
    @props.onLocationTypesRequested() unless @props.locationsByType.length

    # If we only have one loc type, default it.
    if @props.locationsByType.length == 1
      @typeSelected(@props.locationsByType[0])

    # Because we display slightly differently on mobile vs non-mobile we
    # want to catch someone resizing their browser and rerender once the
    # resizing is done.
    $(window).on 'resize', @forceUpdateWithTimer

  componentWillUnmount: -> 
    clearTimeout(@_resizeTimer)
    $(window).off 'resize', @forceUpdateWithTimer

  componentDidUpdate: -> @props.onViewportChange()

  forceUpdateWithTimer: =>
    clearTimeout(@_resizeTimer)
    @_resizeTimer = setTimeout((=> @forceUpdate()), 250)

  clearLocationType: =>
    @setState locationType: ''
    @currentSelector()?.down()
    @props.onTypeChange()

  currentDisplayMode: => @currentLocationType()?.displayMode

  currentLocationType: =>
    return unless @state.locationType
    (t for t in @props.locationsByType when t.type == @state.locationType)[0]

  currentSelector: =>
    if !@state.locationType
      @refs.typeSelector
    else if @currentDisplayMode() == 'list'
      @refs.locSelector
    else
      @refs.acSelector

  isMobile: => window.matchMedia('screen and (max-width: 767px)').matches

  locations: =>
    if !@props.requestedLocations? ||
       @props.requestedLocations.locType != @state.locationType
      return {}

    @props.requestedLocations

  locationSelected: (loc) => @props.onSelect(loc)

  requestLocations: (query) =>
    @props.onLocationsRequested(@state.locationType, query)

  down: => @currentSelector()?.down?()

  left: => @currentSelector()?.left?()

  select: => @currentSelector()?.select?()

  typeSelected: (type) =>
    @props.onTypeChange(type)
    @setState locationType: type.type

  up: => @currentSelector()?.up?()

  renderLocationSelector: ->
    return '' if !@state.locationType && !@isMobile()
    locType = @currentLocationType()
    back = @clearLocationType
    mobile = window.matchMedia('screen and (max-width: 767px)').matches

    if !locType
      @renderTypeSelector()
    else if @currentDisplayMode() == 'list'
      <ListSelector ref="locSelector"
        key={locType.type}
        content={{ header: locType?.description }}
        locations={locType?.locations}
        onBack={if mobile then back else null}
        onSelect={@locationSelected}
        uniqueId={locType?.uniqueId || 'code'}/>
    else
      <AutoCompleteSelector ref="acSelector"
        content={{
                  fetchingMessage: @props.content.fetchingMessage,
                  header: locType?.description,
                  noLocationsFound: @props.content.noLocationsFound,
                  placeholder: @props.placeholder
                  }}
        initialLocations={locType?.locations}
        key={@state.locationType}
        onBack={back}
        onBlur={@props.onBlur}
        onLocationsRequested={@requestLocations}
        onSelect={@locationSelected}
        requestedLocations={@locations()}
      />

  renderTypeSelector: ->
    <TypeSelector ref="typeSelector"
      content={@props.content.type}
      loading={@props.loading}
      onBack={@props.onBack}
      onSelect={@typeSelected}
      types={@props.locationsByType}
      value={@state.locationType}/>

  render: ->
    if !@isMobile() # Show an "always-on" type selector for non-mobile.
      typeSelector = <div className="col-sm-6">{@renderTypeSelector()}</div>

    if @isMobile()
      @renderLocationSelector()
    else
      <div className={"row #{@props.className}"}>
        {typeSelector}
        <div className="col-sm-6">{@renderLocationSelector()}</div>
      </div>

LocationSelector.defaultProps = {
  locations: [],
  locationsByType: []
}

LocationSelector.propTypes = {
  content: PropTypes.shape({
    noLocationsFound: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.object
  }),
  loading: PropTypes.bool,
  requestedLocations: PropTypes.shape({
    query: PropTypes.string,
    locType: PropTypes.string, # The location type associated with these locs.
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.string,
        city: PropTypes.string,
        code: PropTypes.string,
        mapPoint: PropTypes.string,
        name: PropTypes.string,
        state: PropTypes.string,
        type: PropTypes.string,
        zip: PropTypes.string,
  }))}),
  locationsByType: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string, # Location type name
      displayMode: PropTypes.oneOf(['google', 'list', 'auto', 'search',
                                          'both']),
      icon: PropTypes.string,
      type: PropTypes.string,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          address: PropTypes.string,
          city: PropTypes.string,
          code: PropTypes.string,
          mapPoint: PropTypes.string,
          name: PropTypes.string,
          state: PropTypes.string,
          type: PropTypes.string,
          zip: PropTypes.string,
      }))
    })
  ),
  onBack: PropTypes.func,
  onBlur: PropTypes.func,
  onLocationTypesRequested: PropTypes.func.isRequired,
  onLocationsRequested: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func,
  onViewportChange: PropTypes.func,
  value: PropTypes.object # location object
}
