import React from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faDotCircle from '@fortawesome/fontawesome-free-regular/faDotCircle'

import { directions } from '../utils/directions'
import './google_map.scss'

export default class GoogleMap extends React.Component
  constructor: (props) -> super(props)

  RecenterControl: (controlDiv, map, centerFunction) ->
    # Set CSS for the control border.
    controlUI = document.createElement('div')
    controlUI.style.backgroundColor = '#fff'
    controlUI.style.border = '2px solid #fff'
    controlUI.style.borderRadius = '3px'
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
    controlUI.style.cursor = 'pointer'
    controlUI.style.marginTop = '5px'
    controlUI.style.textAlign = 'center'
    controlUI.style.display = 'none'
    controlUI.id = "google-map-recenter-control"
    controlDiv.appendChild(controlUI)

    # Set CSS for the control interior.
    controlText = document.createElement('div')
    controlText.style.color = '#555'
    controlText.style.fontSize = '16px'
    controlText.style.paddingLeft = '10px'
    controlText.style.paddingRight = '10px'
    controlUI.appendChild(controlText)
    ReactDOM.render(<FontAwesomeIcon icon={faDotCircle}/>, controlText)

    # Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener('click', centerFunction)

  addRecenterButtonIfNeeded: =>
    if @_map.getBounds()
      pickupShown = !@_pickup || @_map.getBounds().contains(@_pickup.getPosition())
      dropoffShown = !@_dropoff || @_map.getBounds().contains(@_dropoff.getPosition())
      $('#google-map-recenter-control').toggle(!pickupShown || !dropoffShown)

  centerMap: =>
    if @props.pickup && @props.dropoff
      @centerRoute()
    else if @props.pickup
      @_map.setZoom(15);
      @centerMarker(@markerPoint(@props.pickup))
    else if @props.dropoff
      @_map.setZoom(15);
      @centerMarker(@markerPoint(@props.dropoff))
    else if @props.defaultCenter
      @_map.setZoom(12);
      @centerMarker(@markerPoint(@props.defaultCenter))
    else
      # Center on US
      @_map.setCenter(new google.maps.LatLng(39.8097343, -98.5556199));
      @_map.setZoom(4);

  centerMarker: (point) => @_map.setCenter(point)

  centerRoute: =>
    dirs = @_directionsDisplay.getDirections()
    return unless dirs
    bounds = dirs.routes[0].bounds
    @_map.fitBounds(bounds)
    @_map.setCenter(bounds.getCenter())

  markerPoint: (point) =>
    return unless point

    if ((typeof point) == 'string')
      pointArr = [point.split(',')[0], point.split(',')[1]]
    else
      pointArr = point
    { lat: Number(pointArr[0]), lng: Number(pointArr[1]) }

  updateDirections: =>
    @_directionsDisplay.setMap(null) if @_directionsDisplay
    return unless @props.pickup && @props.dropoff
    directions(@markerPoint(@props.pickup), @markerPoint(@props.dropoff), true)
      .then (response) =>
        @_directionsDisplay.setDirections(response)
        @_directionsDisplay.setMap(@_map)

  updateMarker: (marker, point) =>
    if !point
      if marker
        marker.setMap(null)
        return marker
      else
        return

    if marker
      marker.setMap(@_map)
      marker.setPosition(@markerPoint(point))
      markerRef = marker
    else
      markerRef = new google.maps.Marker({position: @markerPoint(point), map: @_map})

    return markerRef

  updateDropoff: =>
    newDrop = @updateMarker(@_dropoff, @props.dropoff)
    @_dropoff = newDrop

  updatePickup: =>
    newPick = @updateMarker(@_pickup, @props.pickup)
    @_pickup = newPick

  componentDidMount: ->
    mapOptions = {
      zoom: 13,
      zoomControl: true,
      draggable: true,
      scrollwheel: false,
      panControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    }
    mapOptions.center = @markerPoint() if @markerPoint()
    @_map = new google.maps.Map(@refs.map, mapOptions)
    @_directionsDisplay = new google.maps.DirectionsRenderer(map: @_map)

    # Remove Google Map's links from the tab order.
    listener = @_map.addListener('tilesloaded', =>
      $(@refs.map).find('a').attr('tabindex', -1)
      google.maps.event.removeListener(listener)
    )

    centerControlDiv = document.createElement('div')
    centerControl = new @RecenterControl(centerControlDiv, @_map, @centerMap)
    centerControlDiv.index = 1
    @_map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv)
    @_map.addListener('bounds_changed', @addRecenterButtonIfNeeded)

    $(window).on('resize', @centerMap)

    @updateDropoff()
    @updatePickup()
    @updateDirections()
    @centerMap()

  componentDidUpdate: (prevProps, prevState) ->
    pickupChanged = prevProps.pickup != @props.pickup
    dropoffChanged = prevProps.dropoff != @props.dropoff
    @updatePickup() if pickupChanged
    @updateDropoff() if dropoffChanged

    if prevProps.defaultCenter != @props.defaultCenter
      @centerMap()

    if pickupChanged || dropoffChanged
      @updateDirections()
      @centerMap()

  render: ->
    <div className="google-map" ref="map"></div>

GoogleMap.propTypes = {
  dropoff: PropTypes.string,
  pickup: PropTypes.string
}
