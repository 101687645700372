# Returns an object with location types as keys and the loc type config as value.
typesByType = (typesArray) ->
  obj = {}
  obj[type.type] = type for type in typesArray
  obj

export clearLocationTypes = -> { type: 'CLEAR_LOC_TYPE_REQUEST' }

export fetchLocationTypes = (resIndex, byAirport = false, airport) ->
  res = window.reduxStore.getState().reservations.legs[resIndex]
  return { type: 'NOOP' } unless res.providerSiteId

  if (!airport) 
    airport = res.airport
  return { type: 'NOOP' } if byAirport && !airport

  if byAirport
    ltKey = "#{res.providerSiteId}.#{airport}.#{res.serviceArea}"
  else
    ltKey = "#{res.providerSiteId}.unx.#{res.serviceArea}"

  if window.reduxStore.getState().locationTypes[ltKey]?.fetching
    return { type: 'NOOP' }

  getOptions = {
    macro: res.macro,
    provider_site: res.providerSiteId,
    service_area: res.serviceArea
  }
  getOptions.airport = airport if byAirport

  {
    index: resIndex,
    promise: $.getJSON(window.urls.locationTypes.load, getOptions),
    airport: getOptions.airport,
    ltKey: ltKey,
    siteId: res.providerSiteId,
    types: ['LOAD_LOC_TYPE_REQUEST',
            'LOAD_LOC_TYPE_SUCCESS',
            'LOAD_LOC_TYPE_FAILURE']
  }

export locationTypesReducer = (state = {}, action) ->
  switch action.type
    when 'CLEAR_LOC_TYPE_REQUEST'
      { mainSearchMode: null, types: [], typesByType: {}, fetching: false }

    when 'LOAD_LOC_TYPE_REQUEST'
      newState = $.extend(true, {}, state)
      newState[action.ltKey] ||= { mainSearchMode: null, types: [], typesByType: {} }
      newState[action.ltKey].fetching = true
      newState
    when 'LOAD_LOC_TYPE_SUCCESS'
      newState = $.extend(true, {}, state)
      newState[action.ltKey] = {
        mainSearchMode: action.mainSearchMode,
        types: action.types,
        typesByType: action.typesByType,
        fetching: false
      }
      newState
    when 'LOAD_LOC_TYPE_FAILURE'
      console.error "Unable to load location types for #{action.siteId}"
      newState = $.extend(true, {}, state)
      newState[action.ltKey] = { mainSearchMode: null, types: [], typesByType: {}, fetching: false }
      newState
    else state
