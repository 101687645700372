import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SupplementaryInfo from "../supplementary_info";
import FlightLookup from "./flight_lookup";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  div.date-bootstrap {
    max-width: 170px;
  }

  @media (max-width: 767px) {
    padding-left: 0;

    .supplementary-info.flight-time-container {
      flex-direction: column;
    }
  }
`;

const Header = styled.p`
  max-width: 600px;

  @media (max-width: 767px) {
    margin-bottom: 0;
    margin-top: 20px;
  }
`;

function FlightLookupContainer(props) {
  let header;
  if (props.content.flight.lookupHeader)
    header = <Header>{props.content.flight.lookupHeader}</Header>;

  const showReturnFlight =
    props.fields.mode == "roundtrip" && !props.options.onewayOnly;

  function renderFlight() {
    const lookupProps = {
      content: props.content.flight,
      dateDefaults: {
        daysOut: props.options.defaultPickupDaysOut,
        defaultToToday: props.options.defaultPickupToToday
      },
      dropoff: props.fields.dropoff,
      errors: props.errors,
      fields: props.fields,
      onChange: changes => props.onChange(changes),
      onViewportChange: props.onViewportChange,
      options: props.options,
      pickup: props.fields.pickup
    };

    return <FlightLookup {...lookupProps} />;
  }

  function renderReturnFlight() {
    const lookupProps = {
      content: { ...props.content.flight, ...props.content.returnFlight },
      dropoff: props.fields.pickup,
      errors: props.returnErrors,
      fields: props.returnFields || {},
      onChange: changes => props.onReturnChange(changes),
      onViewportChange: props.onViewportChange,
      options: props.options,
      pickup: props.fields.dropoff
    };

    return <FlightLookup {...lookupProps} />;
  }

  return (
    <Container>
      {header}

      <SupplementaryInfo className="flight-time-container">
        {renderFlight()}
        {showReturnFlight ? renderReturnFlight() : null}
      </SupplementaryInfo>
    </Container>
  );
}

FlightLookupContainer.propTypes = {
  content: PropTypes.shape({
    flight: PropTypes.shape({
      lookupHeader: PropTypes.string
    }),
    returnFlight: PropTypes.object
  }),
  errors: PropTypes.object,
  fields: PropTypes.shape({
    dropoff: PropTypes.object,
    mode: PropTypes.string,
    pickup: PropTypes.object
  }),
  onChange: PropTypes.func.isRequired,
  onReturnChange: PropTypes.func,
  onViewportChange: PropTypes.func,
  options: PropTypes.shape({
    defaultPickupDaysOut: PropTypes.number,
    defaultPickupToToday: PropTypes.bool,
    onewayOnly: PropTypes.bool
  }),
  returnErrors: PropTypes.object,
  returnFields: PropTypes.object
};

export default FlightLookupContainer;
