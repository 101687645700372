import * as moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import './date_time.scss'
import BootstrapDate from './internal/bootstrap_date'
import { isMobile } from '../../utils/utils'

export default class DateTime extends React.Component
  constructor: (props) ->
    super(props)
    @state = { dateField: 'html5' }

  componentDidMount: ->
    @setState dateField: 'bootstrap' if !@isDateInputSupported()
    # Without this the date field may not show it's value if it's hidden and
    # re-shown (like if a flight date is replaced with pickup date in MA)
    @forceUpdate()
    window.addEventListener('resize', @forceUpdateWithState)

  componentWillUnmount: -> 
    window.removeEventListener('resize', @forceUpdateWithState)

  forceUpdateWithState: => @forceUpdate()

  isDateInputSupported: =>
    try
      test = document.createElement('input');
      test.type = 'date';
      return test.type != 'text'
    catch e
      unless e.message == "Invalid argument."
        console.error e
      return false

  onDateChange: (dateStr) =>
    if !dateStr
      @props.onDateChange?( date: '', year: '', month: '', day: '')
      return

    [year, month, day] = dateStr.split('-')

    # Convert date to the right format.
    dateString = @props.format.date
      .toUpperCase()
      .replace(/YYYY/, year)
      .replace(/YY/, year.slice(-2))
      .replace(/M(M)?/, month)
      .replace(/D(D)?/, day)

    @props.onDateChange?(
      date: dateString,
      year: year,
      month: month,
      day: day)

  uniqueId: =>
    @_uniqueId ||= Math.random().toString(36).substring(7)

  renderError: (field) ->
    return '' unless @props.errors?[field]
    <label className="error-label"
           dangerouslySetInnerHTML={{__html: @props.errors[field]}}/>

  renderHourSelect: ->
    hrProps = {
      className: 'form-control time',
      style: { width: 'auto', display: 'inline' }
      name: @props.fieldNames?.timeHour,
      onChange: ((e) => @props.onHourChange?(e.target.value))
    }
    hrProps.className += ' has-error' if @props.errors?.time

    if @props.value?.hour
      setValue = @props.value.hour

    else if @props.options?.defaultDaysOut
      date = moment()
      date.add(@props.options.defaultDaysOut, 'days')
      setValue = date.hour() if date
      @props.onHourChange?(setValue)

    else if @props.options?.defaultToToday
      setValue = new Date().getHours()
      @props.onHourChange?(setValue)

    hrs = ['', '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
           '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21',
           '22', '23']
    if @props.content?.twelveHrTimeLabels
      labels = @props.content.twelveHrTimeLabels
    else
      labels = ['', "12 AM", "01 AM", "02 AM", "03 AM", "04 AM", "05 AM",
                "06 AM", "07 AM", "08 AM", "09 AM", "10 AM", "11 AM", "12 PM",
                "01 PM", "02 PM", "03 PM", "04 PM", "05 PM", "06 PM", "07 PM",
                "08 PM", "09 PM", "10 PM", "11 PM"]
    if setValue
      value = if Number(setValue) < 10 then  '0' else ''
      value += String(Number(setValue))

    if @props.controlled
      hrProps.value = value
    else
      hrProps.defaultValue = value


    if @props.options.hidden && @props.fieldNames?.timeHour
      <input type="hidden" name={@props.fieldNames?.timeHour} value={value}/>
    else if !@props.options.hidden
      <select {...hrProps}>
        {
          for hour, i in hrs
            <option value={hour} key="hr#{hour}">
              {if @props.format.time == '12' then labels[i] else hour}
            </option>
        }
      </select>

  renderMinuteSelect: ->
    minProps = {
      className: 'form-control time',
      style: { width: 'auto', display: 'inline' }
      name: @props.fieldNames?.timeMin,
      onChange: ((e) => @props.onMinuteChange?(e.target.value))
    }
    minProps.className += ' has-error' if @props.errors?.time

    if @props.value?.minute
      setValue = @props.value.minute

    else if @props.options?.defaultDaysOut
      date = moment()
      date.add(@props.options.defaultDaysOut, 'days')
      setValue = date.minute()if date
      setValue = Math.ceil( setValue / 5 ) * 5
      setValue = '00' if setValue == 60
      @props.onMinuteChange?(setValue)

    else if @props.options?.defaultToToday
      setValue = new Date().getMinutes()
      setValue = Math.ceil( setValue / 5 ) * 5
      setValue = '00' if setValue == 60
      @props.onMinuteChange?(setValue)

    mins = Array.from({ length: 60 }, (x, i) => String(i).padStart(2, '0'))
    interval = @props.options.minuteInterval || 5
    mins = mins.filter((value) => ((value % interval) == 0) )
    mins.unshift('')
    
    if setValue
      value = if Number(setValue) < 10 then  '0' else ''
      value += String(Number(setValue))

    # Add minute value to our array if it's not in there.
    if value && mins.indexOf(value) == -1
      mins.push(value)
      mins.sort()

    if @props.controlled
      minProps.value = value
    else
      minProps.defaultValue = value

    if @props.options.hidden && @props.fieldNames?.timeMin
      <input type="hidden" name={@props.fieldNames?.timeMin} value={value}/>
    else if !@props.options.hidden
      <select {...minProps}>
        {
          for min in mins
            <option value={min} key="#{@uniqueId()}m#{min}">{min}</option>
        }
      </select>

  renderDate: ->
    if @state.dateField == 'html5'
      min = new Date().toJSON().split('T')[0]
      className = @props.classNames?.date || ''
      className = 'has-error' if @props.errors?.date
      if @props.value?.date?.month
        valMonth = @props.value.month
        if Number(@props.value.month) < 10
          valMonth = "0#{Number(@props.value.month)}"
        valDay = @props.value.day
        if Number(@props.value.day) < 10
          valDay = "0#{Number(@props.value.day)}"
        value = "#{@props.value.year}-#{valMonth}-#{valDay}"
      else if @props.value?.date
        date = moment(@props.value.date, @props.format?.date.toUpperCase())
        value = date.format("YYYY-MM-DD") if date

      else if @props.options?.defaultDaysOut
        date = moment()
        date.add(@props.options.defaultDaysOut, 'days')
        if date
          value = date.format("YYYY-MM-DD")
          @onDateChange(value)

      else if @props.options?.defaultToToday
        date = moment()
        if date
          value = date.format("YYYY-MM-DD")
          @onDateChange(value)

      dateProps = {
        type: 'date', ref: 'html5date',
        onChange: (e) => @onDateChange(e.target.value),
        className: className,
        ref: (ref) => if ref then ref.defaultValue = ""
      }
      if @props.controlled
        dateProps.value = value || ''
      else
        dateProps.defaultValue = value

      hiddenField = <input type="hidden" name={@props.fieldNames?.date} value={@props.value?.date || ''}/>

      <div>
        {
          unless @props.options.hidden
            <input {...dateProps}/>
        }
        
        {hiddenField}
      </div>
    else
      if @props.options.hidden
        <input type="hidden" name={@props.fieldNames?.date} value={@props.value?.date || ''}/>
      else
        <BootstrapDate
          controlled={@props.controlled} error={@props.errors?.date}
          fieldName={@props.fieldNames?.date} format={@props.format?.date}
          onChange={@props.onDateChange} onViewportChange={@props.onViewportChange}
          options={@props.options} value={@props.value?.date || value}/>

  renderTime: ->
    return null if @props.options.hideTime

    unless @props.options.noLabel
      if @props.content?.timeLabel.trim()
        label = <label dangerouslySetInnerHTML={{__html: @props.content?.timeLabel}}/>
      else
        label = <label>&nbsp;</label>

    <div className="time"
         style={{ marginTop: '15px' }}>
      {label}
      <div className="form-group form-inline">
        {@renderHourSelect()}
        :
        {@renderMinuteSelect()}
      </div>
      {@renderError('time')}
    </div>

  render: ->
    dateClass = "date date-#{@state.dateField}"


    if @props.options.hidden
      <div>
        {@renderDate()}
        {@renderHourSelect()}
        {@renderMinuteSelect()}
      </div>

    else
      <div className="date-time-selector" ref="me"
          style={{ display: 'flex', flexWrap: 'wrap', marginTop: '-15px' }} >
        <div className={dateClass}
            style={{ marginRight: '15px', marginTop: '15px' }}>
          <label className="date-label" 
                 dangerouslySetInnerHTML={{__html: @props.content?.dateLabel}}/>
          {@renderDate()}
          {@renderError('date')}
        </div>

        {@renderTime()}
      </div>

DateTime.defaultProps = {
  format: { date: 'mm/dd/yyyy', time: '12' }
}

DateTime.propTypes = {
  classNames: PropTypes.shape({
    date: PropTypes.string
  }),
  content: PropTypes.shape({
    twelveHrTimeLabels: PropTypes.array,
    dateLabel: PropTypes.string,
    timeLabel: PropTypes.string
  }),
  controlled: PropTypes.bool,
  errors: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.string
  }),
  fieldNames: PropTypes.shape({
    date: PropTypes.string,
    timeHour: PropTypes.string,
    timeMin: PropTypes.string
  }),
  format: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.oneOf(['12', '24'])
  }),
  onDateChange: PropTypes.func,
  onHourChange: PropTypes.func,
  onMinuteChange: PropTypes.func,
  onViewportChange: PropTypes.func,
  options: PropTypes.shape({
    defaultDaysOut: PropTypes.number,
    defaultToToday: PropTypes.bool,
    hideTime: PropTypes.bool,
    language: PropTypes.string,
    minuteInterval: PropTypes.number
  }),
  value: PropTypes.shape({
    date: PropTypes.string,
    hour: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    minute: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  })
}
