import React from 'react';
import PropTypes from 'prop-types';

import './list.scss'
import Item from '../../selector_item'
import List from '../../selector_list'

export default class ListSelector extends React.Component
  constructor: (props) -> super(props)

  down: => @refs.list.down()

  left: => @props.onBack?()

  select: => @refs.list.select()

  up: => @refs.list.up()

  render: ->
    locs = @props.locations.map (loc) =>
      if loc.address
        address = <div><span className='loc-address'>{loc.address}</span></div>
      if loc.city || loc.state
        cityState = <div><span className='loc-address'>{loc.city}, {loc.state}</span></div>

      highlightName = loc.display_name || loc.address || "#{loc.city}, #{loc.state}"
      <Item key={loc[@props.uniqueId] || loc.code}
                    name={highlightName}
                    onClick={=> @props.onSelect(loc)}>
        {loc.display_name}{address}{cityState}
      </Item>

    <div className="list-selector">
      <div className="list-label hudson-subsection">
        {@props.content.header.replace(/(<([^>]+)>)/ig, " ")}
      </div>
      <List onBack={@props.onBack} ref="list" className="optimized">
        {locs}
      </List>
    </div>

ListSelector.propTypes = {
  content: PropTypes.shape({
    header: PropTypes.string
  }),
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  onBack: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  uniqueId: PropTypes.string
}
