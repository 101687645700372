# Generates a font awesome icon component for the given location type.

import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faBed from '@fortawesome/fontawesome-free-solid/faBed'
import faBuilding from '@fortawesome/fontawesome-free-solid/faBuilding'
import faHome from '@fortawesome/fontawesome-free-solid/faHome'
import faPlane from '@fortawesome/fontawesome-free-solid/faPlane'
import faShip from '@fortawesome/fontawesome-free-solid/faShip'
import faSquare from '@fortawesome/fontawesome-free-solid/faSquare'
import faTrain from '@fortawesome/fontawesome-free-solid/faTrain'
import faUniversity from '@fortawesome/fontawesome-free-solid/faUniversity'
import faUserCircle from '@fortawesome/fontawesome-free-solid/faUserCircle'

export default class LocationTypeIcon extends React.Component
  icon: =>
    {
      'BUS': faSquare,
      'PUD': faUserCircle,
      'P': faBuilding,
      'S': faUniversity,
      'H': faBed,
      'A': faPlane,
      'C': faBuilding,
      'O': faSquare,
      'R': faTrain,
      'Y': faShip,
      'D': faHome,
      'G': faBuilding
    }[@props.type] || faBuilding

  render: -> <FontAwesomeIcon icon={@icon()} {...@props.iconProps}/>

LocationTypeIcon.defaultProps = {
  iconProps: {}
}

LocationTypeIcon.propTypes = {
  iconProps: PropTypes.object,
  type: PropTypes.string.isRequired
}
