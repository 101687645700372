import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faChevronDown from '@fortawesome/fontawesome-free-solid/faChevronDown'

import './macro_selector.scss'
import FakeField from '../fake_field'
import SelectorItem from '../selector_item'
import SelectorList from '../selector_list'
import SelectorPopup from '../selector_popup'

class MacroSelector extends React.Component
  constructor: (props) ->
    super(props)
    @state = {
      displayPopup: false,
      displayedValue: (@selectedMacro() || ''),
      noMacrosFound: false,
      previousSearchValue: ''
    }

  keyActions: { # Keyboard actions
    down: (->
      if @state.displayPopup then @currentSelector()?.down?() else @showPopup()),
    enter: (-> @currentSelector()?.select?()),
    esc: (-> @hidePopup()), tab: (-> @hidePopup()),
    left: (-> @currentSelector()?.left?()),
    right: (-> @currentSelector()?.select?()),
    tab: (-> @currentSelector()?.select?()),
    up: (-> @currentSelector()?.up?())
  }

  _knmTranslateCode: (code) =>
    {
      9: 'tab', 13: 'enter', 27: 'esc', 37: 'left', 38: 'up', 39: 'right',
      40: 'down'
    }[code]

  handleKeyDown: (e) =>
    keyCode = e.keyCode || e.which
    name = @_knmTranslateCode(keyCode)
    if @keyActions?[name]?
      @keyActions[name].call(this)
      e.preventDefault() unless name == 'tab'

  componentDidMount: -> @props.onViewportChange()

  componentDidUpdate: (prevProps, prevState) ->
    @props.onViewportChange()
    @setState displayedValue: @selectedMacro() if prevProps.value != @props.value

  clearSearch: =>
    @setState displayedValue: '', searchResults: null, noMacrosFound: false

  currentSelector: => @refs.selector

  hidePopup: =>
    @refocus() if window.matchMedia('screen and (max-width: 767px)').matches
    @clearSearch()
    @setState displayPopup: false, displayedValue: @selectedMacro()

  macroSelected: (macro) =>
    changes = $.extend({ macro: macro.id }, macro.values)
    @props.onChange?(changes)
    @setState displayedValue: macro.name
    @hidePopup()

  refocus: => @refs.field.focus?()

  selectedMacro: =>
    return null unless @props.value
    (macro.name for macro in @props.macros when Number(macro.id) is Number(@props.value))[0]

  showPopup: => @setState displayPopup: true, displayedValue: ''

  renderFakeField: ->
    fieldProps = {
      content: { 
        icon: <FontAwesomeIcon icon={faChevronDown}/>, 
        placeholder: @props.content.placeholder 
      },
      onClick: @showPopup, onFocus: @showPopup, onKeyDown: @handleKeyDown,
      ref: "field",
      value: @state.displayedValue || ''}
    <FakeField {...fieldProps}/>

  renderField: ->
    <div>
      {@renderFakeField()}
    </div>

  renderPopup: ->
    list = @props.macros.map (item) =>
        onClick = @macroSelected.bind(this, item)

        if item.subname
          subname = <div><span className='macro-subname'>{item.subname}</span></div>

        <SelectorItem key={item.id} onClick={onClick}>
          {item.name}{subname}
        </SelectorItem>

    popup = <SelectorList ref="selector">{list}</SelectorList>

    <SelectorPopup content={@props.content} onClose={@hidePopup}>
      {popup}
    </SelectorPopup>

  render: ->
    return null unless @props.macros?.length > 0
    <div className="macro-selector">
      <label dangerouslySetInnerHTML={{__html: @props.content.label}}/>
      {@renderField()}
      {if @state.displayPopup then @renderPopup()}
    </div>


MacroSelector.propTypes = {
    content: PropTypes.shape({
      label: PropTypes.string,
      placeholder: PropTypes.string
    }),
    value: PropTypes.number,
    macros: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      settings: PropTypes.object,
      subname: PropTypes.string,
      values: PropTypes.object
    })),
    onChange: PropTypes.func,
    onViewportChange: PropTypes.func
  }

# --- Connect to Redux
mapStateToProps = (state, ownProps) ->
  content: state.content.macroSelector,
  macros: state.macros

export default connect(mapStateToProps)(MacroSelector)
