# Adapted from https://github.com/davidjbradshaw/iframe-resizer/blob/master/js/iframeResizer.contentWindow.js
_capitalizeFirstLetter = (string) ->
  string.charAt(0).toUpperCase() + string.slice(1)

# Adapted from https://github.com/davidjbradshaw/iframe-resizer/blob/master/js/iframeResizer.contentWindow.js
_chkEventThottle = (timer) ->
  if timer > throttledTimer / 2
    throttledTimer = 2 * timer
    # console.log('Event throttle increased to ' + throttledTimer + 'ms')

# Adapted from https://github.com/davidjbradshaw/iframe-resizer/blob/master/js/iframeResizer.contentWindow.js
_getComputedStyle = (prop,el) ->
  el =  el || window.document.body
  retVal = window.document.defaultView.getComputedStyle(el, null)
  retVal = if (null != retVal) then retVal[prop] else 0
  parseInt(retVal, 10)

# Adapted from https://github.com/davidjbradshaw/iframe-resizer/blob/master/js/iframeResizer.contentWindow.js
_getMaxElement = (side, elements) ->
  elVal = 0
  maxVal = 0
  Side = _capitalizeFirstLetter(side)
  timer = Date.now()

  for element in elements
    elVal = element.getBoundingClientRect()[side] +
              _getComputedStyle('margin'+Side, element)
    if elVal > maxVal && element.style.position != 'fixed'
      maxVal = elVal
      maxElem = element
  timer = Date.now() - timer

  _chkEventThottle(timer)
  maxVal + 10

export addParameterToURL = (url, key, value) =>
  _url = url
  if _url.split('?')[1] 
    inter = '&'
  else
    inter = '?'
  "#{_url}#{inter}#{key}=#{value}"

export autoResizeParent = =>
  tellParent('set_iframe_height', "#{myHeight()}px")

export getUrlVars = =>
  vars = {}
  parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, ((m, key, value) =>
    vars[key] = value
  ))
  vars

inParentFrame = -> window.self != window.parent

export isMobile = (breakpointPx) -> 
  breakpointPx ||= 767
  window.matchMedia("screen and (max-width: #{breakpointPx}px)").matches

isOnScreen = (element) => element.getBoundingClientRect().top >= 0

# Adapted from https://github.com/davidjbradshaw/iframe-resizer/blob/master/js/iframeResizer.contentWindow.js
export myHeight = ->
  # Get the computed height of the main body element.
  bodyOffset = window.document.body.offsetHeight +
                _getComputedStyle('marginTop') + _getComputedStyle('marginBottom')

  # Elements that for one reason or another cause the page to resize incorrectly.
  exclusionList = ['option', '.selector-popup-back', '.infinite-scroll',
                   '.infinite-scroll *', 'li.active-result', 'optgroup'
                   '.auto-complete-selector', '.infinite-scroller', '.infinite-loading', '.fa-spin', 'li', 'div.value > div', 'div.value', '.loc-address', '.infinite-scroller *',
                   '.selector-list *', '.selector-popup', '.modal-backdrop', '.modal-dialog', '.modal', '.entry-page-login-modal', '.in', '.modal-sm'
                 ]
  elements = $('body *').not(exclusionList.join(', '))
  Math.max(bodyOffset,
           _getMaxElement('bottom', elements))

# Returns the containing element (if there is one).
parentDocument = -> 
  return null if !inParentFrame()
  try window.parent.document catch e

export parentRedirect = (url) -> 
  if inParentFrame()
    encode_url = url.replace(/=/g, '%3D')
    tellParent('page_redirect', encode_url)
  else
    window.location.href = url

# Tells the containing frame (if there is one) to scroll to the given element.
# $elem - jQuery element
# topOffset - optional extra amount to scroll down (in pixels)
export parentScrollToElement = ($elem, topOffset = 0) ->
  return unless $elem && $elem.length

  globalAdtlScroll = Number(window.globalAdtlScrollOffset)

  try
    parentFrameId =  window.frameElement?.getAttribute('frame_id')
    iframe = parentDocument()?.getElementById(parentFrameId)
  catch
    null

  return unless iframe

  widget = $('body[data-controller="profile"]').length
  unless widget && $elem.offset()
    spaceAboveIframe = $(iframe).offset().top
    elemTop = $elem.offset().top
    pointToScrollTo = spaceAboveIframe + elemTop - topOffset - globalAdtlScroll
    tellParent('scroll_to', pointToScrollTo)

export parentScrollToTheTop = (force) ->
  widget = $('body[data-controller="profile"]').length
  if force || !widget
    tellParent('scroll_to', 0)
  # tellParent('scroll_to_top_of_frame') unless widget

scrollToTheTop = ->
  $("html, body").animate scrollTop: 0, "slow"
  parentScrollToTheTop()

tellParent = (key, value) ->
  return unless inParentFrame()
  window.parent.window.postMessage("#{key}=#{value}", '*')
