import gql from "graphql-tag";

function updateCCField(field, value, cache) {
  const query = gql`
    query updateCCFieldQuery {
      paymentInfo {
        id
        creditCard {
          id
        }
      }
    }
  `;

  const newCC = {
    ...cache.readQuery({ query }).paymentInfo.creditCard,
    [field]: value,
    token: "", // If any CC fields were updated the token is invalid.
    typeCode: "",
  };
  const newPI = {
    ...cache.readQuery({ query }).paymentInfo,
    creditCard: newCC,
  };
  cache.writeQuery({ query, data: { paymentInfo: newPI } });
  return newPI;
}

function updateCCToken(token, type = "cc", cache) {
  const query = gql`
    query updateCCTokenQuery {
      paymentInfo {
        id
        creditCard {
          id
        }
      }
    }
  `;

  const newCC = {
    ...cache.readQuery({ query }).paymentInfo.creditCard,
    token: token,
    typeCode: "",
  };
  const newPI = {
    ...cache.readQuery({ query }).paymentInfo,
    ccTokenType: type,
    creditCard: newCC,
  };
  cache.writeQuery({ query, data: { paymentInfo: newPI } });
  return newPI;
}

function updatePaymentInfo(field, value, cache) {
  const query = gql`
    query updatePaymentInfoQuery {
      paymentInfo {
        id
      }
    }
  `;

  const newPI = { ...cache.readQuery({ query }).paymentInfo, [field]: value };
  cache.writeQuery({ query, data: { paymentInfo: newPI } });
  return newPI;
}

export const mutations = {
  updateCCExp: (_, { value }, { cache }) => {
    return updateCCField("expirationDate", value, cache);
  },
  updateCCName: (_, { value }, { cache }) => {
    return updateCCField("name", value, cache);
  },
  updateCCNumber: (_, { value }, { cache }) => {
    return updateCCField("number", value, cache);
  },
  updateCCVV: (_, { value }, { cache }) => {
    return updateCCField("ccvv", value, cache);
  },
  updateCCToken: (_, { token, type }, { cache }) => {
    return updateCCToken(token, type, cache);
  },
  updateCCZip: (_, { value }, { cache }) => {
    return updateCCField("postalCode", value, cache);
  },
  updateChargeTo: (_, { value }, { cache }) => {
    return updatePaymentInfo("chargeTo", value, cache);
  },
  updateExternalAmountPaid: (_, { value }, { cache }) => {
    return updatePaymentInfo("externalAmountPaid", value, cache);
  },
  updateExternalPaymentId: (_, { value }, { cache }) => {
    return updatePaymentInfo("externalPaymentId", value, cache);
  },
  updatePaymentType: (_, { value }, { cache }) => {
    return updatePaymentInfo("paymentType", value, cache);
  },
};
