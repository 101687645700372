import * as moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap-datepicker-webpack'
import 'bootstrap-datepicker-webpack/dist/css/bootstrap-datepicker3';
import 'bootstrap-datepicker-webpack/dist/locales/bootstrap-datepicker.de.min';
import 'bootstrap-datepicker-webpack/dist/locales/bootstrap-datepicker.en-AU.min';
import 'bootstrap-datepicker-webpack/dist/locales/bootstrap-datepicker.en-GB.min';
import 'bootstrap-datepicker-webpack/dist/locales/bootstrap-datepicker.en-IE.min';
import 'bootstrap-datepicker-webpack/dist/locales/bootstrap-datepicker.en-NZ.min';
import 'bootstrap-datepicker-webpack/dist/locales/bootstrap-datepicker.en-ZA.min';
import 'bootstrap-datepicker-webpack/dist/locales/bootstrap-datepicker.es.min';
import 'bootstrap-datepicker-webpack/dist/locales/bootstrap-datepicker.fr.min';
import 'bootstrap-datepicker-webpack/dist/locales/bootstrap-datepicker.es.min';
import 'bootstrap-datepicker-webpack/dist/locales/bootstrap-datepicker.zh-CN.min';
import 'bootstrap-datepicker-webpack/dist/locales/bootstrap-datepicker.zh-TW.min';

import InputWithIcon from '../../input_with_icon'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faCalendarAlt from '@fortawesome/fontawesome-free-solid/faCalendarAlt'

export default class BootstrapDate extends React.Component
  constructor: (props) -> super(props)

  componentDidMount: ->
    startDate = new Date
    startDate.setDate startDate.getDate()
          # startDate: startDate,
    options =
      todayHighlight: true,
      autoclose: true,
      format: @props.format,
      disableTouchKeyboard: true,
      orientation: 'auto auto'
    if @props.options.bootstrap
      options = $.extend(options, @props.options.bootstrap)
    options.container = @props.options.container if @props.options?.container
    options.language = @props.options.language if @props.options?.language
    picker = $(@refs.field.fieldRef()).datepicker(options)
      .on 'show', @props.onViewportChange
      .on 'hide', @props.onViewportChange
      .on 'changeDate', (e) =>
        @props.onChange?(
          date: e.target.value,
          year: e.date.getFullYear(),
          month: e.date.getMonth() + 1,
          day: e.date.getDate()
        )

    if !@props.value
      if @props.options?.defaultDaysOut
        date = moment()
        date.add(@props.options.defaultDaysOut, 'days')
        picker.datepicker('setDate', date.toDate())
      else if @props.options?.defaultToToday
        picker.datepicker('setDate', new Date)

  showDatePicker: => $(@refs.field.fieldRef()).datepicker('show')

  render: ->
    dateProps = {
      className: 'date-selector',
      content: { icon: <FontAwesomeIcon icon={faCalendarAlt}/> },
      name: @props.fieldName
      onChange: (-> null),
      onFocus: @showDatePicker,
      style: { maxWidth: '170px' },
      ref: 'field'
    }
    dateProps.className += ' has-error' if @props.error

    if @props.controlled
      dateProps.value = (@props.value || '')
    else
      dateProps.defaultValue = @props.value

    <InputWithIcon {...dateProps}/>

BootstrapDate.defaultProps = {
  format: 'mm/dd/yyyy'
}

BootstrapDate.propTypes = {
  controlled: PropTypes.bool,
  error: PropTypes.string,
  fieldName: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func,
  onViewportChange: PropTypes.func,
  options: PropTypes.shape({
    container: PropTypes.string,
    defaultDaysOut: PropTypes.number,
    defaultToToday: PropTypes.bool,
    language: PropTypes.string
  }),
  value: PropTypes.string
}
