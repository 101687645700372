import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faCircleNotch from '@fortawesome/fontawesome-free-solid/faCircleNotch'

import './type.scss'
import Item from '../../selector_item'
import List from '../../selector_list'
import LocationTypeIcon from '../../location_type_icon'

export default class TypeSelector extends React.Component
  constructor: (props) -> super(props)

  down: => @refs.list?.down()

  left: => @props.onBack?()

  select: => @refs.list.select()

  up: => @refs.list?.up()

  toCamelCase: (str) =>
    str.replace /^([A-Z])|[\s-_](\w)/g, (match, p1, p2, offset) ->
      if p2
        return p2.toUpperCase()
      return p1.toLowerCase()

  render: ->
    if @props.loading
      <div className="locs-loading">
        <FontAwesomeIcon icon={faCircleNotch} spin/>
        {@props.content?.loadingMessage}
      </div>
    else
      types = @props.types.map (type) =>
        selected = type.type == @props.value
        <Item key={type.type} parent="true"
                      onClick={=> @props.onSelect(type)}
                      selected={selected} className="loc-type">
          <div className="type-icon">
            <LocationTypeIcon type={type.type} 
                              iconProps={{ size: 'lg', fixedWidth: true }}/>
          </div>
          {type.description.replace(/(<([^>]+)>)/ig, " ")}
        </Item>

      <List key={@props.value} backLabel={@props.content?.backLabel}
                    onBack={@props.onBack} className="type-selector"
                    head={@props.content?.header} ref="list">
        {types}
      </List>

TypeSelector.defaultProps = {
  locations: []
}

TypeSelector.propTypes = {
  content: PropTypes.shape({
    header: PropTypes.string,
    backLabel: PropTypes.string,
    loadingMessage: PropTypes.string
  }),
  loading: PropTypes.bool,
  onBack: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string, # FontAwesome icon name
      description: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired # Location type
    }).isRequired
  ).isRequired,
  value: PropTypes.string
}
