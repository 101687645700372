export submitCart = (adtlParams = {}) ->
  cart = window.reduxStore.getState().cart

  if cart.options.tokenMode == 'i4go' && !cart.fields.CCToken
    i4GoPromise = runI4Go(cart.fields)
  else
    i4GoPromise = $.Deferred().resolve({}).promise()

  promise = i4GoPromise
    .then (updatedFields) =>
      if updatedFields.errors
        $.Deferred().resolve(errors: updatedFields.errors).promise()
      else
        cartFields = cart.fields
        cartFields = $.extend({}, cart.fields, updatedFields)
        postParams = { cart: cartFields, mailing_list: cart.emailSubFields }      
        $.post(window.urls.cart.validateForBooking, postParams)

  {
    promise: promise,
    globalState: window.reduxStore.getState(),
    types: ['SUBMIT_CART_REQUEST', 'SUBMIT_CART_SUCCESS', 'SUBMIT_CART_FAILURE']
  }

export updateEmailFields = (values) ->
  { values: values, type: 'UPDATE_CART_EMAIL_FIELDS' }

export updateFields = (values) ->
  # If cc name is entered and main name field is blank, default main name to cc name
  cart = window.reduxStore.getState().cart
  if values.CCDetails && !values.Name && 
     (!cart.fields.Name || (cart.fields.Name == cart.fields.CCDetails))
    values.Name = values.CCDetails

  # If cc fields changed then clear the token/type code
  if values.hasOwnProperty('CCNumber') || values.hasOwnProperty('CCExp') || 
     values.hasOwnProperty('CCDetails') || values.hasOwnProperty('CCVV') || 
     values.hasOwnProperty('CCZip')
    values.CCToken = ''
    values.cc_token_type = ''

  { values: values, type: 'UPDATE_CART_FIELDS' }

validateI4Go = (i4GoFields, cartFields) ->
  deferredObject = $.Deferred()

  if cartFields.CCExp
    ccExpMonth = Number(cartFields.CCExp.substring(0,2))
    ccExpYear = cartFields.CCExp.substring(2,6)
  
  i4GoAjaxParams = {
    type: 'POST',
    url: i4GoFields.payment_url,
    contentType: "application/json; charset=utf-8",
    dataType: 'JSONP',
    data: {
      fuseaction: 'api.jsonpPostCardEntry',
      i4go_accessblock: i4GoFields.access_block,
      i4go_cardnumber: cartFields.CCNumber,
      i4go_expirationmonth: ccExpMonth,
      i4go_expirationyear: ccExpYear,
      i4go_postalcode: cartFields.CCZip,
      i4go_streetaddress: '',
      i4go_cvv2code: cartFields.CCVV,
      i4go_cardholdername: cartFields.CCDetails
    },
    timeout: 10000,
    cache: false    
  }

  # i4Go expects a global parseResponse function.
  window.parseResponse = (i4GoResponse) =>
    cardType = i4GoResponse.i4go_cardtype
    ccToken = i4GoResponse.i4go_uniqueid

    if i4GoResponse.i4go_response == 'FAILURE'
      errors = i4GoResponse.i4go_responsetext.split(',')
      errorObj = {}
      errorRe = /^(.*?)\((\d+)\)\s*$/

      for error in errors
        matches = error.match(errorRe)
        errorMessage = matches[1]
        code = Number(matches[2])
        if matches 
          if code == 105
            errorObj.CCNumber = errorMessage
          else if code == 106
            errorObj.CCExp = errorMessage
          else if code == 107 || code == 108
            errorObj.CCVV = errorMessage
          else if code == 109
            errorObj.CCDetails = errorMessage
          else if code == 111
            errorObj.CCZip = errorMessage
          else
            errorObj.generalCC = errorMessage

    updatedFields = {
      errors: errorObj,
      CCToken: ccToken,
      CCTypeCode: cardType,
      CCNumber: cartFields.CCNumber.replace(/\d(?=\d{4})/g, "X"),
      CCVV: ''
    }
    deferredObject.resolve(updatedFields)
    window.parseResponse = undefined

  $.ajax(i4GoAjaxParams)
    .error => deferredObject.reject()

  deferredObject.promise()

runI4Go = (cartFields) ->
  deferredObject = $.Deferred()
  $.getJSON(window.urls.cart.i4goData)
    .done (i4GoData) => 
      validateI4Go(i4GoData['i4go'], cartFields)
        .then (fields) => deferredObject.resolve(fields)
        .fail => deferredObject.reject()
    .error =>
      deferredObject.reject()

  deferredObject.promise()

export cartReducer = (state = {}, action) ->
  switch action.type
    when 'SUBMIT_CART_REQUEST'
      newState = $.extend(true, {}, state, submitting: true)
      delete newState.errors
      newState

    when 'SUBMIT_CART_SUCCESS'
      if action.errors
        return $.extend(true, {}, state, submitting: false,
                                         errors: action.errors)
      $.extend(true, {}, state)

    when 'SUBMIT_CART_FAILURE'
      console.error "Unable to submit cart"
      error = window.reduxStore.getState().content.systemError
      $.extend(true, {}, state, submitting: false,
                                errors: { base: error })

    when 'UPDATE_CART_FIELDS'
      newState = $.extend(true, {}, state, { fields: action.values })
      
      # Clear field error
      for field, _value of action.values      
        if field == "special_info"
          delete newState.errors?["specialInfo"] 
        else
          delete newState.errors?[field] 

      if newState.errors?.base && Object.keys(newState.errors).length == 1
        delete newState.errors.base

      newState  

    when 'UPDATE_CART_EMAIL_FIELDS'
      newState = $.extend(true, {}, state, { emailSubFields: action.values })
      newState  

    else
      state
