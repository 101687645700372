import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faSearch from '@fortawesome/fontawesome-free-solid/faSearch'
import faCaretDown from '@fortawesome/fontawesome-free-solid/faCaretDown'

import { isMobile } from '../../utils/utils'
import InputWithIcon from '../input_with_icon'
import FakeField from '../fake_field'
import SelectorItem from '../selector_item'
import SelectorList from '../selector_list'
import SelectorPopup from '../selector_popup'
import AutoCompleteSelector from '../location_selector/selectors/auto_complete'

import './airport.scss'

export default class Airport extends React.Component
  constructor: (props) ->
    super(props)
    @state = {
      displayPopup: false,
      displayedAirports: @props.airports,
      displayedValue: @_currentAirportName() # What's shown in the textbox.
    }

  keyActions: # Keyboard actions
    tab: (-> @refs.list?.select?()),
    down: (-> if @state.displayPopup then @refs.list?.down?() else @showPopup()),
    up: (-> @refs.list?.up?()),
    enter: (-> @refs.list?.select?()),
    esc: (-> @hidePopup())

  _knmTranslateCode: (code) =>
    {
      9: 'tab', 13: 'enter', 27: 'esc', 37: 'left', 38: 'up', 39: 'right',
      40: 'down'
    }[code]

  handleKeyDown: (e) =>
    keyCode = e.keyCode || e.which
    name = @_knmTranslateCode(keyCode)
    if @keyActions?[name]?
      @keyActions[name].call(this)
      e.preventDefault() unless name == 'tab'

  componentDidMount: ->
    @props.onChange(@props.airports[0].code) if @props.airports.length == 1
    window.addEventListener('resize', @forceUpdateWithState)

  componentWillUnmount: -> 
    window.removeEventListener('resize', @forceUpdateWithState)

  componentDidUpdate: (prevProps, prevState) ->
    # Resize page if popup is shown or hidden
    if !prevState.displayPopup && @state.displayPopup
      @props.onViewportChange?()
    else if prevState.displayPopup && !@state.displayPopup
      @props.onViewportChange?()

    if prevProps.value != @props.value
      @setState displayedValue: @_currentAirportName()

    if prevProps.airports.length != @props.airports.length ||
       prevProps.airports[0]?.code != @props.airports[0]?.code
      @setState displayedAirports: @props.airports
      @props.onChange(@props.airports[0].code) if @props.airports.length == 1

  forceUpdateWithState: => @forceUpdate()

  _airportSelected: (airport) =>
    @setState displayedAirports: @props.airports
    @props.onChange(airport.code)
    @hidePopup()

  _currentAirportName: =>
    return '' unless @props.value
    airport = air for air in @props.airports when air.code.toLowerCase() == @props.value.toLowerCase()
    airport?.name

  _renderInputField: ->
    if @props.mode == 'search'
      searchField = <InputWithIcon className="airport-search-field"
                                   content={{
                                     icon: icon,
                                     placeholder: @props.content.placeholder
                                   }}
                                   onChange={@search}
                                   onFocus={@showPopup}
                                   onKeyDown={@handleKeyDown}
                                   value={@state.displayedValue}/>
    else
      icon = <FontAwesomeIcon icon={faCaretDown}/>

    <div>
      {searchField}
      <FakeField className="airport"
                 content={{
                   icon: icon,
                   placeholder: @props.content.placeholder
                 }}
                 onClick={@showPopup}
                 onFocus={@showPopup}
                 onKeyDown={@handleKeyDown}
                 ref="airport"
                 value={@_currentAirportName()}/>
    </div>

  _renderPopup: ->
    if @state.displayedAirports.length || isMobile(767)
      if !isMobile(767)
        airports = @state.displayedAirports.map (airport) =>
          onClick = @_airportSelected.bind(this, airport)
          <SelectorItem key={airport.code} onClick={onClick}>
            {airport.name}
          </SelectorItem>
        popupContent = <SelectorList ref="list">
                        {airports}
                      </SelectorList>

      else
        requestedAirports = {
          query: @state.displayedValue,
          source: 'hudson',
          locations: @state.displayedAirports
        }
        popupContent = <AutoCompleteSelector content={{ noLocationsFound: @props.content.noMatchesFound }}
                                            initialLocations={@props.airports}
                                            requestedLocations={requestedAirports}
                                            onLocationsRequested={@search}
                                            onSelect={@_airportSelected}
                                            searchCharacterMin={1}/>
    else
      airports = []
      popupContent = <div className="no-airports">
                       {@props.content.noMatchesFound}
                     </div>

    <SelectorPopup onClose={@hidePopup} content={@props.content}>
      {popupContent}
    </SelectorPopup>

  hidePopup: =>
    @setState displayedValue: @_currentAirportName(), displayPopup: false

  search: (eventOrQuery) =>
    if typeof eventOrQuery =='object'
      query = eventOrQuery.target.value
    else
      query = eventOrQuery      
    @setState displayedValue: query

    if query.length
      results = []
      for airport in @props.airports
        queryRE = new RegExp(query.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"), 'i')
        if airport.name.match(queryRE) || airport.code.match(queryRE)
          results.push(airport)

      @setState displayedAirports: results, displayPopup: true

    else
      @setState displayedValue: '', displayedAirports: @props.airports

  showPopup: => @setState displayedValue: '', displayPopup: true

  render: ->
    if @props.airports.length == 1 && @state.displayedValue?
      field = <div className="static-airport">{@state.displayedValue}</div>
    else
      field = @_renderInputField()

    <div className="airport-container">
      <label dangerouslySetInnerHTML={{__html: @props.content.label}}/>
      {field}
      {if @state.displayPopup then @_renderPopup()}
    </div>

Airport.defaultProps = {
  mode: 'search'
}

Airport.propTypes = {
  airports: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string
  })).isRequired,
  content: PropTypes.shape({
    label: PropTypes.string,
    noMatchesFound: PropTypes.string,
    placeholder: PropTypes.string
  }),
  mode: PropTypes.oneOf(['list', 'search']),
  onChange: PropTypes.func.isRequired,
  onViewportChange: PropTypes.func,
  value: PropTypes.string
}
