import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Checkbox from 'antd/lib/checkbox'
import 'antd/lib/checkbox/style/css'
import { graphql } from '@apollo/react-hoc';
import gql from "graphql-tag";

import './flight_type.scss'

class FlightType extends React.Component
  componentDidMount: ->
    if @props.options.flightTypeAsCheckbox && @props.value != 'I' && @props.value != 'D'
      @props.onChange('D')

  domesticLabel: => @props.content.domesticLabel || 'Domestic'

  internationalLabel: => @props.content.internationalLabel || 'International'

  onChange: (e) => @props.onChange?(e.target.value)

  renderError: ->
    return '' unless @props.error
    <label className="error-label"
           dangerouslySetInnerHTML={{__html: @props.error}}/>

  renderCheckbox: ->
    style = ".ant-checkbox-checked .ant-checkbox-inner { \
            background-color: #{@props.colors?.buttonBackground}; \
            color: #{@props.colors?.buttonColor}; \
          } \
    "

    if @props.content?.label && !@props.options?.noLabel
      label = <label className="empty-label">&nbsp;</label>

    onChange = (e) =>      
      if e.target.checked
        @props.onChange('I')
      else
        @props.onChange('D')

    <div className="flight-type-checkbox">
      <style>{style}</style>
      {label}
      <Checkbox checked={@props.value == 'I'} onChange={onChange}>
        {@internationalLabel()}
      </Checkbox>
      {@renderError()}
      <input type="hidden" name={@props.fieldName} value={@props.value || 'D'}/>
    </div>

  renderSelect: ->
    selectProps = {
      className: "form-control",
      name: @props.fieldName,
      onChange: @onChange
    }
    selectProps.className += ' has-error' if @props.error

    selectProps.value = (@props.value || '')

    if @props.content?.label && !@props.options?.noLabel
      label = <label dangerouslySetInnerHTML={{__html: @props.content.label}}/>

    <div>
      {label}
      <div className="input-group">
        <select {...selectProps}>
          <option value='' disabled>{@props.content.blankLabel}</option>
          <option value="D">{@domesticLabel()}</option>
          <option value="I">{@internationalLabel()}</option>
        </select>
      </div>
      {@renderError()}
    </div>

  render: ->
    if @props.options.flightTypeAsCheckbox
      @renderCheckbox()
    else
      @renderSelect()

FlightType.propTypes = {  
  content: PropTypes.shape({
    label: PropTypes.string,
    blankLabel: PropTypes.string,
    domesticLabel: PropTypes.string,
    internationalLabel: PropTypes.string
  }),
  error: PropTypes.string,
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.shape({
    flightTypeAsCheckbox: PropTypes.bool,
    noLabel: PropTypes.bool
  }),
  value: PropTypes.string
}

# --- Connect to Redux
mapStateToProps = (state, ownProps) ->
  options: $.extend(true, {}, state.configuration, (ownProps.options || {}))

# -- Apollo/GraphQL
apolloComponent = graphql(gql("
  query flightTypeQuery {
    colors {
      buttonBackground
      buttonColor
      id
      }
  }"),  
  {
    props: (apollo) => 
      return {
        colors: apollo.data.colors || {}
      }
  }
)(FlightType)

export default connect(mapStateToProps)(apolloComponent)