import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchLocations } from '../../../redux/locations'
import { fetchLocationTypes } from '../../../redux/location_types'
import { fetchSiteConfig } from '../../../redux/site_configuration'

import AirportSelector from '../airport_selector'
import DirectionSelector from '../direction_selector'
import Flight from '../flight'
import PassengerSelector from '../passenger_selector'
import ResEditHeader from '../res_edit_header'
import RoundtripHourlyToggle from '../roundtrip_hourly_toggle'
import SiteAndLocation from '../site_and_location'
import SupplementaryInfo from '../supplementary_info'

import './airport.scss'

class AirportReservation extends React.Component
  constructor: (props) -> super(props)

  airportChanged: (airport) =>
    @props.onAirportChanged(airport)

    airport = air for air in @props.airports when air.code == airport
    if airport?.regions.length == 1
      @props.onSiteConfigRequested(airport.regions[0].providerSiteId)

    @props.onLocationTypesRequested() # Preload location type dialog.

  currentAirport: =>
    return null if !@props.airports
    (a for a in @props.airports when a.code == @props.fields.airport)?[0]

  componentDidUpdate: (prevProps, prevState) -> @props.onViewportChanged()

  renderFlight: ->
    <Flight fields={@props.fields}
            errors={@props.errors}
            onChange={@props.onFieldsChanged}
            onCustomerRequestChange={@props.onCustomerRequestChanged}
            onReturnChange={@props.onReturnFieldsChanged}
            onViewportChange={@props.onViewportChanged}
            options={
              $.extend(@props.options, { showFlightDate: true, showFlightType: true })
            }
            returnErrors={@props.returnErrors}
            returnFields={@props.returnFields}/>

  renderPassengers: ->
    return null unless @props.options.showPassengersOnEntryPage
    isMobile = window.matchMedia('screen and (max-width: 767px)').matches
    <PassengerSelector
      error={@props.errors?.passengers}
      onChange={(field, value) =>
        obj = { passengers: {} }
        obj.passengers[field] = value
        @props.onFieldsChanged(obj) }
      onViewportChange={@props.onViewportChanged}
      options={{ usePopup: !isMobile }}
      value={@props.fields.passengers}/>

  renderRoundtripHourlyToggle: ->
    return null if @props.options.onewayOnly || @props.options.editMode
    <RoundtripHourlyToggle value={@props.fields.mode}
      onChange={(mode) => @props.onFieldsChanged({mode})}
      options={{hourlyEnabled: false}}/>

  renderSiteAndLocation: ->
    content = @props.content.siteAndLocation
    if @props.fields.direction == 'D'
      label = content.fromLabel
    else
      label = content.toLabel

    <SiteAndLocation
      content={{
        closeLabel: @props.content.location.closeLabel,
        fetchingMessage: @props.content.location.fetchingMessage,
        label: label,
        noLocationsFound: @props.content.location.noLocationsFound,
        placeholder: @props.content.siteAndLocation.placeholder }}
      disabled={!@props.fields.airport}
      site={{
        sites: @currentAirport()?.regions,
        value: @props.fields.providerSiteId }}
      location={ value: @props.fields.location}
      locationsByType={@props.locationsByType}
      locationsByTypeLoading={@props.locationsByTypeLoading}
      onLocationChange={(location) => @props.onFieldsChanged({location}) }
      onLocationsRequested={@props.onLocationsRequested}
      onLocationTypesRequested={@props.onLocationTypesRequested}
      onSiteChange={(providerSiteId, serviceArea) =>
        @props.onFieldsChanged({providerSiteId, serviceArea})
        @props.onSiteConfigRequested(providerSiteId)
      }
      onViewportChange={@props.onViewportChanged}
      requestedLocations={@props.requestedLocations}
      searchCenter={{
        lat: @currentAirport()?.lat, lng: @currentAirport()?.lon}}/>

  renderSupplemental: ->
    <SupplementaryInfo className="res-supplemental">
      {@renderRoundtripHourlyToggle()}
      {@renderFlight()}
    </SupplementaryInfo>

  render: ->
    <div className="airport-reservation">
      {<ResEditHeader res={@props.fields}/> if @props.options.editMode}

      <div className="ma-panel control-group tab-content hudson-subsection panel reservation">
        <DirectionSelector value={@props.fields.direction}
              onChange={(direction) =>
                @props.onFieldsChanged({direction})}/>

        <div className="airport-loc-row">
          <div className="airport-loc-container">
            <AirportSelector value={@props.fields.airport}
                        onChange={@airportChanged}
                        onViewportChange={@props.onViewportChanged}/>
          </div>

          <div className="airport-loc-container">
            {@renderSiteAndLocation()}
          </div>

          {@renderPassengers()}
        </div>

        <hr/>

        {@renderSupplemental()}

        { 
          if @props.submitButton
            <div className="submit-container">
              {@props.submitButton()}
            </div>        
        }
      </div>
    </div>

# --- Connect to Redux
mapStateToProps = (state, ownProps) ->
  locTypeKey = ownProps.fields.providerSiteId + '.' +
               ownProps.fields.airport + '.' +
               ownProps.fields.serviceArea

  if ownProps.fields.providerSiteId
    siteConfig = state.siteConfiguration[ownProps.fields.providerSiteId]
  else
    siteConfig = {}

  {
    content: state.content,
    locationsByType: (state.locationTypes[locTypeKey]?.types || []),
    locationsByTypeLoading: (state.locationTypes[locTypeKey]?.fetching || siteConfig?.fetching),
    options: $.extend({}, state.configuration, siteConfig),
    requestedLocations: (state.locations[ownProps.index] || {})
  }

AirportReservation.defaultProps = {
  fields: { direction: 'D' }
}

AirportReservation.propTypes = {
  airports: PropTypes.array,
  content: PropTypes.shape({
    siteAndLocation: PropTypes.shape({
      fromLabel: PropTypes.string,
      placeholder: PropTypes.string,
      toLabel: PropTypes.string
  })}),
  index: PropTypes.number.isRequired,
  fields: PropTypes.shape({
    airport: PropTypes.string,
    direction: PropTypes.string,
    location: PropTypes.object,
    mode: PropTypes.oneOf(['oneway', 'multi', 'roundtrip', 'hourly']),
    passengers: PropTypes.object,
    providerSiteId: PropTypes.string
  }).isRequired,
  locationsByType: PropTypes.array,
  locationsByTypeLoading: PropTypes.bool,
  onAirportChanged: PropTypes.func.isRequired,
  onCustomerRequestChanged: PropTypes.func,
  onFieldsChanged: PropTypes.func.isRequired,
  onLocationsRequested: PropTypes.func.isRequired,
  onLocationTypesRequested: PropTypes.func.isRequired,
  onReturnFieldsChanged: PropTypes.func,
  onViewportChanged: PropTypes.func,
  requestedLocations: PropTypes.object,
  returnErrors: PropTypes.object,
  returnFields: PropTypes.object
}

mapDispatchToProps = (dispatch, ownProps) -> bindActionCreators({
  onLocationsRequested: ((locType, query) ->
    fetchLocations(ownProps.index, locType, query, true)),
  onLocationTypesRequested: (-> fetchLocationTypes(ownProps.index, true)),
  onSiteConfigRequested: ((siteId) -> fetchSiteConfig(ownProps.index, siteId))
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AirportReservation)
