import React from 'react';
import PropTypes from 'prop-types';

import { parentScrollToElement } from '../utils/utils'
import './notice_box.scss'

export default class NoticeBox extends React.Component
  constructor: (props) -> super props

  componentDidMount: -> parentScrollToElement($(@box))

  componentDidUpdate: (prevProps, prevState) ->
    if prevProps.content.message != @props.content.message
      parentScrollToElement($(@box))

  htmlElement: => @box

  # Note: These are bootstrap classes
  noticeClass: =>
    if @props.type == 'error' then 'alert-danger' else 'alert-info'

  render: ->
    className = "alert notice-box #{@noticeClass()}"
    if @props.className
      className += " #{@props.className}" 

    if @props.content.message
      <div className={className} ref={(box) => @box = box}
           dangerouslySetInnerHTML={__html: @props.content.message}/>
    else
      null

NoticeBox.propTypes =  {
  className: PropTypes.string,
  content: PropTypes.shape({ message: PropTypes.string }),
  type: PropTypes.oneOf(['error', 'notice'])
}
