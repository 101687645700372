import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import './login_modal.scss'
# A bootstrap modal for logging in.

class LoginModal extends React.Component
  constructor: (props) -> super(props)

  componentDidMount: -> $(@refs.modal).on('hidden.bs.modal', @props.onHide)

  componentDidUpdate: (prevProps) ->
    if !prevProps.displayed && @props.displayed
      @show()

  login: (e) =>
    e.preventDefault()
    $(@refs.button).button('loading')

    formData = $(@refs.form).serialize()
    $.post window.urls.profiles.login, formData, (data) =>
      $(@refs.button).button('reset')
      if data.error
        alert(data.error)
      else
        window.location.reload()

  show: => $(@refs.modal).modal('show')

  render: ->
    <div className='modal entry-page-login-modal' ref="modal">
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="modal-body">
            <form role="form" className="login-modal-form"
                  ref="form" onSubmit={@login}>
              <label dangerouslySetInnerHTML={__html: @props.content.userLabel}/>
              <div className="row">
                <div className="col-lg-12">
                  <input type="text" name="username" className="form-control"/>
                </div>
              </div>

              <label dangerouslySetInnerHTML={__html: @props.content.passwordLabel}/>
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-group">
                    <input type="password" name="password" className="form-control"/>
                    <span className="input-group-btn">
                      <button className="ma-button btn hudson-button" ref="button"
                        dangerouslySetInnerHTML={__html: @props.content.submitLabel}/>
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

LoginModal.propTypes = {
  content: PropTypes.shape({
    passwordLabel: PropTypes.string.isRequired,
    submitLabel: PropTypes.string.isRequired,
    userLabel: PropTypes.string.isRequired
  }),
  displayed: PropTypes.bool,
  onHide: PropTypes.func
}


# --- Connect to Redux
mapStateToProps = (state) ->
  content: state.content.loginModal

LoginModal = connect(mapStateToProps)(LoginModal)

export default LoginModal
