import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import promiseMiddleware from "./middleware/promise";
import accesStoreMiddleware from "./middleware/access_store";

import { airlinesReducer } from "./airlines";
import { airlinePickupPointsReducer } from "./airline_pickup_points";
import { airportsReducer } from "./airports";
import { cartReducer } from "./cart";
import { configReducer } from "./configuration";
import { airportsBySiteReducer } from "./airports_by_site";
import { contentReducer } from "./content";
import { extraStopsReducer } from "./extra_stops";
import { flightCitiesReducer } from "./flight_cities";
import { groupsReducer } from "./groups";
import { locationsReducer } from "./locations";
import { locationTypesReducer } from "./location_types";
import { macrosReducer } from "./macros";
import { passengerFieldsReducer } from "./passenger_fields";
import { passengerTypesReducer } from "./passenger_types";
import { providerContentReducer } from "./provider_content";
import { regionsReducer } from "./regions";
import { reservationsReducer } from "./reservations";
import { serviceAreasReducer } from "./service_areas";
import { serviceExtrasReducer } from "./service_extras";
import { servicesReducer } from "./services";
import { shipsReducer } from "./ships";
import { siteConfigurationReducer } from "./site_configuration";
import { specialInfoReducer } from "./special_info";
import { tripTypesReducer } from "./trip_types";

const reducer = combineReducers({
  airlines: airlinesReducer,
  airlinePickupPoints: airlinePickupPointsReducer,
  airports: airportsReducer,
  airportsBySite: airportsBySiteReducer,
  cart: cartReducer,
  configuration: configReducer,
  content: contentReducer,
  extraStops: extraStopsReducer,
  flightCities: flightCitiesReducer,
  groups: groupsReducer,
  locations: locationsReducer,
  locationTypes: locationTypesReducer,
  macros: macrosReducer,
  passengerFields: passengerFieldsReducer,
  passengerTypes: passengerTypesReducer,
  providerContent: providerContentReducer,
  regions: regionsReducer,
  reservations: reservationsReducer,
  serviceAreas: serviceAreasReducer,
  serviceExtras: serviceExtrasReducer,
  services: servicesReducer,
  ships: shipsReducer,
  siteConfiguration: siteConfigurationReducer,
  specialInfo: specialInfoReducer,
  tripTypes: tripTypesReducer
});

let middleware = [promiseMiddleware, accesStoreMiddleware];

export default function configureStore(initialState) {
  window.reduxStore = createStore(
    reducer,
    window.initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );

  return window.reduxStore;
}
