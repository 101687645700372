import React from 'react';

import './supplementary_info.scss'

export default class EntryPageSupplementaryInfo extends React.Component
  constructor: (props) -> super(props)

  render: ->
    className = "supplementary-info"
    className += " #{@props.className}" if @props.className
    <div className={className} style={@props.style}>
      {@props.children}
    </div>
