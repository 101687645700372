_service = undefined;

# Accepts an address string, returns a google location.
export directions = (start, end, fullResponse = false) ->
  deferredObject = $.Deferred()

  request = {
    origin: start,
    destination: end,
    travelMode: google.maps.TravelMode.DRIVING
  }

  _service ||= new google.maps.DirectionsService()
  _service.route request,  (response, status) =>
    if status == google.maps.DirectionsStatus.OK
      returnVal = if fullResponse then response else response.routes[0].legs[0]
      deferredObject.resolve(returnVal)
    else
      console.warn "Directions service was not successful for the " +
                   "following reason: #{status}"
      deferredObject.reject(status)
  deferredObject.promise()
