import React from "react";
import PropTypes from "prop-types";
import { render } from "react-dom";
import App from "../app/single_page_scripts/app";
import client from "./apollo/init";
import { ApolloProvider } from "react-apollo";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import configureStore from "../app/redux/configure_store";
import Page from "../app/components/page";

function OptionalIntlProvider({ children, include = true }) {
  return include ? <IntlProvider locale={window.locale}>{children}</IntlProvider> : children;
}

OptionalIntlProvider.propTypes = {
  children: PropTypes.any,
  include: PropTypes.bool,
};

function OptionalReduxProvider({ children, include = true }) {
  return include ? <Provider store={configureStore()}>{children}</Provider> : children;
}

OptionalReduxProvider.propTypes = {
  children: PropTypes.any,
  include: PropTypes.bool,
};

export function entryPoint(Component, componentProps = {}, options = {}) {
  document.addEventListener("DOMContentLoaded", function () {
    const attachPoint = document.getElementsByClassName("attach-point")[0];

    render(
      <ApolloProvider client={client}>
        <OptionalIntlProvider include={!options.disableIntl}>
          <OptionalReduxProvider include={options.useRedux}>
            <Page noGlobalWrap={options.noGlobalWrap} noInitialScroll={options.noInitialScroll}>
              <Component notice={window.currentNotice} {...componentProps} />
            </Page>
          </OptionalReduxProvider>
        </OptionalIntlProvider>
      </ApolloProvider>,
      attachPoint
    );
  });

  window.app = new App();

  // Does nothing but break the bfcache
  // Safari (desktop and mobile) caches some pages (bfcache) which doesn't work
  // well with the entry page. This forces cached pages to reload.
  window.onpageshow = function (event) {
    if (event.persisted) {
      window.location.reload();
    }
  };
}
