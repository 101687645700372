import React from 'react';
import PropTypes from 'prop-types';

import './selector_popup.scss'

export default class SelectorPopup extends React.Component
  constructor: (props) -> super(props)

  componentDidMount: ->
    $('body').addClass('modal-is-open')

  componentWillUnmount: -> $('body').removeClass('modal-is-open')

  render: ->
    className = @props.className || ''

    if @props.bottomContent
      bottomContent = <div className="selector-popup-bottom">
        {@props.bottomContent}
      </div>

    <div className={className}>
      <div className="selector-popup-back" onClick={=> @props.onClose()}/>
      <div className="selector-popup container-fluid" style={@props.style}>
        <div className="control-bar">
          <span className="close btn btn-default" onClick={=> @props.onClose()}>
            {@props.content.closeLabel}
          </span>
        </div>
        {@props.children}
        {bottomContent}
      </div>
      
    </div>

SelectorPopup.propTypes = {
  className: PropTypes.string,
  content: PropTypes.shape({
    closeLabel: PropTypes.string
  }),
  onClose: PropTypes.func,
  style: PropTypes.object
}
