_geocodeCache = {};
_geocoder = undefined;

# Accepts an address string, returns a google location.
export geocode = (address, options = {}) ->
  deferredObject = $.Deferred()
  _geocodeCache ||= {}

  # Cache geocodes so we don't call the service unnecessarily.
  if _geocodeCache[address]?
    if options.locationOnly
      result = _geocodeCache[address].geometry.location
    else
      result = _geocodeCache[address]
    return $.Deferred().resolve(result).promise()

  _geocoder ||= new google.maps.Geocoder()
  gcFilters = if options.placeId then { placeId: address } else { address }
  _geocoder.geocode gcFilters, (results, status) =>
    if status == google.maps.GeocoderStatus.OK
      _geocodeCache[address] = results[0]
      if options.locationOnly
        deferredObject.resolve(results[0].geometry.location)
      else
        deferredObject.resolve(results[0])
    else if options.failOk
      console.warn "Geocode was not successful for the following reason: #{status}"
      deferredObject.resolve()
    else
      console.warn "Geocode was not successful for the following reason: #{status}"
      deferredObject.reject(status)
  deferredObject.promise()

# Accepts an incomplete Hudson reservation object (containing only address
# and city, for example) and returns a fleshed out reservation object after
# geocoding it through Google.
# Returns a promise.
export geocodeToHudson = (incResObject, options = {}) ->
  deferredObject = $.Deferred()

  if incResObject.googlePlaceId
    geocoding = geocode(incResObject.googlePlaceId, placeId: true, failOk: options.failOk)
  else if incResObject.lookupString
    geocoding = geocode(incResObject.lookupString, failOk: options.failOk)
  else
    # If we have address, city, and state then don't include the location name.
    # Location name may not be relevant (ex. "My Home Location") and could
    # prevent a successful geocode. Only use loc name if we may not have
    # enough info to geocode otherwise.
    address = ''
    if !incResObject.address || !incResObject.city || !incResObject.state
      address += "#{incResObject.description || incResObject.name || ''}"
    address += " #{incResObject.address || ''} "
    address += "#{incResObject.city}, " if incResObject.city
    address += " #{incResObject.state || ''} #{incResObject.zip || ''}"

    geocoding = geocode(address, failOk: options.failOk)

  geocoding.then (location) =>
    options.displayName = incResObject.name
    if location
      newLoc = googleToHudson(location, options)
      deferredObject.resolve(newLoc)
    else # We couldn't geocode but failure was ok.
      deferredObject.resolve(incResObject)

# Translate a google location to a hudson-friendly location object.
export googleToHudson = (googleLoc, options = {}) ->
  allowRouteLevelLocs = options.routeLevelLocs || false

  # Create a more accessible object
  comps = {}
  shortNameComps = ['route', 'administrative_area_level_1', 'country']
  for comp in googleLoc.address_components
    compType = comp.types[0]
    if shortNameComps.indexOf(compType) > -1
      comps[compType] = comp.short_name
    else
      comps[compType] = comp.long_name

    # Special case for NY boroughs
    if comp.types.indexOf('sublocality_level_1') > -1
      comps.sublocality_level_1 = comp.short_name

  name = [comps.establishment, comps.point_of_interest, comps.premise].filter((word) => word)[0]
  number = comps.street_number || ''
  address = comps.route
  city = [comps.locality, comps.postal_town, comps.administrative_area_level_3, comps.sublocality_level_1].filter((word) => word)[0]
  state = comps.administrative_area_level_1
  zip = comps.postal_code
  country = comps.country

  address = if number then "#{number} #{address}" else address

  # Error if this location isn't specific enough.
  hasRoute = googleLoc.types.indexOf("route") != -1
  specificEnoughTypes = ['street_address', 'establishment', 'point_of_interest',
                         'premise', 'intersection', 'subpremise', 'airport',
                         'park']
  isSpecificEnough = (allowRouteLevelLocs && hasRoute) ||
                       _locationIsOneOfType(googleLoc, specificEnoughTypes)
  error = "not_specific_enough" unless isSpecificEnough

  # If this is a business/POI it's ok that we don't have an address but
  # let's put a placeholder in the address field so that code later on
  # doesn't yell that address is empty.
  if !address && (
       googleLoc.types.indexOf("establishment") != -1 ||
       googleLoc.types.indexOf("point_of_interest") != -1)
    address = '--'

  name = if name then name else googleLoc.formatted_address
  {
    address, city, state, zip, name: name, error, country,
    google_place_id: googleLoc.place_id,
    display_name: options.displayName,
    map_point: "#{googleLoc.geometry.location.lat()},#{googleLoc.geometry.location.lng()}",
  }

export _locationIsOneOfType = (location, types) ->
  for type in types
    return true if location.types.indexOf(type) != -1
  false
