import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faAnchor from '@fortawesome/fontawesome-free-solid/faAnchor'
import faArrowsAltH from '@fortawesome/fontawesome-free-solid/faArrowsAltH'
import faBus from '@fortawesome/fontawesome-free-solid/faBus'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import faList from '@fortawesome/fontawesome-free-solid/faList'
import faPlane from '@fortawesome/fontawesome-free-solid/faPlane'

import './trip_type_selector.scss'

export class TripTypeSelector extends React.Component
  constructor: (props) -> super(props)

  enabledTypesCount: => (type for type in @props.types when type.enabled).length

  renderTripType: (type) ->
    isActive = @props.current == type.type
    className = "ma-trip-type"
    className += " ma-active" if isActive
    aClassName = if isActive then 'hudson-button' else 'hudson-app'

    # Reference the imported font-awesome names so we can reference them dynamically.
    anchor = faAnchor
    bus = faBus
    layerGroup = faLayerGroup
    list = faList
    plane = faPlane

    try
      if type.icon == 'arrows-alt-h' || type.icon == 'arrows-h'
        icon = faArrowsAltH
      else
        icon = eval(type.icon)
    catch error
      icon = faList
      console.error "Unable to load icon #{type.icon}"
      console.error error

    <li key={type.type} className={className}>
      <a href={type.url} tabIndex="-1" className={aClassName}>
        <FontAwesomeIcon icon={icon}/>
        <div dangerouslySetInnerHTML={__html: type.label}/>
      </a>
    </li>

  render: ->
    if @enabledTypesCount() > 1
      <ul className="ma-entry-tt-selector nav nav-tabs">
        {@renderTripType(type) for type in @props.types when type.enabled}
      </ul>
    else
      null

TripTypeSelector.defaultProps = {
  types: []
}

TripTypeSelector.propTypes = {
  current: PropTypes.string, # Currently selected trip type
  types: PropTypes.arrayOf(
    PropTypes.shape({
      enabled: PropTypes.bool,
      icon: PropTypes.string,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
  })).isRequired
}

# --- Connect to Redux
mapStateToProps = (state) ->
  current: state.tripTypes.current,
  types: state.tripTypes.types

export ReduxedTripTypeSelector = connect(mapStateToProps)(TripTypeSelector)
