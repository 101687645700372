import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import AirportReservation from "./airport";

const NETWORK_AIRPORTS_QUERY = gql`
  query airportReservationQuery {
    networkAirports {
      id
      code
      lat
      lon
      regions {
        description
        providerSiteId
        serviceAreaId
      }
    }
  }
`;

function AirportReservationWithApollo({ ...props }) {
  const { loading, data } = useQuery(NETWORK_AIRPORTS_QUERY);

  return (
    !loading && (
      <AirportReservation airports={data.networkAirports} {...props} />
    )
  );
}

export default AirportReservationWithApollo;
