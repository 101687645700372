import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { graphql } from '@apollo/react-hoc';
import gql from "graphql-tag";

import './bus_form.scss'
import {
  saveReservation, updateReservationFields, updateReservationLocation
} from '../../redux/reservations'
import Reservation from './reservation/bus'
import RoundtripHourlyToggle from './roundtrip_hourly_toggle'
import SelectList from '../fields/select_list'

class EntryPageBusForm extends React.Component
  constructor: (props) -> super(props)

  componentDidMount: ->
    # Make sure service group gets defaulted.
    if @props.serviceGroups.length == 1
      @serviceGroupChanged(@props.serviceGroups[0].id)

    if @props.options.optimizeSpace
      @props.onFieldsChanged(0, {roundtrip: 'oneway'})

  componentDidUpdate: (prevProps, prevState) ->
    if @props.reservations[0].roundtrip != prevProps.reservations[0].roundtrip
      @props.onViewportChanged()  

  redirectParentToMainFramePage: =>
    window.parent.window.postMessage("page_redirect=#{window.urls.parent.mainFrame}", '*')

  serviceGroupChanged: (id) =>
    @props.onFieldsChanged(0, serviceGroupId: id, serviceArea: @serviceGroup().serviceArea)
    @props.onFieldsChanged(1, serviceGroupId: id, serviceArea: @serviceGroup().serviceArea)

  serviceGroup: (id = @serviceGroupId()) =>
    (group for group in @props.serviceGroups when Number(group.id) == Number(id))[0]

  serviceGroupId: =>
    @props.reservations[0].serviceGroupId || @props.serviceGroups[0]?.id
  
  renderForm: =>
    if @props.serviceGroups.length == 0
      return "No service groups configured"

    unless @props.options.optimizeSpace
      firstLegHeader = <h3>{@props.content.trip.bus.legHeaders[0]}</h3>
      submitButton = <div className="ma-button-container">
        {@props.submitButton()}
      </div>

    <div>
      <div className="bus-res-container">
        {firstLegHeader}
        <Reservation
            errors={@props.errors?.legs?[0] || {}}
            fields={@props.reservations[0]}
            index={0}
            locations={@serviceGroup()?.locations[0]}
            onFieldsChanged={(fields) => @props.onFieldsChanged(0, fields)}
            onLocationChange={(field, value) =>
              @props.onLocationChanged(0, field, value)}
            onViewportChanged={@props.onViewportChanged}
            submitButton={@props.submitButton()}/>
      </div>

      {@renderRoundTripToggle()}

      { 
        if @props.reservations[0].roundtrip == 'roundtrip' &&
           !@props.options.optimizeSpace
          <div className="bus-res-container second-leg">
            <h3>{@props.content.trip.bus.legHeaders[1]}</h3>
            <Reservation
                errors={@props.errors?.legs?[1] || {}}
                fields={@props.reservations[1]}
                index={1}
                locations={@serviceGroup()?.locations[1]}
                onFieldsChanged={(fields) => @props.onFieldsChanged(1, fields)}
                onLocationChange={(field, value) =>
                  @props.onLocationChanged(1, field, value)}
                onViewportChanged={@props.onViewportChanged}/>
          </div>
      }  

      {submitButton}
    </div>

  renderHeader: ->
    return '' unless @props.content.trip.bus.header
    <div className="entry-page-header"
         dangerouslySetInnerHTML={{__html: @props.content.trip.bus.header}}/>

  renderRoundTripToggle: =>
    if @props.options.optimizeSpace
      onClick = =>
        @props.onFieldsChanged(0, {roundtrip: 'roundtrip'})
        if @props.onSaveData
          @props.onSaveData()
        else
          @redirectParentToMainFramePage()
      return <div className="more-options">
        <a href="#" onClick={onClick}>
          {@props.content.roundtripToggle.widgetLink}
        </a>
      </div>

    <RoundtripHourlyToggle value={@props.reservations[0].roundtrip}
      onChange={(roundtrip) => @props.onFieldsChanged(0, {roundtrip})}
      options={{ hourlyEnabled: false }}/>    

  renderServiceGroup: ->    
    return null unless @props.serviceGroups.length > 1

    values = {}
    values["#{group.name}"] = group.id for group in @props.serviceGroups      

    <div className="service-group">
      <label dangerouslySetInnerHTML={{__html: @props.content.bus.serviceGroupLabel}}/>
      <SelectList value={@serviceGroupId()} 
                  values={values} 
                  onChange={@serviceGroupChanged}/>
    </div>

  render: ->
    if @props.serviceGroupsLoading
      return null
    else
      return <div className="bus-form">
        {@renderHeader()}
        {@renderServiceGroup()}
        {@renderForm()}
      </div>

EntryPageBusForm.propTypes = {
  content: PropTypes.shape({
    header: PropTypes.string
  }),
  errors: PropTypes.object,
  onFieldsChanged: PropTypes.func.isRequired,
  onSaveData: PropTypes.func,
  onViewportChanged: PropTypes.func,
  reservations: PropTypes.array,
  returnErrors: PropTypes.object
}

# --- Connect to Redux
mapStateToProps = (state) ->
  content: state.content,
  errors: state.reservations.errors || {},
  options: state.configuration,
  reservations: state.reservations.legs,
  returnErrors: state.reservations.errors?.legs?[1] || {}

mapDispatchToProps = (dispatch) -> bindActionCreators({
  onFieldsChanged: updateReservationFields,
  onLocationChanged: updateReservationLocation,
  onSaveData: saveReservation
}, dispatch)

# --- Apollo/GraphQL
apolloComponent = graphql(gql("
  query busFormQuery {
    serviceGroups {
      id
      name
      serviceArea    
      locations {
        dropoff {
          code
          displayName
        }
        pickup {
          code
          displayName
        }
      }
    }
  }"),  
  {
    props: (apollo) => 
      return {
        serviceGroups: apollo.data.serviceGroups || [],
        serviceGroupsLoading: apollo.data.loading
      }
  }
)(EntryPageBusForm)

export default connect(mapStateToProps, mapDispatchToProps)(apolloComponent)
