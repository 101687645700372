import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faSearch from '@fortawesome/fontawesome-free-solid/faSearch'
import faCircleNotch from '@fortawesome/fontawesome-free-solid/faCircleNotch'
import faCaretLeft from '@fortawesome/fontawesome-free-solid/faCaretLeft'

import './auto_complete.scss'
import InputWithIcon from '../../input_with_icon'
import Item from '../../selector_item'
import List from '../../selector_list'
import googleSourceImage from './source_images/powered_by_google_on_white.png'

export default class AutoCompleteSelector extends React.Component
  constructor: (props) ->
    super(props)
    @state = {
      inputValue: '',
      inputValueTime: new Date,
      loading: false,
      locations: @props.initialLocations,
      noLocationsFound: false,
      previousSearchValue: ''
    }

  keyActions: # Keyboard actions
    tab: (-> @props.onBlur?()),
    down: (-> @down()),
    up: (-> @up()),
    left: (-> @left()),
    enter: (-> @select())

  _knmTranslateCode: (code) =>
    {
      9: 'tab', 13: 'enter', 27: 'esc', 37: 'left', 38: 'up', 39: 'right',
      40: 'down'
    }[code]

  handleKeyDown: (e) =>
    keyCode = e.keyCode || e.which
    name = @_knmTranslateCode(keyCode)
    if @keyActions?[name]?
      @keyActions[name].call(this)
      e.preventDefault() unless name == 'tab'

  componentDidMount: ->
    # @refs.field?.focus?() unless @props.initialLocations.length

  componentDidUpdate: (prevProps, prevState) ->
    storeSearchQueryChanged = prevProps.requestedLocations?.query != @props.requestedLocations?.query
    storeSearchLocCountChanged = prevProps.requestedLocations?.locations?.length != @props.requestedLocations?.locations?.length
    storeSearchFinished = prevProps.requestedLocations?.fetching && !@props.requestedLocations?.fetching
    storeSearchIsOurs = @props.requestedLocations &&
                          (@props.requestedLocations.query == @state.inputValue) 

    if storeSearchIsOurs &&
       (storeSearchQueryChanged || storeSearchLocCountChanged || storeSearchFinished)
      if !@props.requestedLocations.fetching &&
         @props.requestedLocations.locations.length == 0
        noLocationsFound = true
      @setState
        loading: @props.requestedLocations.fetching,
        locations: @props.requestedLocations.locations,
        noLocationsFound: noLocationsFound

  componentWillUnmount: -> clearTimeout(@_searchDebouncer)

  locationSelected: (loc) => @props.onSelect(loc)

  search: (query) =>
    if query.length
      @setState loading: true, locations: [], noLocationsFound: false
      @props.onLocationsRequested(query)
    else
      @setState loading: false, locations: @props.initialLocations, noLocationsFound: false

  # Introduces a slight delay to ensure we aren't sending tons of AJAX calls.
  _searchDelayer: (eventTimestamp) =>
    query = @refs['field'].value()
    if eventTimestamp
      eventTime = new Date(eventTimestamp)
    if !eventTime || eventTime.getFullYear() < 2017
      eventTime = new Date

    @setState previousSearchValue: query, inputValue: query, inputValueTime: eventTime
    if query.length >= @props.searchCharacterMin
      clearTimeout(@_searchDebouncer)
      @_searchDebouncer = setTimeout((=> @search(query)), 500)
    else if query.length == 0
      @search(query)

  down: => @selector.down?()

  left: => @props.onBack() if @refs.field.isBlank()

  locationHighlighted: (name) =>
    @setState inputValue: (name || @state.previousSearchValue), inputValueTime: new Date

  select: => @selector.select?()

  up: => @selector.up?()

  renderLoadingIndicator: ->
    return '' unless @state.loading

    <div className="locs-loading">
      <FontAwesomeIcon icon={faCircleNotch} spin/>
      {@props.content.fetchingMessage}
    </div>

  render: ->
    locs = @state.locations.map (loc) =>
      if loc.address
        address = <div><span className='loc-address'>{loc.address}</span></div>
      if loc.city || loc.state
        cityState = <div><span className='loc-address'>{loc.city}, {loc.state}</span></div>

      highlightName = loc.name || loc.address || "#{loc.city}, #{loc.state}"
      <Item key={loc.googlePlaceId || loc[@props.uniqueId] || loc.code} onClick={=> @locationSelected(loc)} name={highlightName}>
        {loc.name} {address} {cityState}
      </Item>

    if @props.onBack?
      backLabel = if @props.backLabel? then @props.backLabel else 'Back'
      back = <div key="back" className="back" onClick={@props.onBack}>
               <FontAwesomeIcon icon={faCaretLeft}/> {backLabel}
             </div>

    if @state.noLocationsFound
      noLocsFoundMessage = <div className="no-locs">
                             {@props.content.noLocationsFound}
                           </div>
    isMobile = window.matchMedia('screen and (max-width: 767px)').matches

    if @props.requestedLocations.source == 'google'
      locs.push <div style={{ textAlign: 'right', margin: '5px' }}>
        <img src={window.urls.relative_root + googleSourceImage}/>
      </div>

    <div className="auto-complete-selector">
      <div className="head-content">
        {back if isMobile}
        <div className="ac-label hudson-subsection">
          {@props.content.header?.replace(/(<([^>]+)>)/ig, " ")}
        </div>
        <div className="input-container hudson-subsection">
          <InputWithIcon content={{
                      icon: <FontAwesomeIcon icon={faSearch}/>,
                      placeholder: @props.content.placeholder
                    }}
                    className="hudson-subsection"
                    onChange={ (e) => @_searchDelayer(e.timeStamp) }
                    ref="field"
                    value={@state.inputValue}
                    valueTime={@state.inputValueTime}
                    onKeyDown={@handleKeyDown}/>
        </div>
      </div>
      {noLocsFoundMessage}
      {@renderLoadingIndicator()}
      <List ref={(list) => @selector = list} onHighlight={@locationHighlighted}>
        {locs}
      </List>
    </div>

AutoCompleteSelector.defaultProps = {
  initialLocations: [],
  searchCharacterMin: 3
}

AutoCompleteSelector.propTypes = {
  content: PropTypes.shape({
    fetchingMessage: PropTypes.string,
    header: PropTypes.string,
    noLocationsFound: PropTypes.string,
    placeholder: PropTypes.string
  }),
  initialLocations: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string
    })
  ),
  requestedLocations: PropTypes.shape({
    query: PropTypes.string,
    source: PropTypes.string,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.string,
        city: PropTypes.string,
        code: PropTypes.string,
        name: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
  }))}),
  onBack: PropTypes.func,
  onBlur: PropTypes.func,
  onLocationsRequested: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  uniqueId: PropTypes.string
}
