import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faChevronDown from '@fortawesome/fontawesome-free-solid/faChevronDown'

import './site_and_service_area.scss'
import FakeField from '../fake_field'
import InputWithIcon from '../input_with_icon'
import SelectorItem from '../selector_item'
import SelectorList from '../selector_list'
import SelectorPopup from '../selector_popup'

class SiteAndServiceArea extends React.Component
  constructor: (props) ->
    super(props)
    @state = {
      displayPopup: false,
      cities: @props.regions.cities,
      selectedCity: undefined,
      regions: [],
      selectedSite: undefined,
      serviceAreas: @props.regions.serviceAreas?[@props.value.site]
    }

  keyActions: { # Keyboard actions
    down: (->
      if @state.displayPopup then @refs.selector?.down?() else @showPopup()),
    enter: (-> @refs.selector?.select?()),
    esc: (-> @hidePopup()), tab: (-> @hidePopup()),
    left: (-> @refs.selector?.left?()),
    right: (-> @refs.selector?.select?()),
    tab: (-> @refs.selector?.select?()),
    up: (-> @refs.selector?.up?())
  }

  _knmTranslateCode: (code) =>
    {
      9: 'tab', 13: 'enter', 27: 'esc', 37: 'left', 38: 'up', 39: 'right',
      40: 'down'
    }[code]

  handleKeyDown: (e) =>
    keyCode = e.keyCode || e.which
    name = @_knmTranslateCode(keyCode)
    if @keyActions?[name]?
      @keyActions[name].call(this)
      e.preventDefault() unless name == 'tab'

  componentDidMount: ->
    @props.onViewportChange()

    # If we're only selecting service areas, and we only have one service area
    # then auto-select it.
    if @serviceAreaOnlyMode() && @state.serviceAreas.length == 1
      @serviceAreaSelected(@state.serviceAreas[0].id)

  componentDidUpdate: (prevProps, prevState) ->
    @props.onViewportChange()

    serviceAreas = @props.regions.serviceAreas?[@props.value.site] || []
    prevServiceAreas = prevState.serviceAreas || []
    if (prevServiceAreas.length != serviceAreas.length)
      @setState serviceAreas: serviceAreas
      if !serviceAreas.find((sA) => sA.id == @props.value.serviceArea)
        @serviceAreaSelected(null)

  citySelected: (city) =>
    @clearSearch()
    regions = @props.regions.regions?[city]
    if regions.length == 1
      @siteSelected(regions[0])
    else
      @setState { selectedCity: city, regions: @props.regions.regions?[city] }

  clearCity: =>
    @setState { selectedCity: undefined, cities: @props.regions.cities }

  clearSearch: => @setState displayedValue: undefined

  clearSite: =>
    @setState { selectedSite: undefined, regions: @props.regions.regions?[city] }

  currentList: =>
    if @currentlySelecting() == 'cities'
       @state.cities.map (item) =>
        onClick = @citySelected.bind(this, item)
        <SelectorItem key={item}
                      onClick={onClick}
                      parent={@props.regions.regions?[item]?.length > 1}>
          {item}
        </SelectorItem>
    else if @currentlySelecting() == 'regions'
       @state.regions.map (item) =>
        onClick = @siteSelected.bind(this, item)
        <SelectorItem key={item.siteId} onClick={onClick}>
          {item.name}
        </SelectorItem>
    else if @currentlySelecting() == 'serviceAreas'
       @state.regions.map (item) =>
        onClick = @serviceAreaSelected.bind(this, item.code)
        <SelectorItem key={item.code} onClick={onClick}>
          {item.name}
        </SelectorItem>

  currentlySelecting: =>
    if @props.regions.cities.length && !@state.selectedCity
      'cities'
    else if @state.regions.length && !@state.selectedSite
      'regions'
    else if @state.serviceAreas?.length
      'serviceAreas'

  fieldValue: =>
    siteId = @props.value?.site
    return '' unless siteId

    # Service area selected?
    if @props.value.serviceArea && @state.serviceAreas?.length
      for area in @state.serviceAreas when Number(area.id) == Number(@props.value.serviceArea)
        return area.name if area.name

    for city, regions of @props.regions.regions
      for region in regions when region.siteId == siteId && region.city == @props.value?.regionCity
        if region.name
          return region.name
        else
          return city

    ''

  hidePopup: =>
    return unless @state.displayPopup
    @refocus() if window.matchMedia('screen and (max-width: 767px)').matches
    @setState displayPopup: false

  locationSelected: (loc) =>
    @props.onLocationChange(loc)
    @hidePopup()

  refocus: => @refs.field.focus?()

  regionsAvailable: => @props.regions.available

  search: (event) =>
    query = event.target.value
    @setState displayedValue: query
    currentlySelecting = @currentlySelecting()

    # if query.length
    results = []
    if @state["original#{currentlySelecting}"]?
      list = @state["original#{currentlySelecting}"]
    else
      list = @state[currentlySelecting]
    queryRE = new RegExp(query.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"), 'i')
    for item in list
      if typeof(item) == 'string'
        results.push(item) if item.match(queryRE)
      else
        results.push(item) if item.name.match(queryRE)

    newState = { displayPopup: true }
    newState["original#{currentlySelecting}"] = list
    newState[currentlySelecting] = results
    @setState newState

    # else
      # @setState displayedResults: @props.regions

  serviceAreaOnlyMode: =>
    !@props.regions.cities.length && @state.serviceAreas?.length

  serviceAreaSelected: (id) =>
    @props.onServiceAreaChange(id)
    @hidePopup()

  showPopup: => @setState displayPopup: true

  siteSelected: (region) =>
    @props.onSiteChange(region.siteId, region.city)

    if region.serviceAreas.length == 1
      @props.onServiceAreaChange(region.serviceAreas[0].id)
    else
      @props.onServiceAreaChange(null)

    @hidePopup()

  renderCityList: ->
    list = @state.cities.map (item) =>
      # If only one region associated, then list it with the city
      regions = @props.regions.regions?[item]
      if regions?.length == 1
        subname = <div><span className='city-subname'>{regions[0].name}</span></div>

      onClick = @citySelected.bind(this, item)
      <SelectorItem key={item}
                    onClick={onClick}
                    parent={@props.regions.regions?[item]?.length > 1}>
        {item}{subname}
      </SelectorItem>

    <SelectorList ref="selector">{list}</SelectorList>

  renderField: ->
    mobile = window.matchMedia('screen and (max-width: 767px)')

    if mobile
      <FakeField content={{
                   icon: <FontAwesomeIcon icon={faChevronDown}/>,
                   placeholder: @props.content.placeholder
                 }}
                 ref="field"
                 onFocus={@showPopup}
                 onKeyDown={@handleKeyDown}
                 onClick={@showPopup}
                 value={@state.displayedValue || @fieldValue()}/>
    else
      <InputWithIcon content={{
                       icon: <FontAwesomeIcon icon={faChevronDown}/>,
                       placeholder: @props.content.placeholder
                     }}
                     ref="field"
                     onChange={@search}
                     onFocus={@showPopup}
                     onKeyDown={@handleKeyDown}
                     value={@state.displayedValue || @fieldValue()}/>

  renderPopup: ->
    if !@state[@currentlySelecting()].length
      popup = <div className="no-matches">{@props.content.noMatchesFound}</div>
    else if @currentlySelecting() == 'cities'
      popup = @renderCityList()
    else if @currentlySelecting() == 'regions'
      popup = @renderRegionList()
    else if @currentlySelecting() == 'serviceAreas'
      popup = @renderServiceAreaList()

    <SelectorPopup content={@props.content} onClose={@hidePopup}>
      {popup}
    </SelectorPopup>

  renderRegionList: ->
    list = @state.regions.map (item) =>
      onClick = @siteSelected.bind(this, item)
      <SelectorItem key={item.siteId} onClick={onClick}>
        {item.name}
      </SelectorItem>

    <SelectorList ref="selector" head={@state.selectedCity} onBack={@clearCity}>
      {list}
    </SelectorList>

  renderServiceAreaList: ->
    list = @state.serviceAreas.map (item) =>
      onClick = @serviceAreaSelected.bind(this, item.id)
      <SelectorItem key={item.id} onClick={onClick}>
        {item.name}
      </SelectorItem>

    onBack = if @state.regions.length then @clearSite else null
    <SelectorList ref="selector" head={@state.selectedSite} onBack={onBack}>
      {list}
    </SelectorList>

  render: ->
    if @regionsAvailable() &&
        !(@serviceAreaOnlyMode() && @state.serviceAreas.length == 1)
      <div className="region-selector">
        <label dangerouslySetInnerHTML={{__html: @props.content.label}}/>
        {@renderField()}
        {if @state.displayPopup then @renderPopup()}
      </div>
    else
      null

SiteAndServiceArea.propTypes = {
  content: PropTypes.shape({
    label: PropTypes.string,
    noMatchesFound: PropTypes.string,
    placeholder: PropTypes.string
  }),
  onServiceAreaChange: PropTypes.func,
  onSiteChange: PropTypes.func,
  onViewportChange: PropTypes.func,
  regions: PropTypes.shape({
    available: PropTypes.bool,
    cities: PropTypes.array,
    regions: PropTypes.object,
    serviceAreas: PropTypes.object
  }),
  value: PropTypes.shape({
    site: PropTypes.string,
    serviceArea: PropTypes.string
  })
}

# --- Connect to Redux
mapStateToProps = (state, ownProps) ->
  content: state.content.siteAndServiceArea,
  regions: state.regions

export default connect(mapStateToProps)(SiteAndServiceArea)
