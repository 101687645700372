export fetchAirlinePickupPoints = (resIndex, airportKey, airline) ->
  res = window.reduxStore.getState().reservations.legs[resIndex]
  return { type: 'NOOP' } unless res.providerSiteId && airportKey

  ppKey = airportKey + airline?.replace(/ /g, '')
  if window.reduxStore.getState().airlinePickupPoints[ppKey]?.fetching
    return { type: 'NOOP' }

  getOptions = {
    provider_site: res.providerSiteId,
    airport: airportKey,
    airline: airline
  }

  {
    promise: $.getJSON(window.urls.airline_pickup_points, getOptions),
    ppKey: ppKey
    siteId: res.providerSiteId,
    types: ['LOAD_AIR_PP_REQUEST',
            'LOAD_AIR_PP_SUCCESS',
            'LOAD_AIR_PP_FAILURE']
  }

export airlinePickupPointsReducer = (state = {}, action) ->
  switch action.type
    when 'LOAD_AIR_PP_REQUEST'
      newState = $.extend(true, {}, state)
      newState[action.ppKey] ||= { points: [] }
      newState[action.ppKey].fetching = true
      newState
    when 'LOAD_AIR_PP_SUCCESS'
      newState = $.extend(true, {}, state)
      newState[action.ppKey] = {
        points: action.points,
        fetching: false
      }
      newState
    when 'LOAD_AIR_PP_FAILURE'
      console.error "Unable to load airline pickup points for #{action.siteId}/#{action.ppKey}"
      newState = $.extend(true, {}, state)
      newState[action.ppKey] = { points: [], fetching: false }
      newState
    else state
