export loadServices = (legIndex, serviceType, fields) ->
  fields = fields || {}
  getFields = $.extend({},
                       fields,
                       { service_type: serviceType, leg_index: legIndex })

  {
    promise: $.getJSON(window.urls.services.load, getFields), legIndex,
    requestDate: new Date().getTime(),
    globalState: window.reduxStore.getState(),
    types: ['LOAD_SERVICES_REQUEST',
            'LOAD_SERVICES_SUCCESS',
            'LOAD_SERVICES_FAILURE']
  }

export calculateFare = (legIndex, legParams) ->
  getFields = legParams + "&leg_index=#{legIndex}"
  {
    promise: $.getJSON(window.urls.services.recalc, getFields), legIndex,
    requestDate: new Date().getTime(),
    types: ['CALC_FARE_REQUEST',
            'CALC_FARE_SUCCESS',
            'CALC_FARE_FAILURE']
  }

export servicesReducer = (state = [], action) ->
  switch action.type
    when 'CALC_FARE_FAILURE'
      console.error "Unable to calculate fare for #{action.legIndex}"
      newState = $.extend(true, {}, state)

      if action.requestDate < newState[action.legIndex].lastFareRequestDate
        return newState

      newState[action.legIndex].fareFetching = false
      newState

    when 'CALC_FARE_REQUEST'
      newState = $.extend(true, {}, state)
      newState[action.legIndex].services ||= {}
      newState[action.legIndex].fareFetching = true
      newState[action.legIndex].lastFareRequestDate = action.requestDate
      newState

    when 'CALC_FARE_SUCCESS'
      newState = $.extend(true, {}, state)

      if action.requestDate < newState[action.legIndex].lastFareRequestDate
        return newState

      if action.error
        newState[action.legIndex].currentFare = "--"        
      else
        newState[action.legIndex].currentFare = action.fare
        newState[action.legIndex].currentFareWithoutGratuity = action.fareWithoutGratuity
      newState[action.legIndex].fareFetching = false

      newState


    when 'LOAD_SERVICES_REQUEST'
      newState = $.extend(true, {}, state)
      newState[action.legIndex].services ||= {}
      newState[action.legIndex].fetching = true
      newState[action.legIndex].lastRequestDate = action.requestDate
      newState

    when 'LOAD_SERVICES_SUCCESS'
      newState = $.extend(true, {}, state)

      if action.requestDate < newState[action.legIndex].lastRequestDate
        return newState

      if action.error
        currentServiceType = action.globalState.reservations.legs[action.legIndex].serviceType
        for service in newState[action.legIndex].services when Number(service.type) == Number(currentServiceType)
          service.unavailable = true
          service.error = action.error
        newState[action.legIndex].fetching = false

      else
        newState[action.legIndex].services = action.services
        newState[action.legIndex].fetching = false

      newState

    when 'LOAD_SERVICES_FAILURE'
      console.error "Unable to load services for #{action.legIndex}"
      newState = $.extend(true, {}, state)

      if action.requestDate < newState[action.legIndex].lastRequestDate
        return newState

      newState[action.legIndex].fetching = false
      newState
    else state
