import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faSearch from '@fortawesome/fontawesome-free-solid/faSearch'
import faCaretDown from '@fortawesome/fontawesome-free-solid/faCaretDown'
import faCircleNotch from '@fortawesome/fontawesome-free-solid/faCircleNotch'

import FakeField from '../fake_field'
import InputWithIcon from '../input_with_icon'
import LocationSelector from '../location_selector/selector'
import SelectorItem from '../selector_item'
import SelectorList from '../selector_list'
import SelectorPopup from '../selector_popup'
import SiteSelector from './site_selector'

# Manages selection and storage of provider site id and location.
export default class SiteAndLocation extends React.Component
  constructor: (props) ->
    super(props)
    @state = {
      displayPopup: false,
      displayedValue: @props.location.value?.name || '',
      searchResultsLoading: false,
      searchResults: null,
      noLocationsFound: false,
      previousSearchValue: ''
    }

  keyActions: { # Keyboard actions
    down: (->
      if @state.displayPopup then @currentSelector()?.down?() else @showPopup()),
    enter: (-> @currentSelector()?.select?()),
    esc: (-> @hidePopup()), tab: (-> @hidePopup()),
    left: (-> @currentSelector()?.left?()),
    right: (-> @currentSelector()?.select?()),
    tab: (-> @currentSelector()?.select?()),
    up: (-> @currentSelector()?.up?())
  }

  _knmTranslateCode: (code) =>
    {
      9: 'tab', 13: 'enter', 27: 'esc', 37: 'left', 38: 'up', 39: 'right',
      40: 'down'
    }[code]

  handleKeyDown: (e) =>
    keyCode = e.keyCode || e.which
    name = @_knmTranslateCode(keyCode)
    if @keyActions?[name]?
      @keyActions[name].call(this)
      e.preventDefault() unless name == 'tab'

  currentSelector: =>
    if @isSearching() then @refs.selector else @refs.selector?.getWrappedInstance()

  componentDidUpdate: (prevProps, prevState) ->
    # Resize page if popup is shown or hidden
    if !prevState.displayPopup && @state.displayPopup
      @props.onViewportChange()
    else if prevState.displayPopup && !@state.displayPopup
      @props.onViewportChange()

    # If our location changed, change the displayed value.
    if prevProps.location.value?.code != @props.location.value?.code
      @setState displayedValue: @props.location.value?.name

    @focusOnSearchAllField() if !prevState.displayPopup && @state.displayPopup

    # Manage search query updates coming from the location redux store.
    storeSearchQueryChanged = prevProps.requestedLocations?.query != @props.requestedLocations?.query
    storeSearchLocCountChanged = prevProps.requestedLocations?.locations?.length != @props.requestedLocations?.locations?.length
    storeSearchFinished = prevProps.requestedLocations?.fetching && !@props.requestedLocations?.fetching
    storeSearchIsOurs = @props.requestedLocations &&
                          (@props.requestedLocations.query == @state.displayedValue) &&
                          @props.requestedLocations.locType == null
    if storeSearchIsOurs &&
       (storeSearchQueryChanged || storeSearchLocCountChanged || storeSearchFinished)
      if !@props.requestedLocations.fetching &&
         @props.requestedLocations.locations.length == 0
        noLocationsFound = true
      @setState
        searchResultsLoading: @props.requestedLocations.fetching,
        searchResults: @props.requestedLocations.locations,
        noLocationsFound: noLocationsFound

  clearSearch: =>
    @setState
      displayedValue: '',
      searchResults: null,
      noLocationsFound: false,
      searchResultsLoading: false

  componentWillUnmount: -> clearTimeout(@_searchDebouncer)

  currentSite: =>
    return unless @props.site.value
    (site for site in @props.site.sites when site.providerSiteId == @props.site.value)[0]

  focusOnSearchAllField: =>
    @refs.searchAllField.focus?() if $('.all-loc-search').is(':visible')

  hidePopup: =>
    @refocus() if window.matchMedia('screen and (max-width: 767px)').matches
    @clearSearch()
    @setState displayPopup: false, displayedValue: @props.location.value?.name

  isDisabled: => @props.disabled || (!@props.site.value && !@props.site.sites?.length)

  isSearching: => @state.searchResults || @state.searchResultsLoading

  locationHighlighted: (name) =>
    @setState displayedValue: (name || @state.previousSearchValue)

  locationSelected: (loc) =>
    @props.onLocationChange(loc)
    @hidePopup()

  refocus: => @refs.field.focus?()

  search: ($searchBox, query) =>
    if query.length
      @setState searchResultsLoading: true
      @props.onLocationsRequested(null, query)
    else
      @clearSearch()

  # Introduces a slight delay to ensure we aren't sending tons of AJAX calls.
  searchDelayer: (event) =>
    $searchBox = $(event.target)
    query = $searchBox.val()
    @setState
      previousSearchValue: query,
      displayedValue: query,
      searchResultsLoading: query.length
    clearTimeout(@_searchDebouncer)
    @_searchDebouncer = setTimeout((=> @search($searchBox, query)), 200)

  showPopup: =>
    @setState displayPopup: true, displayedValue: '', typeSelected: false

  renderField: ->
    mobile = window.matchMedia('screen and (max-width: 767px)').matches

    # Don't allow searching if on mobile or we're multi-region.
    if @props.site.sites?.length > 1
      @renderFakeField(icon: <FontAwesomeIcon icon={faCaretDown}/>)
    else if mobile
      @renderFakeField()
    else
      @renderInputField()

  renderFakeField: (options = {}) ->
    if options.icon
      icon = options.icon

    fieldProps = {
      content: { icon: icon, placeholder: @props.content.placeholder },
      onClick: @showPopup,
      onFocus: @showPopup,
      onKeyDown: @handleKeyDown,
      ref: "field",
      value: @props.location.value?.name }

    # If there is no site set and there are no sites to choose from: disable.
    if @isDisabled()
      fieldProps.className = 'disabled'
      fieldProps.onClick = null
      fieldProps.onFocus = null
      fieldProps.onKeyDown = null

    <FakeField {...fieldProps}/>

  renderInputField: (ref = 'field') ->
    inputProps = {
      className: "location-search-field",
      content: { 
        placeholder: @props.content.placeholder 
      },
      onChange: @searchDelayer,
      onFocus: @showPopup,
      onKeyDown: @handleKeyDown,
      ref: ref,
      value: @state.displayedValue || '' }

    # If there is no site set and there are no sites to choose from: disable.
    inputProps.disabled = true if @isDisabled()

    <div className="location-search-container">
      <InputWithIcon {...inputProps}/>
    </div>

  renderLoadingIndicator: ->
    <div className="locs-loading">
      <FontAwesomeIcon icon={faCircleNotch} spin/>
      {@props.content.fetchingMessage}
    </div>

  renderPopup: ->
    if !@props.site.value
      popup = <SiteSelector ref="selector"
                            onChange={@props.onSiteChange}
                            sites={@props.site.sites}/>
    else if @isSearching()
      popup = @renderSearchResults()
      popupClass = 'search-results'
    else
      if @props.site.sites?.length > 1
        back = (=> @props.onSiteChange('', ''))
        typeHeader = @currentSite().name
      popup = <LocationSelector
                ref="selector"
                content={{ type: { header: typeHeader } }}
                loading={@props.locationsByTypeLoading}
                locationsByType={@props.locationsByType}
                onBack={back}
                onBlur={@hidePopup}
                onLocationsRequested={@props.onLocationsRequested}
                onLocationTypesRequested={@props.onLocationTypesRequested}
                onTypeChange={(type) => @setState typeSelected: type }
                onSelect={@locationSelected}
                onViewportChange={@props.onViewportChange}
                requestedLocations={@props.requestedLocations}
                searchCenter={@props.searchCenter}
                {...@props.location}/>

    # The "search all box" is really only intended for mobile. And if the
    # user has selected a location type then let's hide the search box so
    # that it doesn't get confused with a type-specific search box.
    # Also, you can't search all locations if we're multi-region.
    if !@state.typeSelected && @props.site.sites?.length == 1
      searchAllBox = @renderSearchAllBox()

    <SelectorPopup content={@props.content} onClose={@hidePopup}
                   className={popupClass}>
      {searchAllBox}
      {popup}
    </SelectorPopup>

  renderSearchAllBox: ->
    <div className="all-loc-search">{@renderInputField('searchAllField')}</div>

  renderSearchResults: ->
    return @renderLoadingIndicator() if @state.searchResultsLoading

    locs = @state.searchResults.map (loc) =>
      if loc.address
        address = <div><span className='loc-address'>{loc.address}</span></div>
      if loc.city || loc.state
        cityState = <div><span className='loc-address'>{loc.city}, {loc.state}</span></div>

      <SelectorItem key={loc.code} onClick={=> @locationSelected(loc)}>
        {loc.name}{address}{cityState}
      </SelectorItem>

    if @state.noLocationsFound
      return <div className="no-locs">{@props.content.noLocationsFound}</div>

    <SelectorList ref="selector" onHighlight={@locationHighlighted}>
      {locs}
    </SelectorList>

  render: ->
    <div className="site-and-location">
      <label dangerouslySetInnerHTML={{__html: @props.content.label}}/>
      {@renderField()}
      {if @state.displayPopup then @renderPopup()}
    </div>

SiteAndLocation.propTypes = {
  content: PropTypes.shape({
    fetchingMessage: PropTypes.string,
    label: PropTypes.string,
    noLocationsFound: PropTypes.string,
    placeholder: PropTypes.string
  }),
  location: PropTypes.shape({
    value: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      code: PropTypes.string,
      mapPoint: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.string,
      type: PropTypes.string,
      zip: PropTypes.string,
    })
  }).isRequired,
  locationsByType: PropTypes.array,
  locationsByTypeLoading: PropTypes.bool,
  onLocationChange: PropTypes.func.isRequired,
  onLocationsRequested: PropTypes.func.isRequired,
  onLocationTypesRequested: PropTypes.func,
  onSiteChange: PropTypes.func.isRequired,
  onViewportChange: PropTypes.func,
  requestedLocations: PropTypes.object,
  searchCenter: PropTypes.object,
  site: PropTypes.shape({
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        providerSiteId: PropTypes.string
    })),
    value: PropTypes.string
  }).isRequired
}
