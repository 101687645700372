import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  updateReservationAirport, updateCustomerRequest, updateReservationFields
} from '../../redux/reservations'
import Reservation from './reservation/airport_with_apollo'

class AirportForm extends React.Component
  constructor: (props) -> super(props)

  renderHeader: ->
    return '' unless @props.content.header
    <div className="entry-page-header"
         dangerouslySetInnerHTML={{__html: @props.content.header}}/>

  render: ->
    <div>
      {@renderHeader()}
      <Reservation index={0}
        errors={@props.errors}
        fields={@props.reservations[0]}
        onAirportChanged={(airport) => @props.onAirportChanged(0, airport)}
        onCustomerRequestChanged={(value) => @props.onCustomerRequestChanged(0, value)}
        onFieldsChanged={(fields) => @props.onFieldsChanged(0, fields)}
        onReturnFieldsChanged={(fields) => @props.onFieldsChanged(1, fields)}
        onViewportChanged={@props.onViewportChanged}
        returnErrors={@props.returnErrors}
        returnFields={@props.reservations[1]}
        submitButton={@props.submitButton}/>
    </div>

AirportForm.propTypes = {
  content: PropTypes.shape({
    header: PropTypes.string
  }),
  errors: PropTypes.object,
  onAirportChanged: PropTypes.func.isRequired,
  onFieldsChanged: PropTypes.func.isRequired,
  onViewportChanged: PropTypes.func,
  reservations: PropTypes.array,
  returnErrors: PropTypes.object
}

# --- Connect to Redux
mapStateToProps = (state) ->
  content: state.content.trip.airport,
  errors: state.reservations.errors?.legs?[0] || {},
  reservations: state.reservations.legs,
  returnErrors: state.reservations.errors?.legs?[1] || {}

mapDispatchToProps = (dispatch) -> bindActionCreators({
  onAirportChanged: updateReservationAirport,
  onCustomerRequestChanged: updateCustomerRequest,
  onFieldsChanged: updateReservationFields
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AirportForm)
