import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { isMobile } from '../../../utils/utils'
import './bus.scss'
import PassengerSelector from '../passenger_selector'
import PickupTime from '../pickup_time'
import SelectList from '../../fields/select_list'

class BusReservation extends React.Component
  componentDidMount: -> 
    window.addEventListener('resize', @forceUpdateWithState)

  componentWillUnmount: -> 
    window.removeEventListener('resize', @forceUpdateWithState)

  componentDidUpdate: (prevProps, prevState) ->
    # If the service group changed then we need to re-broadcast our locs
    if prevProps.fields.serviceGroupId != @props.fields.serviceGroupId
      @props.onLocationChange('pickup', { code: @refs['loc-pickup'].value() })
      @props.onLocationChange('dropoff', { code: @refs['loc-dropoff'].value() })

  forceUpdateWithState: => @forceUpdate()

  renderLocations: (endpoint) ->
    values = {}
    if @props.locations[endpoint].length > 1
      values['--'] = '' 
    values[loc.displayName] = loc.code for loc in @props.locations[endpoint]

    label = @props.content.bus.locationLabels[@props.index][endpoint]
    ref= "loc-#{endpoint}"

    <div className="location-container">
      <label dangerouslySetInnerHTML={{__html: label}}/>
      <SelectList ref={ref}
                  value={@props.fields[endpoint]?.code} 
                  values={values} 
                  onChange={(loc) => 
                    @props.onLocationChange(endpoint, { code: loc }) }/>
    </div>

  renderPassengers: ->
    return null unless @props.options.showPassengersOnEntryPage
    <PassengerSelector
      error={@props.errors?.passengers}
      onChange={(field, value) =>
        obj = { passengers: {} }
        obj.passengers[field] = value
        @props.onFieldsChanged(obj) }
      onViewportChange={@props.onViewportChanged}
      options={{ showNumberOnZero: @props.options.optimizeSpace, oldStyle: true, usePopup: true }}
      value={@props.fields.passengers}/>

  renderPickupTime: ->
    options = $.extend({}, @props.options, {
      onewayOnly: true,
      hidden: @props.options.hidePickupDateOnBus,
      hideTime: true,
      bootstrap: { orientation: 'top left' }
    })

    # Make sure we have a default time of some sort.    
    if @props.options.hidePickupDateOnBus && 
       !@props.options.defaultPickupDaysOut &&
       !@props.options.defaultPickupToToday &&
       !@props.options.busOpenBooking
      options.defaultPickupDaysOut = 1

    <PickupTime fields={ {
                         mode: 'oneway',
                         pickupTime: @props.fields.pickupTime
                       } }
                errors={@props.errors}
                onChange={@props.onFieldsChanged}
                onViewportChange={@props.onViewportChanged}
                options={options}/>

  render: ->
    if @props.options.optimizeSpace && @props.submitButton
        submitButton = <div className="submit-container">
          {@props.submitButton}
        </div>

    <div className="bus-reservation">
      <div className="ma-panel control-group tab-content hudson-subsection panel reservation">
        <div className="location-row">
          {@renderLocations('pickup')}
          {@renderLocations('dropoff')}

          <div className="passenger-pickup-date">
            {@renderPassengers()}
            {@renderPickupTime()}
          </div>
        </div>

        {submitButton}      
      </div>
    </div>


BusReservation.propTypes = {
  content: PropTypes.object,
  errors: PropTypes.object,
  fields: PropTypes.shape({
    dropoff: PropTypes.object,
    mode: PropTypes.oneOf(['bus']),
    pickup: PropTypes.object,
    providerSiteId: PropTypes.string,
    serviceGroupId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  }).isRequired,
  index: PropTypes.number,
  locations: PropTypes.object.isRequired,
  onFieldsChanged: PropTypes.func.isRequired,
  onLocationChange: PropTypes.func.isRequired,
  onViewportChanged: PropTypes.func,
  options: PropTypes.object,
  submitButton: PropTypes.object
}

# --- Connect to redux

mapStateToProps = (state, ownProps) ->
  if ownProps.fields.providerSiteId
    siteConfig = state.siteConfiguration[ownProps.fields.providerSiteId]
  else
    siteConfig = {}

  {
    content: state.content,
    fields: ownProps.fields,
    options: $.extend({}, state.configuration, siteConfig)
  }

export default connect(mapStateToProps)(BusReservation)
