import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faCircleNotch from '@fortawesome/fontawesome-free-solid/faCircleNotch'
import faSearch from '@fortawesome/fontawesome-free-solid/faSearch'
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes'

import './location.scss'
import ReduxedLocationSelector from './redux_location_selector'
import FakeField from './fake_field'
import Item from './selector_item'
import GoogleMap from './google_map'
import InputWithIcon from './input_with_icon'
import List from './selector_list'
import SelectorPopup from './selector_popup'
import googleSourceImage from './location_selector/selectors/source_images/powered_by_google_on_white.png'

export default class Location extends React.Component
  constructor: (props) ->
    super(props)
    @state = {
      displayPopup: false,
      displayedValue: (@props.location.value?.display_name ||
                       @props.location.value?.name || ''),
      displayedValueTime: new Date,
      noLocationsFound: false,
      previousSearchValue: ''
    }

  _knmTranslateCode: (code) =>
    {
      9: 'tab', 13: 'enter', 27: 'esc', 37: 'left', 38: 'up', 39: 'right',
      40: 'down'
    }[code]

  handleKeyDown: (e) =>
    keyCode = e.keyCode || e.which
    name = @_knmTranslateCode(keyCode)
    if @keyActions?[name]?
      @keyActions[name].call(this)
      e.preventDefault() unless name == 'tab'

  keyActions: { # Keyboard actions
    down: (->
      if @state.displayPopup then @currentSelector()?.down?() else @showPopup()),
    enter: (-> @currentSelector()?.select?()),
    esc: (-> @hidePopup()), tab: (-> @hidePopup()),
    left: (-> @currentSelector()?.left?()),
    right: (-> @currentSelector()?.select?()),
    tab: (-> @currentSelector()?.select?()),
    up: (-> @currentSelector()?.up?())
  }

  componentDidMount: ->
    # If a user is tabbing between fields we want to hide the popup if they
    # have tabbed away from it.
    $(document).on 'focusin', =>
      isIE11 = !!(navigator.userAgent.match(/Trident/) && navigator.userAgent.match(/rv[ :]11/))
      return unless document.activeElement && !@isMobile() && @state.displayPopup && !isIE11
      @hidePopup() unless @refs.location && $.contains(@refs.location, document.activeElement)

    # A little bit of a hack. This triggers the geocoding routine to run.
    # It's cheap insurance for cases where a user has selected a Hudson location
    # without a map point. This will geocode the loc and add it to the map.
    if @props.options.googleMapsEnabled
      @props.onLocationChange(@props.location.value) if @props.location?.value

  componentDidUpdate: (prevProps, prevState) ->
    # Resize page if popup is shown or hidden
    if !prevState.displayPopup && @state.displayPopup
      @props.onViewportChanged()
    else if prevState.displayPopup && !@state.displayPopup
      @props.onViewportChanged()

    if @state.searchResults?.length != prevState.searchResults?.length ||
       @state.searchResultsLoading != prevState.searchResultsLoading
      @props.onViewportChanged()

    # If our location changed, change the displayed value.
    if prevProps.location.value?.display_name != @props.location.value?.display_name ||
       prevProps.location.value?.name != @props.location.value?.name
      @setState( {
        displayedValue: (@props.location.value?.display_name || @props.location.value?.name),
        displayedValueTime: new Date
      })

    @focusOnSearchAllField() if !prevState.displayPopup && @state.displayPopup

    # Manage search query updates coming from the location redux store.
    storeSearchQueryChanged = prevProps.requestedLocations?.query != @props.requestedLocations?.query
    storeSearchLocCountChanged = prevProps.requestedLocations?.locations?.length != @props.requestedLocations?.locations?.length
    storeSearchFinished = prevProps.requestedLocations?.fetching && !@props.requestedLocations?.fetching
    storeSearchIsOurs = @props.requestedLocations &&
                          (@props.requestedLocations.query == @state.currentSearch) &&
                          @props.requestedLocations.locType == null

    if storeSearchIsOurs &&
       (storeSearchQueryChanged || storeSearchLocCountChanged || storeSearchFinished)
      if !@props.requestedLocations.fetching &&
         @props.requestedLocations.locations.length == 0
        noLocationsFound = true
      @setState
        googleFallbackAvailable: @props.requestedLocations.googleFallback,
        searchResultsLoading: @props.requestedLocations.fetching,
        searchResults: @props.requestedLocations.locations,
        searchResultsSource: @props.requestedLocations.source,
        noLocationsFound: noLocationsFound

  clearSearch: =>
    @setState
      currentSearch: '',
      displayedValue: '',
      displayedValueTime: new Date,
      googleFallbackAvailable: false,
      searchResults: null,
      searchResultsSource: null,
      noLocationsFound: false,
      searchResultsLoading: false

  componentWillUnmount: -> clearTimeout(@_searchDebouncer)

  currentSelector: =>
    if @isSearching() then @refs.selector else @refs.selector?.getWrappedInstance()

  focusOnSearchAllField: =>
    @refs.searchAllField.focus?() if $('.all-loc-search').is(':visible')

  hidePopup: (options = {}) =>
    return unless @state.displayPopup
    @refocus() if @isMobile()
    @clearSearch()
    if options.displayedValue?
      displayedValue = options.displayedValue
    else
      displayedValue = @props.location.value?.display_name ||
                        @props.location.value?.name
    @setState
      displayPopup: false,
      displayedValueTime: new Date,
      displayedValue: displayedValue

  isDisabled: => @props.disabled || @props.readonly || @isUpdating()

  isMobile: => window.matchMedia('screen and (max-width: 767px)').matches

  isSearching: => @state.searchResults || @state.searchResultsLoading

  isUpdating: =>
    @props.location.value?.updating || @props.updating

  # We only want to include PUDs if the hideLocationPopup option is set.
  locationsByType: =>
    return @props.locationsByType unless @props.options.hideLocationPopup

    pudType = (t for t in @props.locationsByType when t.type == 'PUD')[0]
    return [pudType]

  locationHighlighted: (name) =>
    @setState displayedValue: (name || @state.previousSearchValue), displayedValueTime: new Date

  locationCleared: =>
    @props.onLocationChange({})
    @hidePopup(displayedValue: '')

  locationSelected: (loc) =>
    @props.onLocationChange(loc)
    if loc
      name = loc.display_name || loc.name
    @hidePopup(displayedValue: name)

  refocus: => @refs.field?.focus?()

  search: (query, googleFallback) =>
    if query.length
      @setState searchResultsLoading: true, currentSearch: query
      @props.onLocationsRequested(null, query, googleFallback)
    else
      @clearSearch()
      @hidePopup(displayedValue: '') if !@shouldShowLocationPopup() && !@isMobile()

  # Introduces a slight delay to ensure we aren't sending tons of AJAX calls.
  searchDelayer: (ref, eventTimestamp) =>
    query = @refs[ref].value()
    if eventTimestamp
      eventTime = new Date(eventTimestamp)
    if !eventTime || eventTime.getFullYear() < 2017
      eventTime = new Date


    if query.length < 3
      @setState
        previousSearchValue: query,
        displayPopup: @isMobile(),
        displayedValue: query,
        displayedValueTime: eventTime,
        searchResultsLoading: false
    else
      @setState
        previousSearchValue: query,
        displayPopup: true,
        displayedValue: query,
        displayedValueTime: eventTime,
        searchResultsLoading: query.length
      clearTimeout(@_searchDebouncer)
      @_searchDebouncer = setTimeout((=> @search(query)), 600)  

  # Respect the hideLocationPopup option but also check if we have PUDs.
  shouldShowLocationPopup: =>
    return true if !@props.options.hideLocationPopup

    # Any PUDs?
    pudType = (t for t in @props.locationsByType when t.type == 'PUD')[0]
    return pudType?.locations?.length > 0

  showPopup: =>
    return if @state.displayPopup || (!@shouldShowLocationPopup() && !@isMobile())
    @setState displayPopup: true, displayedValue: '', displayedValueTime: new Date, typeSelected: false

  renderError: ->
    error = @props.location?.value?.error || @props.error
    return '' if !error
    <label className="error-label"
           dangerouslySetInnerHTML={{__html: error}}/>

  renderField: ->
    mobile = window.matchMedia('screen and (max-width: 767px)').matches
    if mobile
      field = @renderFakeField()
    else
      field = @renderInputField()

    <div style={{ width: '100%' }}>
      {field}
      {@renderError()}
    </div>

  renderFakeField: ->
    fieldProps = {
      onClick: @showPopup, onFocus: @showPopup, onKeyDown: @handleKeyDown,
      loading: @isUpdating(),
      ref: "fakeField",
      value: @state.displayedValue || ''}

    if @props.loading
      fieldProps.content = { placeholder: @props.content.loadingMessage }
    else
      fieldProps.content = { placeholder: @props.content.placeholder }

    # To support clearing out location field. Needs a little more work in mobile.
    # if @state.displayedValue
    #   fieldProps.content.icon = <FontAwesomeIcon icon={faTimes}/>
    #   fieldProps.onIconClick = @locationCleared
    # else
    # fieldProps.content.icon = <FontAwesomeIcon icon={faSearch}/>

    if @isDisabled()
      fieldProps.className = 'disabled'
      fieldProps.onClick = null
      fieldProps.onFocus = null
      fieldProps.onKeyDown = null
    fieldProps.className += ' has-error' if @props.location?.value?.error || @props.error

    <FakeField {...fieldProps}/>

  renderInputField: (ref = 'field') ->
    # NOTE: Can stop passing valueTime when React fixes IE 11 bug:
    # https://github.com/facebook/react/issues/7027
    inputProps = {
      className: "location-search-field",
      loading: @isUpdating(),
      onChange: ( (e) => @searchDelayer(ref, e.timeStamp) ),
      onFocus: @showPopup,
      onKeyDown: @handleKeyDown,
      ref: ref,
      value: @state.displayedValue || '',
      valueTime: @state.displayedValueTime }
    inputProps.className += ' has-error' if @props.location?.value?.error || @props.error
    inputProps.disabled = true if @isDisabled()
    if @props.loading
      inputProps.content = { placeholder: @props.content.loadingMessage }
    else
      inputProps.content = { placeholder: @props.content.placeholder }

    if @state.displayedValue
      inputProps.content.icon = <FontAwesomeIcon icon={faTimes}/>
      inputProps.onIconClick = @locationCleared
    else
      inputProps.content.icon = <FontAwesomeIcon icon={faSearch}/>

    <div className="location-search-container">
      <InputWithIcon {...inputProps}/>
    </div>

  renderLoadingIndicator: ->
    <div className="locs-loading">
      <FontAwesomeIcon icon={faCircleNotch} spin/>
      {@props.content.fetchingMessage}
    </div>

  renderPopup: ->
    popupClass = ''

    if @isSearching()
      popup = @renderSearchResults()
      popupClass = 'search-results'
    else if !@shouldShowLocationPopup()
      popup = null
    else
      popup = <ReduxedLocationSelector
                ref="selector"
                className="location-selector-list"
                loading={@props.loading}
                locationsByType={@locationsByType()}
                onBlur={@hidePopup}
                onLocationsRequested={@props.onLocationsRequested}
                onLocationTypesRequested={@props.onLocationTypesRequested}
                onSelect={@locationSelected}
                onTypeChange={(type) => @setState typeSelected: type }
                onViewportChange={@props.onViewportChanged}
                requestedLocations={@props.requestedLocations}
                {...@props.location}/>

    # The "search all box" is really only intended for mobile. And if the
    # user has selected a location type then let's hide the search box so
    # that it doesn't get confused with a type-specific search box.
    searchAllBox = if @state.typeSelected then null else @renderSearchAllBox()

    if @state.searchResultsSource == 'google' && !@state.searchResultsLoading
      popupClass += ' google-sourced'
      sourceMessage = <div style={{ margin: '7px', position: 'fixed', right: 0, bottom: 0 }}>
        <img src={window.urls.relative_root + googleSourceImage}/>
      </div>

    <SelectorPopup onClose={@hidePopup} className={popupClass}
                   content={@props.content} options={@props.options}
                   bottomContent={sourceMessage}>
      {searchAllBox}
      {popup}
    </SelectorPopup>

  renderSearchAllBox: ->
    <div className="all-loc-search">{@renderInputField('searchAllField')}</div>

  renderSearchResults: ->
    return @renderLoadingIndicator() if @state.searchResultsLoading

    locs = @state.searchResults.map (loc) =>
      if loc.address
        address = <div><span className='loc-address'>{loc.address}</span></div>
      if loc.city || loc.state
        cityState = <div><span className='loc-address'>{loc.city}, {loc.state}</span></div>

      highlightName = loc.name || loc.address || "#{loc.city}, #{loc.state}"
      <Item key={loc.googlePlaceId || loc.code}
                    name={highlightName}
                    onClick={=> @locationSelected(loc)}>
        {loc.name}{address}{cityState}
      </Item>

    if @state.googleFallbackAvailable
      onClick = => 
        @refocus()
        @search(@state.displayedValue, true)
      locs.push <Item key="googleFallback" 
                      name={@state.displayedValue}
                      className="google-fallback-button"
                      onClick={onClick}>
        {@props.content.googleFallbackLabel}
      </Item>
    
    if @state.noLocationsFound
      return <div className="no-locs">{@props.content.noLocationsFound}</div>

    <List ref="selector"
          className="location-selector-list"
          onHighlight={@locationHighlighted}>
      {locs}
    </List>

  renderNotes: ->
    return unless @props.location.value?.notes
    <span className="cat-info label label-warning">
      {@props.location.value.notes}
    </span>

  render: ->
    className = "site-and-location entrypage-location #{@props.className}"
    className += " readonly" if @props.readonly

    <div ref="location" className={className}>
      <label dangerouslySetInnerHTML={{__html: @props.content.label}}/>
      {@renderField()}
      {@renderNotes()}
      {if @state.displayPopup then @renderPopup()}
    </div>

Location.propTypes = {
  className: PropTypes.string,
  content: PropTypes.shape({
    location: PropTypes.shape({
      label: PropTypes.string,
      noLocationsFound: PropTypes.string,
      placeholder: PropTypes.string
    })
  }),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool,
  location: PropTypes.shape({
    value: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      code: PropTypes.string,
      display_name: PropTypes.string,
      map_point: PropTypes.string,
      name: PropTypes.string,
      notes: PropTypes.string,
      state: PropTypes.string,
      type: PropTypes.string,
      zip: PropTypes.string,
    })
  }).isRequired,
  locationsByType: PropTypes.array,
  onLocationChange: PropTypes.func.isRequired,
  onLocationsRequested: PropTypes.func.isRequired,
  onLocationTypesRequested: PropTypes.func,
  onViewportChanged: PropTypes.func,
  options: PropTypes.shape({
    googleMapsEnabled: PropTypes.bool,
    hideLocationPopup: PropTypes.bool,
    optimizeSpace: PropTypes.bool
  }),
  readonly: PropTypes.bool,
  requestedLocations: PropTypes.object
}
